<template>
  <div>
    <LoadingVehicle v-if="saving" />
    <QForm v-else @submit="saveOrderDetails">
      <h6 class="q-mt-none q-mb-md text-grey-9">Customer Details</h6>
      <QSeparator class="q-my-md" />
      <!--Drive Block -->
      <div class="col-6">
        <p class="q-mb-md text-weight-bold text-grey-9">Customer Type</p>
        <div class="row q-col-gutter-md">
          <div class="col-6">
            <p><QRadio v-model="searchOption" val="New" label="New Customer" /></p>
          </div>
          <div class="col-6">
            <p><QRadio v-model="searchOption" val="Existing" label="Existing Customer" /></p>
          </div>
        </div>
      </div>
      <QSeparator class="q-my-md" />
      <p v-if="!existingCustomer" class="q-mb-md text-weight-bold text-grey-9">Search for Entity by ABN, ACN or Company Name</p>
      <div v-if="!existingCustomer" class="row q-col-gutter-md">
        <div class="col-6">
          <QInput
            v-model.lazy="companySearch"
            filled
            maxlength="50"
            color="secondary"
            dense
            hide-bottom-space
            clearable
            :loading="companySearchLoading"
          />
        </div>
        <!-- Search option -->
        <div class="col-6">
          <QBtn
            type="input"
            label="Search"
            color="secondary"
            :loading="companySearchLoading"
            :disable="searchStringlength < 1"
            :ripple="false"
            @click.prevent="searchForCompany"
          />
        </div>
      </div>
      <!-- Results -->
      <div v-if="businessInfo && showLists && !existingCustomer" style="padding-top: 25px">
        <div class="row q-col-gutter-md text-weight-bold text-grey-9">
          <div class="col-6" style="padding-left: 25px">
            <p>Company Name</p>
          </div>
          <div class="col-4">
            <p>ABN</p>
          </div>
          <div class="col-2">
            <p v-if="stateDefined || postDefined">Location</p>
          </div>
        </div>

        <div class="row q-col-gutter-md" v-for="company in businessInfo" :key="company.abn">
          <div class="col-6">
            <p><QRadio v-model="companySelected" :val="company" :label="company.name" /></p>
          </div>
          <div class="col-4">
            <p>{{ company.abn }}</p>
          </div>
          <div class="col-2">
            <p>{{ company.postCode }} {{ company.stateCode }}</p>
          </div>
        </div>
      </div>
      <div v-if="businessInfo && businessInfo.length == 0" style="padding-top: 25px">
        <p>No results found.</p>
      </div>

      <!-- Search existing customer -->
      <div v-if="existingCustomer">
        <div class="col-6">
          <p class="q-mb-md text-weight-bold text-grey-9">Select Customer</p>
          <div class="row q-col-gutter-md">
            <div class="col-6">
              <ExistingCustomer />
            </div>
          </div>
        </div>
      </div>

      <QSeparator class="q-my-md" />
      <FormError :show="$v.$error && $v.$dirty" class="q-my-md" />

      <p class="q-mb-md text-weight-bold text-grey-9" v-if="showCompanyFields()">Company Details</p>
      <div class="row q-col-gutter-md" v-if="showCompanyFields()">
        <div class="col-6">
          <p class="text-body3 text-grey-7 q-mb-xs text-weight-medium">Company Name*</p>
          <QInput
            v-model="$v.companyName.$model"
            filled
            maxlength="50"
            color="secondary"
            dense
            :disable="isExpired"
            hide-bottom-space
            clearable
            :error="$v.companyName.$error"
            :readonly="true"
          />
        </div>
        <div class="col-6">
          <p class="text-body3 text-grey-7 q-mb-xs text-weight-medium">ABN / ACN*</p>
          <QInput
            v-model="$v.companyAbn.$model"
            filled
            maxlength="11"
            color="secondary"
            dense
            :disable="isExpired"
            hide-bottom-space
            clearable
            :error="$v.companyAbn.$error"
            :readonly="true"
          />
        </div>
      </div>
      <div class="q-my-md" v-if="$v.companyName.$error || $v.companyAbn.$error">
        <p class="flex text-red text-body3 q-mb-none error-text">Please search the company and select from list!</p>
      </div>

      <QSeparator v-if="showCompanyFields()" class="q-my-md" />
      <p class="q-mb-md text-weight-bold text-grey-9">Contact Details</p>
      <div class="row q-col-gutter-md">
        <div class="col-6">
          <p class="text-body3 text-grey-7 q-mb-xs text-weight-medium">Customer Name*</p>
          <QInput
            v-model="$v.customerName.$model"
            filled
            maxlength="50"
            color="secondary"
            dense
            :disable="isExpired"
            hide-bottom-space
            clearable
            :error="$v.customerName.$error"
          />
        </div>
        <div class="col-6">
          <p class="text-body3 text-grey-7 q-mb-xs text-weight-medium">Contact Number*</p>
          <QInput
            v-model="$v.customerContactNumber.$model"
            filled
            type="tel"
            pattern="[- +0-9]+"
            title="Please enter a valid Australian phone number"
            color="secondary"
            dense
            :disable="isExpired"
            hide-bottom-space
            clearable
            :error="$v.customerContactNumber.$error"
          />
        </div>
        <div class="col-6">
          <p class="text-body3 text-grey-7 q-mb-xs text-weight-medium">Contact Email*</p>
          <QInput
            v-model="$v.customerContactEmail.$model"
            filled
            type="email"
            color="secondary"
            dense
            :disable="isExpired"
            hide-bottom-space
            clearable
            :error="$v.customerContactEmail.$error || dragonBallUserEmail"
            @blur="checkEmailValidity"
          />
          <div class="q-my-md" v-if="$v.customerContactEmail.$error || dragonBallUserEmail">
            <p v-if="$v.customerContactEmail.$error" class="flex text-red text-body3 q-mb-none error-text">
              Please enter a valid email address, max length 55 characters!
            </p>
            <p v-else class="flex text-red text-body3 q-mb-none error-text">
              This email address cannot be associated with a customer record. Please use an alternative email address!
            </p>
          </div>
        </div>
      </div>
      <QSeparator class="q-my-md" />
      <p class="q-mb-md text-weight-bold text-grey-9">Delivery Address</p>
      <div class="row q-col-gutter-md">
        <div class="col-12">
          <EUIAutocomplete
            class="primary col-6"
            theme="primary"
            popup-content-class="sai-select-popup"
            :country-restrictions="['au', 'nz']"
            :placeholder="deliveryAddress"
            behavior="menu"
            dropdown-icon="eva-search-outline"
            no-error-icon
            hide-bottom-space
            :disable="false"
            :clearable="false"
            @input="updatePlace"
          />
        </div>
        <div v-if="invalidAddress">
          <p style="color: red; font-size: 12px">Please select a valid address with postcode</p>
        </div>
      </div>
      <div class="row q-col-gutter-md" v-if="0">
        <div class="col-6">
          <p class="text-body3 text-grey-7 q-mb-xs text-weight-medium">Address Line 1*</p>
          <QInput
            v-model="$v.deliveryAddressLine1.$model"
            filled
            color="secondary"
            dense
            :disable="isExpired"
            clearable
            :error="$v.deliveryAddressLine1.$error"
            hide-bottom-space
          />
        </div>
        <div class="col-6">
          <p class="text-body3 text-grey-7 q-mb-xs text-weight-medium">Address Line 2</p>
          <QInput
            v-model="$v.deliveryAddressLine2.$model"
            filled
            color="secondary"
            dense
            :disable="isExpired"
            clearable
            :error="$v.deliveryAddressLine2.$error"
            hide-bottom-space
          />
        </div>
        <div class="col-4">
          <p class="text-body3 text-grey-7 q-mb-xs text-weight-medium">Suburb*</p>
          <!-- <p class="text-body3 text-grey-7 q-mb-xs text-weight-medium">Suburb*</p> -->
          <QInput
            v-model="$v.deliverySuburb.$model"
            filled
            color="secondary"
            dense
            :disable="isExpired"
            clearable
            :error="$v.deliverySuburb.$error"
            hide-bottom-space
          />
        </div>
        <div class="col-4">
          <p class="text-body3 text-grey-7 q-mb-xs text-weight-medium">State*</p>
          <StateSelect
            v-model="$v.deliveryState.$model"
            filled
            color="secondary"
            dense
            :disable="isExpired"
            :error="$v.deliveryState.$error"
            hide-bottom-space
          />
        </div>
        <div class="col-4">
          <p class="text-body3 text-grey-7 q-mb-xs text-weight-medium">Postcode*</p>
          <QInput
            v-model="$v.deliveryPostcode.$model"
            input-class="hide-input-arrows"
            filled
            max="9999"
            min="0"
            type="number"
            color="secondary"
            dense
            :disable="isExpired"
            clearable
            :error="$v.deliveryPostcode.$error"
            hide-bottom-space
          />
        </div>
        <div class="col-6">
          <p class="text-body3 text-grey-7 q-mb-xs text-weight-medium">Delivery Date*</p>
          <EUIDateInput
            v-model="$v.deliveryDate.$model"
            filled
            color="secondary"
            dateMask="DD/MM/YYYY"
            dense
            :disable="isExpired"
            hide-bottom-space
            :error="$v.deliveryDate.$error"
          />
        </div>
      </div>
      <QSeparator class="q-my-md" />
      <div class="row q-col-gutter-md">
        <div class="col-6" v-if="showDealership">
          <p class="q-mb-md text-weight-bold text-grey-9">Dealership</p>
          <p class="text-body3 text-grey-7 q-mb-xs text-weight-medium">Dealership*</p>
          <QSelect
            ref="dealerSelect"
            v-model="dealerSearch"
            filled
            color="secondary"
            dense
            :disable="isExpired"
            :options="dealers.rows"
            clearable
            use-input
            popup-content-class="limit-q-menu-height"
            fill-input
            hide-selected
            @virtual-scroll="dealerSearchScrolled"
            @popup-hide="dealerSearchClosed"
            @input="selectDealer"
            @input-value="searchDealers"
            option-label="name"
            :disabled="dealerSearchCreating"
            :loading="$apollo.queries.dealers.loading"
            :error="$v.dealership.$error"
          >
            <template slot="no-option">
              <QItem v-if="!dealerSearch">
                <QItemSection>
                  <QItemLabel> Search for a dealer. </QItemLabel>
                </QItemSection>
              </QItem>
              <QItem v-else-if="!$apollo.queries.dealers.loading" clickable @click="addNewDealer" class="cursor-pointer">
                <QItemSection>
                  <QItemLabel class="text-grey-7 items-center row">
                    <QIcon :name="plusIcon" class="q-mr-sm" color="secondary" size="20px" />
                    <span class="text-secondary col-grow"> Add this dealership </span>
                    <QSpinner color="secondary" size="xs" v-if="dealerSearchCreating" />
                  </QItemLabel>
                </QItemSection>
              </QItem>
            </template>
          </QSelect>
        </div>
        <div class="col-6">
          <p class="q-mb-md text-weight-bold text-grey-9">Registration Suburb</p>
          <p class="text-body3 text-grey-7 q-mb-xs text-weight-medium">Postcode*</p>
          <QInput
            v-model="$v.registrationPostcode.$model"
            input-class="hide-input-arrows"
            filled
            max="9999"
            type="number"
            color="secondary"
            dense
            :disable="isExpired"
            clearable
            :error="$v.registrationPostcode.$error"
          />
        </div>
      </div>
      <DriveQuoteNavigationActions back-to="quoteColourAndExtras">
        <template slot="next">
          <QBtn
            type="submit"
            :label="isExpired ? 'Next' : 'Save and Next'"
            :icon-right="this.nextIcon"
            color="secondary"
            :loading="saving || emailValidationLoading"
            :disable="saving"
            :ripple="false"
          />
        </template>
      </DriveQuoteNavigationActions>
    </QForm>
  </div>
</template>

<script>
import {
  QForm,
  QInput,
  QSeparator,
  Icons,
  QSelect,
  QBtn,
  QItem,
  QItemSection,
  QItemLabel,
  QIcon,
  QSpinner,
  QRadio,
  date,
  EUIAutocomplete,
  EnGbLang
} from 'eclipx.ui';
import EUIDateInput from '@/components/EuiDateInput';
import { mapState, mapActions, mapGetters } from 'vuex';
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators';
import DriveQuoteNavigationActions from '../DriveQuoteNavigationActions';
import StateSelect from '@/components/StateSelect/StateSelect';
import QueryDealers from '@/graphql/queries/QueryDealers';
import MutateDealer from '@/graphql/mutations/MutateDealer';
import { GlobalDialog } from '@/components/Dialogs/GlobalDialog.vue';
import { GLOBAL_ERROR } from '@/components/GlobalError/GlobalError';
import MutateQuote from '@/graphql/mutations/MutateQuote';
import getFeatureAllocations from '@/graphql/mutations/FeatureAllocations';
import LoadingVehicle from '@/components/LoadingVehicle/LoadingVehicle';
import ExistingCustomer from '@/components/SearchExistingCustomer/SearchExistingCustomer';
import QueryUserByEmail from '@/graphql/queries/QueryUserByEmail';
import SearchCompany from '@/mixins/BusinessLookUp';
import FormError from '@/components/FormError/FormError';

export default {
  name: 'DriveCustomerDetails',
  mixins: [SearchCompany],
  data() {
    return {
      saving: false,
      companyName: null,
      companyNumber: null,
      companyStateCode: null,
      companyPostCode: null,
      companyAbn: null,
      customerName: null,
      customerContactNumber: null,
      customerContactEmail: null,
      deliveryAddressLine1: null,
      deliveryAddressLine2: null,
      deliverySuburb: null,
      deliveryState: null,
      deliveryPostcode: null,
      deliveryDate: null,
      dealership: null,
      registrationPostcode: null,
      companySearch: null,
      companySelected: null,
      companySearchLoading: false,
      dealers: [],
      dealerSearch: null,
      dealerSearchCreating: false,
      dealerQuerySize: 20,
      haveNZBN: null,
      soleTrader: null,
      showDealership: false,
      showResult: true,
      searchOption: 'New',
      dragonBallUserEmail: false,
      emailValidationLoading: false,
      businessInfo: null,
      invalidAddress: false
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  validations: {
    companyName: { required },
    companyAbn: { required },
    customerName: { required },
    customerContactNumber: { required },
    customerContactEmail: { required, email, minLength: minLength(4), maxLength: maxLength(55) },
    deliveryAddressLine1: { required },
    deliveryAddressLine2: {},
    deliverySuburb: {
      required
    },
    deliveryState: { required },
    deliveryPostcode: { required },
    deliveryDate: { required },
    dealership: { required },
    registrationPostcode: { required }
  },
  computed: {
    ...mapState({
      erpquote: (state) => state.quote.quote.erpquote,
      selected: (state) => state.org.selectedOrg,
      user: (state) => state.auth.user
    }),
    ...mapGetters({
      isExpired: 'quote/isExpired',
      saveQuoteFields: 'quote/saveQuoteFields'
    }),
    deliveryAddress: function () {
      let str = '';
      str += this.deliveryAddressLine1 ? `${this.deliveryAddressLine1} ` : '';
      str += this.deliveryAddressLine2 ? `${this.deliveryAddressLine2}, ` : '';
      str += this.deliverySuburb ? `${this.deliverySuburb}, ` : '';
      str += this.deliveryState ? `${this.deliveryState}, ` : '';
      str += this.deliveryPostcode || '';
      return str;
    },
    dealerSearchQueryVariables: function () {
      let params = {
        size: this.dealerQuerySize
      };
      if (this.dealerSearch) {
        params.query = this.dealerSearch;
      }
      return { params };
    },
    features() {
      return this.$store.getters['auth/feature'];
    },
    hasOwnDealerFeature() {
      return this.features.indexOf('DB::OwnDealer') > -1; // for testing purpose DB::ManageDealership
    },
    soleTraderDisplay() {
      if (this.haveNZBN === false) {
        return true;
      }
      return false;
    },
    searchStringlength() {
      if (this.companySearch) {
        return this.companySearch.trim().length;
      } else {
        return this.companySearch;
      }
    },
    stateDefined() {
      if (this.businessInfo && this.businessInfo.length) {
        return this.businessInfo.find((row) => row.stateCode);
      } else {
        return null;
      }
    },
    postDefined() {
      if (this.businessInfo && this.businessInfo.length) {
        return this.businessInfo.find((row) => row.postCode);
      } else {
        return null;
      }
    },
    nzbnDisplayLogic() {
      let display = false;
      if (this.haveNZBN || this.haveNZBN === null) {
        display = true;
      } else if (this.haveNZBN !== null && !this.haveNZBN && (this.soleTrader === null || !this.soleTrader)) {
        display = false;
      }
      return display;
    },

    readOnlyLogic() {
      let readOnly = false;
      if (
        this.haveNZBN ||
        (this.soleTrader == null && this.haveNZBN === null) ||
        (this.soleTrader == null && this.haveNZBN !== null && !this.haveNZBN)
      ) {
        readOnly = true;
      }
      return readOnly;
    },
    existingCustomer() {
      return this.searchOption === 'Existing' ? true : false;
    },
    showLists() {
      return this.showResult;
    }
  },
  watch: {
    // If the dealership changes, ensure the dealership search text matches
    dealership: function (value) {
      if (this.showDealership) this.dealerSearch = value;
    },
    // Ensure the dealership select list is refreshed and shows the first result
    dealers: function () {
      if (this.showDealership) this.$refs.dealerSelect.refresh();
    },
    companySelected: async function (company) {
      if (this.existingCustomer) {
        this.companyName = company?.name;
        this.companyNumber = company?.registerNumber;
        this.companyStateCode = company?.stateCode;
        this.companyPostCode = company?.postCode;
        this.companyAbn = company?.abn;
        if (company?.contactDetail) {
          this.customerContactEmail = company?.contactDetail?.email;
          this.customerName = company?.contactDetail?.name;
          this.customerContactNumber = company?.contactDetail?.contactNumber;
          this.deliveryAddressLine1 = company?.contactDetail?.street;
          this.deliverySuburb = company?.contactDetail?.suburb;
          this.deliveryState = company?.contactDetail?.state;
          this.deliveryPostcode = company?.contactDetail?.postCode;
        }
        if (company) {
          this.showResult = false;
          this.companySearch = company?.name;
        }
      } else if (company) {
        this.showResult = !this.showResult;
        const srchString = this.companySearch ? this.companySearch.replace(/\s/g, '') : '';
        if (this.companySearch && srchString.length !== 11 && !/^\d+$/.test(srchString)) {
          this.companySearch = company.name;
          this.companySearchLoading = true;
          const { rows } = await this.searchCompanies(company.abn.trim());
          this.companySearchLoading = false;
          const [legalCompanyDetails] = rows;
          company = legalCompanyDetails;
        }
        this.companySearch = company.name;
        this.companyName = company.name;
        this.companyAbn = company.abn;
      }
    },
    searchStringlength: function (updated, oldValue) {
      if (
        this.companySelected &&
        this.companySelected.name !== this.companySearch &&
        this.searchStringlength &&
        Math.abs(oldValue - updated) == 1
      ) {
        this.showResult = true;
        this.sortCompanies();
      } else if (!this.companySelected && this.searchStringlength && this.businessInfo && Math.abs(updated - oldValue) == 1) {
        this.showResult = true;
        this.sortCompanies();
      }
      if (!this.searchStringlength) {
        this.showResult = false;
        this.companySelected = null;
      }
    },

    haveNZBN: function () {
      if ((!this.haveNZBN && this.soleTrader === null) || (this.haveNZBN && this.soleTrader !== null)) {
        this.soleTrader = null;
        this.companyName = null;
        this.companyNumber = null;
        this.companyStateCode = null;
        this.companyPostCode = null;
        this.companyAbn = null;
        this.companySelected = null;
        this.companySearch = null;
      }
    },
    erpquote: function (erpquote) {
      if (erpquote && erpquote.customer && this.companyName !== erpquote.customer.companyName) {
        this.companyName = erpquote.customer.companyName;
        this.companyNumber = erpquote.customer.companyNumber;
        this.companyStateCode = erpquote.customer.companyStateCode;
        this.companyPostCode = erpquote.customer.companyPostCode;
        this.companyAbn = erpquote.customer.companyAbn;
        this.customerName = erpquote.customer.customerName;
        this.customerContactNumber = erpquote.customer.customerContactNumber;
        this.customerContactEmail = erpquote.customer.customerContactEmail;
        this.deliveryAddressLine1 = erpquote.customer.deliveryAddressLine1;
        this.deliveryAddressLine2 = erpquote.customer.deliveryAddressLine2;
        this.deliverySuburb = erpquote.customer.deliverySuburb;
        this.deliveryState = erpquote.customer.deliveryState;
        this.deliveryPostcode = erpquote.customer.deliveryPostcode;
      }
    },
    customerContactEmail: function () {
      if (this.dragonBallUserEmail) this.dragonBallUserEmail = false;
    }
  },
  apollo: {
    dealers: {
      query: QueryDealers,
      debounce: 300,
      fetchPolicy: 'network-only',
      variables() {
        return this.dealerSearchQueryVariables;
      },
      error: function ({ graphQLErrors }) {
        const errMessage = graphQLErrors.map((err) => {
          return err.message;
        });
        /*Extract error message */
        let message = errMessage.join().replace(/Error:|{}/gi, '');
        if (message && message.startsWith('{"extensions":') && message.includes('"message"') && message.endsWith('}}}}}')) {
          message = message
            .substring(message.indexOf('"message'), message.indexOf('"}}}}}'))
            .replace(/message|:|"/gi, '')
            .trim();
        }
        if (message && message.trim().endsWith('.')) {
          message = message.slice(0, -1);
        }
        if (/:|{|}|"|\?|%/.test(message)) {
          message = '';
        }

        this.$euiNotifications.emit({
          notification: GLOBAL_ERROR,
          action: () => {
            return this.$apollo.queries.dealers.refresh();
          },
          message
        });
      },
      result: function (res) {
        this.$apollo.mutate({
          mutation: getFeatureAllocations,
          update: (store, response) => {
            let found = false;

            for (let i = 0; i < response.data.getFeatureAllocations.length; i++) {
              if (response.data.getFeatureAllocations[i].feature.code == 'DB::OwnDealer') {
                found = true;
              }
            }

            if (!found) {
              this.showDealership = true;
              // this.dealerSearch = this.dealership;
            } else {
              if (res.data.dealers && res.data.dealers.rows.length == 0) this.addNewDealer();
            }
          }
        });
      }
    }
  },
  methods: {
    ...mapActions({ saveCustomerDetails: 'quote/saveCustomerDetails' }),
    goToNext() {
      this.$router.push({
        name: 'quoteReview',
        params: { quoteId: this.$route.params.quoteId }
      });
    },
    updatePlace(place) {
      if (!place?.address?.postCode) {
        this.invalidAddress = true;
        return;
      }

      if (place && place.address) {
        const subPremise = place.address.subPremise ? place.address.subPremise + ' ' : '';
        const streetNumber = place.address.streetNumber ? place.address.streetNumber + ' ' : '';
        this.deliveryAddressLine1 = subPremise + streetNumber + place.address.address;
        this.deliverySuburb = place.address.suburb;
        this.deliveryState = place.address.state;
        this.deliveryPostcode = place.address.postCode;
        this.invalidAddress = false;
      }
    },
    searchForCompany: async function () {
      if (this.companySearch && this.companySearch.length > 0) {
        this.companySearchLoading = true;
        this.businessInfo = null;
        this.companySelected = null;
        try {
          const { rows } = await this.searchCompanies(this.companySearch);
          this.companySearchLoading = false;
          this.showResult = true;
          this.businessInfo = rows;
        } catch (err) {
          this.companySearchLoading = false;
        }
      }
    },
    showCompanyFields: function () {
      if (this.erpquote.customer.companyName || this.erpquote.customer.companyAbn) return true;
      if (this.$route.params.quoteId != 'new-quote') return true;
      return false;
    },
    sortCompanies: function () {
      if (this.companySearch && this.businessInfo.length > 0) {
        const sorted = this.businessInfo.sort((a, b) => {
          if (
            a.name.toLowerCase().indexOf(this.companySearch.toLowerCase()) > b.name.toLowerCase().indexOf(this.companySearch.toLowerCase())
          ) {
            return -1;
          } else if (
            a.name.toLowerCase().indexOf(this.companySearch.toLowerCase()) < b.name.toLowerCase().indexOf(this.companySearch.toLowerCase())
          ) {
            return 1;
          }
        });
        return sorted.slice(0, 10);
      } else if (!this.companySearch && this.businessInfo) {
        return this.businessInfo;
      } else {
        return [];
      }
    },

    saveOrderDetails: async function () {
      await this.checkEmailValidity();

      window.scrollTo(0, 0);
      if (!this.showDealership) {
        if (!this.dealership && this.dealers && this.dealers.rows && this.dealers.rows.length) this.dealership = this.dealers.rows[0].name;
      }
      this.$v.$touch();
      if (this.$v.$invalid || this.dragonBallUserEmail) {
        return;
      }

      if (!this.isExpired) {
        let leaseUpdatedValues = {
          leaseTerm: this.erpquote.leaseTerm,
          kmsPerYear: this.erpquote.kmsPerYear,
          availableOptions: {
            ...this.erpquote.availableOptions,
            ...(this.erpquote.availableOptions?.tyres && {
              tyres: this.erpquote.availableOptions.tyres === 'NONE' ? 0 : this.erpquote.availableOptions.tyres
            })
          },
          leaseStart: this.erpquote.leaseStart,
          leaseEnd: this.erpquote.leaseEnd
        };

        let colourAndExtrasUpdatedValues = {
          exteriorColour: this.erpquote.exteriorColour,
          interiorColour: this.erpquote.interiorColour,
          optionalEquipment: this.erpquote.optionalEquipment,
          adhocEquipment: this.erpquote.adhocEquipment,
          price: this.erpquote.price
        };

        let customerUpdatedValues = {
          customer: {
            companyName: this.companyName,
            companyNumber: this.companyNumber,
            companyStateCode: this.companyStateCode,
            companyPostCode: this.companyPostCode,
            companyAbn: this.companyAbn,
            customerName: this.customerName,
            customerContactNumber: this.customerContactNumber,
            customerContactEmail: this.customerContactEmail,
            deliveryAddressLine1: this.deliveryAddressLine1,
            deliveryAddressLine2: this.deliveryAddressLine2,
            deliverySuburb: this.deliverySuburb,
            deliveryState: this.deliveryState,
            deliveryPostcode: this.deliveryPostcode,
            deliveryDate: date.formatDate(date.extractDate(this.deliveryDate, 'DD/MM/YYYY', EnGbLang.date), 'YYYY-MM-DD', EnGbLang.date),
            dealership: this.showDealership ? this.dealership : '',
            registrationPostcode: this.registrationPostcode,
            haveNZBN: this.haveNZBN,
            soleTrader: this.soleTrader
          }
        };

        let updatedValues = {
          ...leaseUpdatedValues,
          ...colourAndExtrasUpdatedValues,
          ...customerUpdatedValues
        };

        this.saving = true;
        return await this.$apollo
          .mutate({
            mutation: MutateQuote,
            variables: {
              params: {
                ...this.saveQuoteFields,
                erpQuoteData: {
                  ...this.erpquote,
                  ...updatedValues
                }
              }
            },
            update: (store, response) => {
              if (response.data.saveQuote) {
                this.saveCustomerDetails({
                  ...response.data.saveQuote.response,
                  ...customerUpdatedValues,
                  customer: {
                    ...customerUpdatedValues.customer,
                    ...(response.data.saveQuote.response.salesforce && { salesforce: response.data.saveQuote.response.salesforce })
                  }
                });

                if (response.data.saveQuote.message === 'INVALID_ENTITY') {
                  const message =
                    'Your customer cannot be onboarded to our online lease application experience. For assistance, contact FleetPartners.';

                  this.$euiNotifications.emit({
                    notification: GlobalDialog,
                    noEscDismiss: true,
                    persistent: true,
                    body:
                      '<div class="col-12" style="text-align: center">' +
                      '<img src="/assets/images/alert.png" class="header-logo" />' +
                      '</div>' +
                      `<p>${message}</p>`,
                    qCardStyle: {
                      padding: '0',
                      width: '400px',
                      maxWidth: '80vw'
                    },
                    closeBtnStyle: {
                      display: 'none'
                    },
                    btnOne: {
                      label: 'Ok',
                      outline: true,
                      color: 'primary',
                      action: () => {}
                    }
                  });
                }
                this.goToNext();
              }
            }
          })
          .catch(({ graphQLErrors }) => {
            let errMessage = graphQLErrors.map((err) => {
              return err.message;
            });
            /*Extract error message */
            let message = errMessage.join().replace(/Error:|{}/gi, '');
            if (message && message.startsWith('{"extensions":') && message.includes('"message"') && message.endsWith('}}}}}')) {
              message = message
                .substring(message.indexOf('"message'), message.indexOf('"}}}}}'))
                .replace(/message|:|"/gi, '')
                .trim();
            }
            if (message && message.trim().endsWith('.')) {
              message = message.slice(0, -1);
            }
            if (/:|{|}|"|\?|%/.test(message)) {
              message = '';
            }
            this.$euiNotifications.emit({
              notification: GLOBAL_ERROR,
              action: () => {
                return this.saveOrderDetails();
              },
              message
            });
          })
          .finally(() => {
            this.saving = false;
          });
      } else {
        this.goToNext();
      }
    },
    dealerSearchScrolled: async function ({ index, to }) {
      let totalSelectCount = to + 1;
      // Do nothing if there were no rows, the query for new rows is in progress, the user did not scroll to the end or
      // the total number of potential rows have been fetched
      if (to < 1 || this.$apollo.queries.dealers.loading || index !== to || totalSelectCount >= this.dealers.count) {
        return;
      }
      let variables = this.dealerSearchQueryVariables;
      variables.params.page = parseInt(totalSelectCount / this.dealerQuerySize);
      await this.$apollo.queries.dealers.fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return {
            dealers: {
              count: fetchMoreResult.dealers.count,
              rows: [...this.dealers.rows, ...fetchMoreResult.dealers.rows],
              __typename: fetchMoreResult.dealers.__typename
            }
          };
        }
      });
      this.$nextTick(function () {
        this.$refs.dealerSelect.refresh();
      });
    },
    dealerSearchClosed: function () {
      if (this.dealership && this.dealership.id) {
        this.dealerSearch = this.dealership.name;
      }
    },
    selectDealer: function (value = {}) {
      this.dealership = value.name;
      this.dealerSearch = value ? value.name : null;
    },
    searchDealers: function (value) {
      this.dealerSearch = value;
    },
    addNewDealer: async function () {
      this.dealerSearchCreating = true;

      return await this.$apollo
        .mutate({
          mutation: MutateDealer,
          variables: {
            params: {
              name: this.dealerSearch ? this.dealerSearch : this.$store.getters['auth/user'].organisation.name,
              isActive: true
            }
          },
          update: (store, response) => {
            if (response.data.createDealer) {
              const dealer = response.data.createDealer.dealer.name;
              store.writeQuery({
                query: QueryDealers,
                data: {
                  dealers: {
                    count: 1,
                    rows: [dealer],
                    __typename: 'DealerResponse'
                  }
                }
              });
              if (this.showDealership) this.$refs.dealerSelect.hidePopup();
              this.dealership = dealer;
              this.dealers = { count: 1, rows: [dealer] };
            }
            this.dealerSearchCreating = false;
          }
        })
        .catch(() => {
          this.dealerSearchCreating = false;
          this.$euiNotifications.emit({
            notification: GLOBAL_ERROR,
            action: () => {
              return this.addNewDealer();
            }
          });
        });
    },
    checkEmailValidity: async function () {
      this.$v.customerContactEmail.$touch();
      if (!this.$v.customerContactEmail.$error) {
        if (this.customerContactEmail?.trim() == this.user.email.trim()) {
          this.dragonBallUserEmail = true;
        } else {
          this.emailValidationLoading = true;
          const userDetail = await this.$apollo
            .query({
              query: QueryUserByEmail,
              variables: {
                email: this.customerContactEmail
              }
            })
            .catch(() => {
              this.dealerSearchCreating = false;
              this.$euiNotifications.emit({
                notification: GLOBAL_ERROR,
                action: () => {
                  return this.checkEmailValidity();
                }
              });
            })
            .finally(() => {
              this.emailValidationLoading = false;
            });
          if (userDetail?.data?.getUserByEmail?.roles) {
            this.dragonBallUserEmail = userDetail?.data?.getUserByEmail?.roles.some((role) =>
              ['DB Eclipx Admin', 'DB Broker Admin', 'DB Broker Unrestricted', 'DB Broker Restricted', 'DB Eclipx User'].includes(role.name)
            );
          }
        }
      }
    }
  },
  created() {
    // If the prior page is invalid, go to the first incomplete page
    if (!this.$store.getters['quote/quoteColourAndExtrasValid']) {
      this.$router.replace({
        name: this.$store.getters['quote/firstIncompletePage'],
        params: { quoteId: this.$route.params.quoteId }
      });
    }

    this.companyName = this.erpquote.customer.companyName;
    this.companyNumber = this.erpquote.customer.companyNumber;
    this.companyStateCode = this.erpquote.customer.companyStateCode;
    this.companyPostCode = this.erpquote.customer.companyPostCode;
    this.companyAbn = this.erpquote.customer.companyAbn;
    this.customerName = this.erpquote.customer.customerName;
    this.customerContactNumber = this.erpquote.customer.customerContactNumber;
    this.customerContactEmail = this.erpquote.customer.customerContactEmail;
    this.deliveryAddressLine1 = this.erpquote.customer.deliveryAddressLine1;
    this.deliveryAddressLine2 = this.erpquote.customer.deliveryAddressLine2;
    this.deliverySuburb = this.erpquote.customer.deliverySuburb;
    this.deliveryState = this.erpquote.customer.deliveryState;
    this.deliveryPostcode = this.erpquote.customer.deliveryPostcode;
    let deliveryDate = this.erpquote.customer.deliveryDate
      ? date.extractDate(this.erpquote.customer.deliveryDate, 'YYYY-MM-DD', EnGbLang.date)
      : date.extractDate(this.erpquote.leaseStart, 'YYYY-MM-DD', EnGbLang.date);
    this.deliveryDate = date.formatDate(deliveryDate, 'DD/MM/YYYY', EnGbLang.date);
    this.dealership = this.erpquote.customer.dealership;
    this.registrationPostcode = this.erpquote.customer.registrationPostcode;

    this.nextIcon = Icons.evaArrowForward;
    this.plusIcon = Icons.evaPlusCircle;
    this.haveNZBN = this.erpquote.customer.haveNZBN;
    this.soleTrader = this.erpquote.customer.soleTrader;
    this.dealerSearch = this.erpquote.customer.dealership || null;
  },
  components: {
    QForm,
    QInput,
    QSeparator,
    EUIDateInput,
    EUIAutocomplete,
    DriveQuoteNavigationActions,
    QBtn,
    StateSelect,
    QSelect,
    QItem,
    QItemSection,
    QItemLabel,
    QIcon,
    QSpinner,
    QRadio,
    LoadingVehicle,
    ExistingCustomer,
    FormError
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
.EUISelect::v-deep
  input::placeholder
    color: #333333!important
</style>

import gql from 'graphql-tag';
export default gql`
  query getQuoteDetail($dbQuoteId: String) {
    getQuoteDetail(dbQuoteId: $dbQuoteId) {
      dbquote {
        id
        docusign_ref
        org_id
        quote_status_code
        is_request_pricing
      }
      erpquote {
        erpQuoteRef
        status
        product {
          id
          name
          description
        }
        make {
          id
          name
          description
        }
        bodyType {
          id
          name
        }
        model {
          id
          name
        }
        variant {
          id
          imageUrl
          baseImageUrl
          trimImageUrl
          listPrice
          listPriceGross
          modelYear
          trim
          derivative
          fuelType
          transmission
          transmissionGroupId
          engineDescription
          driveTrain
          seats
        }
        leaseStart
        leaseEnd
        leaseTerm
        kmsPerYear
        availableOptions
        exteriorColour {
          id
          code
          name
          standard
          requires
        }
        interiorColour {
          id
          code
          name
          standard
          requires
        }
        optionalEquipment {
          id
          code
          name
          amountNet
          requires
          description
          price
          catalogPrice
          isFreeFormOption
        }
        adhocEquipment {
          id
          name
          description
          price
          catalogPrice
          isFreeFormOption
        }
        customer {
          companyName
          companyNumber
          companyStateCode
          companyPostCode
          companyAbn
          customerName
          customerContactNumber
          customerContactEmail
          deliveryAddressLine1
          deliveryAddressLine2
          deliverySuburb
          deliveryState
          deliveryPostcode
          deliveryDate
          dealership
          registrationPostcode
          haveNZBN
          soleTrader
          salesforce {
            accountId
            opportunityId
            introducer
            creditTotalValue
            creditRemaining1
            erpRef
            leadId
          }
        }
        price {
          onRoadCost
          monthlyRentalNet
          weeklyRentalNet
        }
        quoteRef {
          quoteId
          milesQuoteRef
          vehicle {
            id
            vehicleTypeId
          }
        }
        itemisedPricing
      }
    }
  }
`;

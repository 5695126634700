export default {
  namespaced: true,
  state: {
    org: null,
    selectedOrg: null,
    orgSalesforce: null
  },
  getters: {
    org(state) {
      return state.org;
    },
    selectedOrg(state) {
      return state.selectedOrg;
    },
    getOrgErpCode: (state) => (orgId) => {
      const org = state.org.find((org) => org.id.match(orgId));

      return org?.erps[0]?.code || '';
    },
    isOrgDealer(state) {
      return state.orgSalesforce?.type == 'DEALER';
    }
  },
  mutations: {
    clearOrg(state) {
      state.org = null;
      state.selectedOrg = null;
    },
    setOrg(state, org) {
      state.org = org;
    },
    setSelectedOrg(state, selectedOrg) {
      state.selectedOrg = selectedOrg;
    },
    setOrgSalesforce(state, orgSalesforce) {
      state.orgSalesforce = orgSalesforce;
    }
  }
};

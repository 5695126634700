<template>
  <QPage class="column q-pa-lg">
    <div class="row">
      <h6
        :class="{
          'q-ma-none text-weight-bold text-grey-9': true,
          'q-mb-md': !$store.getters['quote/canCreate']
        }"
      >
        {{ this.title }}
      </h6>
      <div style="margin-left: 25px">
        <QBtn
          icon="img:/assets/images/copy.png"
          v-if="erpQuoteRef && $route.name === 'quoteReview'"
          color="primary"
          unelevated
          :ripple="false"
          label="Duplicate"
          @click="copyQuoteHandler"
        />
      </div>
    </div>

    <QuoteTabs :disabled="$apollo.queries.getQuoteDetail.loading" v-if="$store.getters['quote/canCreate']" />

    <QCard class="row justify-center items-center q-py-xl" v-if="$apollo.queries.getQuoteDetail.loading">
      <LoadingVehicle />
    </QCard>

    <div class="row q-gutter-md" v-else>
      <QCard class="col q-pa-lg self-start" bordered :key="dbquoteId">
        <div v-if="error" class="text-body3 text-grey-9">{{ error }}</div>
        <router-view v-else />
      </QCard>

      <div class="col-xs-4 col-sm-5 col-md-4 col-lg-4 col-xl-3">
        <QuoteSummaryCard
          :vehicle-loading="$apollo.queries.getQuoteDetail.loading"
          :finance-loading="$apollo.queries.getQuoteDetail.loading"
          :has-price="hasPrice"
          class="q-mb-md"
        />

        <QuoteReviewActions v-if="$route.name === 'quoteReview' && dbquoteId" />
      </div>
    </div>
  </QPage>
</template>

<script>
import { QPage, QCard, QBtn } from 'eclipx.ui';
import { mapState, mapMutations, mapGetters } from 'vuex';
import { NewQuoteRouteParam } from '../../router/Routes';
import QuoteTabs from './DriveQuoteTabs';
import QuoteSummaryCard from '../../components/QuoteSummaryCard/QuoteSummaryCard';
import QueryQuote from '../../graphql/queries/QueryQuote';
import QuoteReviewActions from '../../components/QuoteReviewActions/QuoteReviewActions';
import { initialQuote } from '../../store/modules/quotes/quoteStore';
import { GLOBAL_ERROR } from '../../components/GlobalError/GlobalError';
import LoadingVehicle from '../../components/LoadingVehicle/LoadingVehicle';

export default {
  name: 'DriveQuote',
  data() {
    return {
      error: null
    };
  },
  computed: {
    ...mapState({
      quote: (state) => state.quote.quote,
      dbquoteId: (state) => state.quote.quote.dbquote.id,
      erpQuoteRef: (state) => state.quote.quote.erpquote.erpQuoteRef,
      erpquote: (state) => state.quote.quote.erpquote
    }),
    ...mapGetters({
      isRequestPricing: 'quote/isRequestPricing',
      hasRequestedPricing: 'quote/hasRequestedPricing',
      hasPrice: 'quote/hasPrice'
    }),
    title() {
      if (this.$apollo.queries.getQuoteDetail.loading) {
        return 'Quote';
      }

      if (this.dbquoteId) {
        return `Quote: ${this.erpQuoteRef}`;
      } else {
        return 'New Quote';
      }
    },
    routeId() {
      return this.$route.params.quoteId;
    }
  },
  apollo: {
    getQuoteDetail: {
      query: QueryQuote,
      fetchPolicy: 'network-only',
      variables() {
        return {
          dbQuoteId: this.routeId
        };
      },
      skip() {
        return this.routeId === NewQuoteRouteParam || this.error;
      },
      update(data) {
        let quote = data.getQuoteDetail || initialQuote;

        if (quote.erpquote.availableOptions?.tyres) {
          quote.erpquote.availableOptions.tyres =
            quote.erpquote.availableOptions.tyres === 'NONE' ? 0 : quote.erpquote.availableOptions.tyres;
        }

        this.setQuote(quote);
        return quote;
      },
      error: function ({ graphQLErrors }) {
        const errMessage = graphQLErrors.map((err) => {
          return err.message;
        });
        /*Extract error message */
        let message = errMessage.join().replace(/Error:|{}/gi, '');
        if (message && message.startsWith('{"extensions":') && message.includes('"message"') && message.endsWith('}}}}}')) {
          message = message
            .substring(message.indexOf('"message'), message.indexOf('"}}}}}'))
            .replace(/message|:|"/gi, '')
            .trim();
        }
        if (message && message.trim().endsWith('.')) {
          message = message.slice(0, -1);
        }
        if (/:|{|}|"|\?|%/.test(message)) {
          message = '';
        }
        this.$euiNotifications.emit({
          notification: GLOBAL_ERROR,
          action: () => {
            return this.$apollo.queries.getQuoteDetail.refresh();
          },
          message
        });
      }
    }
  },
  created() {
    // Reset the stored quote if saved and going to a new quote route
    if (this.routeId === NewQuoteRouteParam && this.dbquoteId) {
      this.createNewQuote();
    }
  },
  methods: {
    ...mapMutations({
      createNewQuote: 'quote/createNewQuote',
      setQuote: 'quote/setQuote',
      copyQuote: 'quote/copyQuote'
    }),
    copyQuoteHandler: async function () {
      this.copyQuote();
      this.$router.push({
        name: 'quote',
        params: { quoteId: 'new-quote' }
      });
    }
  },
  components: {
    LoadingVehicle,
    QPage,
    QuoteTabs,
    QuoteSummaryCard,
    QCard,
    QuoteReviewActions,
    QBtn
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped></style>

import { initialQuote } from './quoteStore';

export default {
  setQuote(state, quote) {
    state.quote = { ...quote };
  },
  // Applies a list of fields to the dbquote
  setDbQuoteFields(state, dbQuoteFields) {
    state.quote = {
      ...state.quote,
      dbquote: {
        ...state.quote.dbquote,
        ...dbQuoteFields
      }
    };
  },
  // Applies a list of fields to the erp quote
  setErpQuoteFields(state, erpQuoteFields) {
    state.quote = {
      ...state.quote,
      erpquote: {
        ...state.quote.erpquote,
        ...erpQuoteFields
      }
    };
  },
  // Applies a list of fields to the erp quote price
  setErpQuotePriceFields(state, priceFields) {
    state.quote = {
      ...state.quote,
      erpquote: {
        ...state.quote.erpquote,
        price: {
          ...state.quote.erpquote.price,
          ...priceFields
        }
      }
    };
  },
  // Sets the quote to a 'new' empty state
  createNewQuote(state) {
    state.quote = { ...initialQuote };
  },
  // Clear quote fields from the car details page
  clearCarDetails(state) {
    state.quote.erpquote.product = {};
    state.quote.erpquote.make = {};
    state.quote.erpquote.bodyType = {};
  },
  // Clear quote fields from the car variant page
  clearCarVariant(state) {
    state.quote.erpquote.model = {};
    state.quote.erpquote.variant = {};
  },
  clearLeaseDetails(state) {
    state.quote.erpquote.availableOptions = [];
    state.quote.erpquote.leaseTerm = null;
    state.quote.erpquote.kmsPerYear = null;
    state.quote.erpquote.leaseStart = null;
    state.quote.erpquote.leaseEnd = null;
  },
  clearColourAndExtras(state) {
    state.quote.erpquote.exteriorColour = {};
    state.quote.erpquote.interiorColour = {};
    state.quote.erpquote.optionalEquipment = [];
    state.quote.erpquote.adhocEquipment = [];
    state.quote.erpquote.price.onRoadCost = null;
  },
  clearCustomerDetails(state) {
    state.quote.erpquote.customer = { ...initialQuote.erpquote.customer };
  },
  copyQuote(state) {
    state.quote.dbQuoteId = null;
    state.quote.dbquote = {};
    state.quote.erpquote = {
      ...state.quote.erpquote,
      erpQuoteRef: null,
      status: null,
      bodyType: { id: state.quote.erpquote?.bodyType?.name || state.quote.erpquote?.bodyType?.id },
      customer: { ...state.quote.erpquote.customer, salesforce: {} },
      quoteRef: {
        ...state.quote.erpquote.quoteRef,
        milesQuoteRef: null,
        quoteId: null
      },
      price: { onRoadCost: state.quote.erpquote.price.onRoadCost }
    };
  },
  //TODO: Add Copy Logic in ItemisedPricing
  copyItemisedPricingQuote(state) {
    state.quote.dbquote = {};
    state.quote.erpquote = {
      ...state.quote.erpquote,
      erpQuoteRef: null,
      status: null,
      customer: { ...state.quote.erpquote.customer, salesforce: {} },
      quoteRef: {}
    };
  },
  saveCompanyDetails(state, companyDetails) {
    state.quote = { ...state.quote };
    state.quote.erpquote = {
      ...state.quote.erpquote,
      customer: { ...state.quote.erpquote.customer, ...companyDetails }
    };
  }
};

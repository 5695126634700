<template>
  <MilesColourAndExtras v-if="isMilesNZ" />
  <DriveColourAndExtras v-else />
</template>

<script>
import { mapState } from 'vuex';
import MilesColourAndExtras from '@/components/MilesQuote/MilesColourAndExtras/MilesColourAndExtras';
import DriveColourAndExtras from '@/components/DriveQuote/DriveColourAndExtras/DriveColourAndExtras';

export default {
  name: 'QuoteColoursAndExtras',
  data() {
    return {
      saving: false,
      // Default value pre apollo query result
      vehicleOptions: {
        exteriorColours: [],
        interiorColours: [],
        optionalEquipment: [],
        includedEquipment: []
      },
      exteriorColour: null,
      interiorColour: null,
      optionalEquipment: [],
      adhocEquipment: [],
      onRoadCost: null,
      driveAwayOptions: 'DRIVEAWAYCOST'
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapState({
      selectedOrg: (state) => state.org.selectedOrg,
      user: (state) => state.auth.user
    }),
    isMilesNZ() {
      const erpCode = this.selectedOrg?.erps[0]?.code || this.user.organisation.erps[0].code;
      return erpCode === 'MILESNZ';
    }
  },
  components: {
    MilesColourAndExtras,
    DriveColourAndExtras
  }
};
</script>

<template>
  <div>
    <LoadingVehicle v-if="saving" />
    <QForm v-else @submit="saveQuoteLeaseDetails">
      <h6 class="q-mt-none q-mb-none text-grey-8">Lease Details</h6>
      <div class="flex justify-center q-mt-md" v-if="$apollo.queries.leaseOptions.loading">
        <QSpinner size="md" color="grey-7" />
      </div>
      <div v-else>
        <FormError :show="$v.$invalid && $v.$dirty" class="q-mt-md" />
        <div class="row q-col-gutter-md q-mt-sm">
          <div class="col-6">
            <p class="text-body1 text-grey-9 q-mb-xs text-weight-medium">Duration of Lease*</p>
            <LeaseDetailsSlider
              sliderType="month"
              :sliderValue.sync="leaseTerm"
              @sliderValueChanged="handleLeaseTermChanged"
              :step="1"
              :minimumValue="leaseOptions.leaseTerms[0].months"
              :maximumValue="leaseOptions.leaseTerms[leaseOptions.leaseTerms.length - 1].months"
              :isDisabled="isDisabled"
              :error="$v.leaseTerm.$error"
            />
          </div>
          <div class="col-6">
            <p class="text-body1 text-grey-9 q-mb-xs text-weight-medium">Estimated kms/year*</p>
            <LeaseDetailsSlider
              sliderType="kilometer"
              :sliderValue.sync="kmsPerYear"
              @sliderValueChanged="handleKmsPerYearChanged"
              :isDisabled="isDisabled"
              :error="$v.kmsPerYear.$error"
            />
          </div>
        </div>

        <!-- <div class="row q-col-gutter-x-md">
          <div class="col-6 q-mt-md" v-for="option in mappedAvailableOptions" :key="option.id">
            <EUIFormField
              :value="availableOptions[option.id]"
              :type="option.type"
              :disable="isDisabled"
              :required="option.required"
              :attributes="option.attributes"
              :error="$v.availableOptions[option.id].$error"
              @input="(value) => setAvailableField(option.id, value)"
            />
          </div>
        </div>

        <div class="row q-col-gutter-md q-mt-md">
          <div class="col-6">
            <p class="q-mb-md text-weight-bold text-grey-9">Inclusions</p>
            <div class="row no-wrap" v-for="inclusion in leaseOptions.included" :key="inclusion">
              <QIcon class="q-mr-sm icon-spacer" :name="checkmark" size="1rem" color="green-8" />
              <span class="text-grey-7">{{ inclusion }}</span>
            </div>
          </div>
          <div class="col-6">
            <p class="q-mb-md text-weight-bold text-grey-9">Exclusions</p>
            <div class="row no-wrap" v-for="exclusion in leaseOptions.excluded" :key="exclusion">
              <QIcon class="q-mr-sm icon-spacer" :name="close" size="1rem" color="grey-7" />
              <span class="text-grey-7">{{ exclusion }}</span>
            </div>
          </div>
        </div> -->
      </div>
      <MilesQuoteNavigationActions back-to="quoteCarVariants">
        <template slot="next" v-if="!$apollo.queries.leaseOptions.loading">
          <QBtn
            type="submit"
            :label="isDisabled ? 'Next' : 'Save and Next'"
            :icon-right="nextIcon"
            color="secondary"
            :ripple="false"
            :loading="saving"
            :disable="saving"
          />
        </template>
      </MilesQuoteNavigationActions>
    </QForm>
  </div>
</template>

<script>
import { QBtn, Icons, QForm, date, EnGbLang, QSpinner } from 'eclipx.ui';
import { GlobalDialog } from '@/components/Dialogs/GlobalDialog.vue';
import { required } from 'vuelidate/lib/validators';
import { mapActions, mapState, mapGetters } from 'vuex';
import MilesQuoteNavigationActions from '../MilesQuoteNavigationActions';
import FormError from '@/components/FormError/FormError';
import QueryLeaseOptions from '@/graphql/queries/QueryLeaseOptions';
import { GLOBAL_ERROR } from '@/components/GlobalError/GlobalError';
import LoadingVehicle from '@/components/LoadingVehicle/LoadingVehicle';
import UpdateMilesQuote from '@/graphql/mutations/UpdateMilesQuote';
import LeaseDetailsSlider from '@/components/LeaseDetails/LeaseDetailsSlider';
import validateTotalDistance from '@/utils/validate-total-distance';

export default {
  name: 'MilesQuoteLeaseDetails',
  data() {
    return {
      leaseOptions: {},
      leaseTerm: 24,
      kmsPerYear: 10000,
      leaseStart: null,
      availableOptions: {},
      saving: false
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  validations() {
    return {
      leaseTerm: { required },
      kmsPerYear: { required },
      leaseStart: { required },
      availableOptions: {
        ...this.mapOptionsToValue(this.leaseOptions.availableOptions, 'id', (option) => (option.required ? { required } : {}))
      }
    };
  },
  computed: {
    ...mapState({
      erpquote: (state) => state.quote.quote.erpquote,
      dbQuote: (state) => state.quote.quote.dbquote,
      milesQuoteRef: (state) => state.quote.quote.erpquote?.quoteRef?.milesQuoteRef
    }),
    ...mapGetters({
      isExpired: 'quote/isExpired',
      hasRequestedPricing: 'quote/hasRequestedPricing',
      saveQuoteFields: 'quote/saveQuoteFields',
      pricingConfiguration: 'orgProfile/pricingConfiguration'
    }),
    isDisabled: function () {
      return this.isExpired || this.hasRequestedPricing;
    },
    mappedAvailableOptions: function () {
      let options = {};
      this.leaseOptions.availableOptions.forEach((option) => {
        options[option.id] = {
          id: option.id,
          type: option.type,
          required: option.required,
          attributes: {
            'title': option.name,
            'label': option.name,
            'options': option.options,
            'option-label': 'name',
            'option-value': 'id',
            'disable': this.isDisabled
          }
        };
        // Set the correct select display value
        if (option.type === 'select') {
          let selectedOption = option.options.find((rowOption) => rowOption.id === this.availableOptions[option.id]) || {};
          options[option.id].attributes['display-value'] = selectedOption.name || this.availableOptions[option.id] || 'Select';
        }
      });
      return options;
    },
    selectedTerm: function () {
      let terms = this.leaseOptions.leaseTerms || [];
      if (!terms.length || !this.leaseTerm) {
        return {};
      }
      return terms.find((term) => term.months === this.leaseTerm) || {};
    },
    kmsPerYearOptions: function () {
      return this.selectedTerm.kmsPerYear || [];
    },
    leaseEnd: function () {
      if (!this.leaseTerm || !this.kmsPerYear || !this.leaseStart) {
        return null;
      }
      let startDate = this.extractFormattedDate(this.leaseStart);
      let endDate = date.addToDate(startDate, { month: this.leaseTerm });
      return date.formatDate(endDate, 'DD/MM/YYYY', this.$q.lang);
    }
  },
  apollo: {
    leaseOptions: {
      query: QueryLeaseOptions,
      variables() {
        return {
          productId: this.erpquote.product.id.toString()
        };
      },
      skip() {
        return !this.$store.getters['quote/quoteVariantValid'];
      },
      error: function ({ graphQLErrors }) {
        const errMessage = graphQLErrors.map((err) => {
          return err.message;
        });
        /*Extract error message */
        let message = errMessage.join().replace(/Error:|{}/gi, '');
        if (message && message.startsWith('{"extensions":') && message.includes('"message"') && message.endsWith('}}}}}')) {
          message = message
            .substring(message.indexOf('"message'), message.indexOf('"}}}}}'))
            .replace(/message|:|"/gi, '')
            .trim();
        }
        if (message && message.trim().endsWith('.')) {
          message = message.slice(0, -1);
        }
        if (/:|{|}|"|\?|%/.test(message)) {
          message = '';
        }
        this.$euiNotifications.emit({
          notification: GLOBAL_ERROR,
          action: () => {
            return this.$apollo.queries.leaseOptions.refresh();
          },
          message //:errMessage.join().replace(/Error:|{}/gi, '')
        });
      }
    }
  },
  watch: {
    'leaseOptions.availableOptions': function () {
      // Set available options from the store quote with defaults from the api available options
      this.availableOptions = {
        ...this.mapOptionsToValue(this.leaseOptions.availableOptions, 'id', (option) => {
          // Default is always returned as a string, however, checkbox and radio will need it cast to a boolean
          let isCheckboxOrRadio = ['radio', 'checkbox'].includes(option.type);
          return isCheckboxOrRadio ? JSON.parse(option.default) : option.default;
        }),
        ...this.erpquote.availableOptions
      };
    }
  },
  methods: {
    ...mapActions({ saveLeaseDetails: 'quote/saveLeaseDetails' }),
    extractFormattedDate(formattedDate) {
      return date.extractDate(formattedDate, 'DD/MM/YYYY', EnGbLang.date);
    },
    selectTerm() {
      let kmsPerYear = this.selectedTerm.kmsPerYear;
      if (kmsPerYear && kmsPerYear.indexOf(this.kmsPerYear) < 0) {
        this.kmsPerYear = null;
      }
    },
    // Reduce the given options into an object of an option id and value of a given function
    mapOptionsToValue(options, id, valueDefinition) {
      return options.reduce((acc, option) => {
        acc[option[id]] = valueDefinition(option);
        return acc;
      }, {});
    },
    setAvailableField(id, value) {
      this.availableOptions[id] = value;
    },
    goToNext() {
      this.$router.push({
        name: 'quoteColourAndExtras'
      });
    },
    saveQuoteLeaseDetails: async function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      if (this.validateTotalDistance()) {
        return;
      }

      if (!this.isDisabled) {
        let updatedValues = {
          leaseTerm: this.leaseTerm,
          kmsPerYear: this.kmsPerYear,
          availableOptions: {
            ...this.availableOptions,
            ...(this.availableOptions?.tyres && {
              tyres: this.availableOptions?.tyres === 'NONE' ? 0 : this.availableOptions.tyres
            }),
            videoTolling: false,
            fuelCards: ''
          },
          leaseStart: date.formatDate(this.extractFormattedDate(this.leaseStart), 'YYYY-MM-DD', EnGbLang.date),
          leaseEnd: date.formatDate(this.extractFormattedDate(this.leaseEnd), 'YYYY-MM-DD', EnGbLang.date)
        };

        if (this.milesQuoteRef && this.dbQuote?.id) {
          this.saving = true;
          return await this.$apollo
            .mutate({
              mutation: UpdateMilesQuote,
              variables: {
                params: {
                  dbQuoteId: this.dbQuote?.id,
                  milesQuoteRef: this.milesQuoteRef,
                  erpQuoteData: {
                    ...this.erpquote,
                    ...updatedValues,
                    ...(this.pricingConfiguration.isItemisedPricingEnabled && {
                      price: {
                        onRoadCost: null,
                        monthlyRentalNet: null,
                        weeklyRentalNet: null
                      }
                    })
                  }
                }
              },
              update: (store, response) => {
                if (response.data.updateMilesQuote) {
                  const { dbQuoteId, erpQuoteRef, erpStatus } = response.data.updateMilesQuote.response;

                  this.saveLeaseDetails({
                    ...this.erpquote,
                    dbQuoteId: this.dbQuote?.id ? this.dbQuote.id : dbQuoteId,
                    ...(this.dbQuote?.quote_status_code && {
                      dbStatus: this.dbQuote?.quote_status_code
                    }),
                    erpQuoteRef,
                    erpStatus,
                    ...updatedValues
                  });

                  this.goToNext();
                }
              }
            })
            .catch(() => {
              this.$euiNotifications.emit({
                notification: GLOBAL_ERROR,
                action: () => {
                  return this.saveQuoteLeaseDetails();
                }
              });
            })
            .finally(() => {
              this.saving = false;
            });
        } else {
          this.saveLeaseDetails({
            ...this.erpquote,
            ...updatedValues,
            ...(this.dbQuote?.id && {
              dbQuoteId: this.dbQuote?.id,
              dbStatus: this.dbQuote?.quote_status_code
            })
          });
          this.goToNext();
        }
      } else {
        return this.goToNext();
      }
    },
    handleLeaseTermChanged(value) {
      this.leaseTerm = parseInt(value);
    },
    handleKmsPerYearChanged(value) {
      this.kmsPerYear = parseInt(value);
    },
    validateTotalDistance() {
      const isDistanceOverMax = validateTotalDistance(this.kmsPerYear, this.leaseTerm);

      if (isDistanceOverMax) {
        const message =
          'The maximum distance for a quote for this vehicle  is 200,000kms. Reduce the Duration of Lease and/or Estimated kms/year to generate a quote.';

        this.$euiNotifications.emit({
          notification: GlobalDialog,
          noEscDismiss: true,
          persistent: true,
          body:
            '<div class="col-12" style="text-align: center">' +
            '<img src="/assets/images/alert.png" class="header-logo" />' +
            '</div>' +
            `<p>${message}</p>`,
          qCardStyle: {
            padding: '0',
            width: '400px',
            maxWidth: '80vw'
          },
          closeBtnStyle: {
            display: 'none'
          },
          btnOne: {
            label: 'OK',
            outline: true,
            color: 'primary',
            action: () => {}
          }
        });
      }

      return isDistanceOverMax;
    }
  },
  created() {
    // If the prior page is invalid, go to the first incomplete page
    if (!this.$store.getters['quote/quoteVariantValid']) {
      this.$router.replace({
        name: this.$store.getters['quote/firstIncompletePage'],
        params: { quoteId: this.$route.params.quoteId }
      });
    }

    this.leaseTerm = parseInt(this.erpquote.leaseTerm) || 24;
    this.kmsPerYear = parseInt(this.erpquote.kmsPerYear) || 10000;
    let startDate = this.erpquote.leaseStart
      ? date.extractDate(this.erpquote.leaseStart, 'YYYY-MM-DD', EnGbLang.date)
      : new Date(Date.now());
    this.leaseStart = date.formatDate(startDate, 'DD/MM/YYYY', EnGbLang.date);
    this.checkmark = Icons.evaCheckmark;
    this.close = Icons.evaClose;
    this.nextIcon = Icons.evaArrowForward;
    this.calendarIcon = Icons.evaCalendar;
  },
  components: {
    FormError,
    QBtn,
    QForm,
    MilesQuoteNavigationActions,
    QSpinner,
    LoadingVehicle,
    LeaseDetailsSlider
  }
};
</script>

<style lang="sass" scoped>
.icon-spacer
    margin-top: 2px
</style>

<template>
  <div class="bg-white q-pa-lg">
    <div class="row justify-between items-center q-mb-lg">
      <h6 class="q-ma-none text-weight-bold text-grey-9">Quotes</h6>
      <div class="row justify-end col-grow items-center q-gutter-x-md">
        <span class="text-weight-medium text-grey-8">Status</span>
        <QSelect
          class="col-6 col-sm-3 col-md-1"
          color="secondary"
          outlined
          dense
          v-model="quoteStatus"
          :options="statusOptions"
          option-label="name"
          :loading="$apollo.queries.dbQuoteStatuses.loading"
        />
        <QInput
          v-model.lazy="searchString"
          filled
          maxlength="50"
          color="secondary"
          dense
          hide-bottom-space
          clearable
          :loading="loadingSearch"
        />
        <QBtn type="input" label="Search" color="primary" unelevated :ripple="false" :loading="loadingSearch" @click.prevent="search" />
      </div>
    </div>
    <QTable
      flat
      :loading="$apollo.queries.dbquotesByUser.loading"
      :columns="columns"
      :data="dbquotesByUser.rows"
      :pagination.sync="pagination"
      @row-click="this.openQuote"
      @request="tableQuotesRequest"
      row-key="id"
    >
      <template v-slot:body-cell-actions="props">
        <QTd :props="props" key="actions">
          <div @click.stop style="font-size: 20px; width: 50px; height: 50px">
            <span style="margin-left: 5px; height: 3px; width: 3px; background-color: black; border-radius: 50%; display: inline-block" />
            <span style="margin-left: 3px; height: 3px; width: 3px; background-color: black; border-radius: 50%; display: inline-block" />
            <span style="margin-left: 3px; height: 3px; width: 3px; background-color: black; border-radius: 50%; display: inline-block" />
            <QPopupEdit v-model="props.row" slot="scope" :offset="[0, -22]" :cover="false">
              <QBtn flat dense label="Duplicate" style="font-size: 11px" @click.stop="copyQuote(props.row)" :loading="copyQuoteLoading" />
            </QPopupEdit>
          </div>
        </QTd>
      </template>
    </QTable>
  </div>
</template>

<script>
import { QTable, QSelect, QBtn, QInput, QTd, QPopupEdit } from 'eclipx.ui';
import quotesTableColumns from './quotesTableColumns';
//import QueryQuotes from '../../graphql/queries/QueryQuotes';
import QueryQuotesByUser from '../../graphql/queries/QueryQuotesByUser';
import QueryQuoteStatuses from '../../graphql/queries/QueryQuoteStatuses';
import { GLOBAL_ERROR } from '../../components/GlobalError/GlobalError';
import { mapMutations } from 'vuex';
import QueryQuoteDetail from '../../graphql/queries/QueryQuote';
import SearchQuote from '../../graphql/queries/QuerySearchQuote';

export default {
  name: 'Quotes',
  data() {
    return {
      columns: quotesTableColumns,
      quoteStatus: { name: 'Show All', code: null },
      dbQuoteStatuses: [],
      dbquotes: {},
      dbquotesByUser: {},
      sortBy: 'created_at',
      descending: true,
      page: 1,
      rowsPerPage: 20,
      loadingSearch: false,
      copyQuoteLoading: false,
      searchString: ''
    };
  },
  watch: {
    searchString: function (value) {
      if (!value) {
        this.$apollo.queries.dbquotesByUser.refresh();
      }
    }
  },
  computed: {
    pagination: {
      get: function () {
        return {
          sortBy: this.sortBy,
          descending: this.descending,
          page: this.page,
          rowsPerPage: this.searchString ? this.dbquotesByUser.count : this.rowsPerPage, //this.rowsPerPage,
          rowsNumber: this.dbquotesByUser.count
        };
      },
      set: function (newValue) {}
    },
    statusOptions: function () {
      return [{ name: 'Show All', code: null }, ...this.dbQuoteStatuses];
    },
    features() {
      return this.$store.getters['auth/feature'];
    },
    viewAllQuotes() {
      return this.features.indexOf('DB::ViewAllQuotes') > -1;
    },
    viewQuotes() {
      return this.features.indexOf('DB::ReadQuote') > -1;
    }
  },
  apollo: {
    dbQuoteStatuses: {
      query: QueryQuoteStatuses,
      error: function () {
        this.$euiNotifications.emit({
          notification: GLOBAL_ERROR,
          action: () => {
            return this.$apollo.queries.dbQuoteStatuses.refresh();
          }
        });
      }
    },
    dbquotesByUser: {
      query: QueryQuotesByUser,
      fetchPolicy: 'network-only',
      variables() {
        return {
          params: {
            page: this.page - 1, // Quasar table is 1 based, the api is 0 based
            size: this.rowsPerPage,
            sortCol: this.sortBy,
            sortType: this.descending ? 'desc' : 'asc',
            filterCol: 'quote_status_code',
            filterOp: 'eq',
            filterValue: this.quoteStatus ? this.quoteStatus.code : null
          },
          userId: this.viewAllQuotes ? null : this.viewQuotes ? this.$store.getters['auth/user'].id : 'no user'
        };
      },
      error: function ({ graphQLErrors }) {
        const errMessage = graphQLErrors.map((err) => {
          return err.message;
        });
        /*Extract error message */
        let message = errMessage.join().replace(/Error:|{}/gi, '');
        if (message && message.startsWith('{"extensions":') && message.includes('"message"') && message.endsWith('}}}}}')) {
          message = message
            .substring(message.indexOf('"message'), message.indexOf('"}}}}}'))
            .replace(/message|:|"/gi, '')
            .trim();
        }
        if (message && message.trim().endsWith('.')) {
          message = message.slice(0, -1);
        }
        if (/:|{|}|"|\?|%/.test(message)) {
          message = '';
        }
        this.$euiNotifications.emit({
          notification: GLOBAL_ERROR,
          action: () => {
            return this.$apollo.queries.dbquotesByUser.refresh();
          },
          message
        });
      }
    }
  },
  methods: {
    ...mapMutations({
      createNewQuote: 'quote/createNewQuote',
      setQuote: 'quote/setQuote'
    }),
    openQuote: function (event, row) {
      this.$router.push({
        name: 'quoteReview',
        params: { quoteId: row.id }
      });
    },
    tableQuotesRequest: function ({ pagination }) {
      this.sortBy = pagination.sortBy;
      this.descending = pagination.descending;
      this.page = pagination.page;
      this.rowsPerPage = pagination.rowsPerPage;
      // Ensure the data fields have been set before querying for the quotes list
      this.$nextTick(function () {
        this.$apollo.queries.dbquotesByUser.refresh();
      });
    },
    search: async function () {
      this.loadingSearch = true;
      const result = await this.$apollo
        .query({
          query: SearchQuote,
          fetchPolicy: 'network-only',
          variables: {
            params: {
              page: 0, //this.page - 1,
              size: 60, //this.rowsPerPage,
              sortCol: this.sortBy,
              sortType: this.descending ? 'desc' : 'asc',
              searchString: this.searchString
            },
            userId: this.viewAllQuotes ? null : this.viewQuotes ? this.$store.getters['auth/user'].id : 'no user'
          }
        })
        .catch(({ graphQLErrors }) => {
          let errMessage = graphQLErrors.map((err) => {
            return err.message;
          });
          /*Extract error message */

          let message = errMessage.join().replace(/Error:|{}/gi, '');
          if (message && message.startsWith('{"extensions":') && message.includes('"message"') && message.endsWith('}}}}}')) {
            message = message
              .substring(message.indexOf('"message'), message.indexOf('"}}}}}'))
              .replace(/message|:|"/gi, '')
              .trim();
          }
          if (message && message.trim().endsWith('.')) {
            message = message.slice(0, -1);
          }
          if (/:|{|}|"|\?|%/.test(message)) {
            message = '';
          }
          this.$euiNotifications.emit({
            notification: GLOBAL_ERROR,
            action: () => {
              return this.btnEventhandeler();
            },
            message
          });
        })
        .finally(() => {
          this.loadingSearch = false;
        });
      this.dbquotesByUser = result.data.searchQuote;
    },
    copyQuote: async function (row) {
      this.copyQuoteLoading = true;
      const result = await this.$apollo
        .query({
          query: QueryQuoteDetail,
          fetchPolicy: 'network-only',
          variables: {
            dbQuoteId: row.id
          }
        })
        .catch(({ graphQLErrors }) => {
          let errMessage = graphQLErrors.map((err) => {
            return err.message;
          });
          /*Extract error message */
          let message = errMessage.join().replace(/Error:|{}/gi, '');
          if (message && message.startsWith('{"extensions":') && message.includes('"message"') && message.endsWith('}}}}}')) {
            message = message
              .substring(message.indexOf('"message'), message.indexOf('"}}}}}'))
              .replace(/message|:|"/gi, '')
              .trim();
          }
          if (message && message.trim().endsWith('.')) {
            message = message.slice(0, -1);
          }
          if (/:|{|}|"|\?|%/.test(message)) {
            message = '';
          }
          this.$euiNotifications.emit({
            notification: GLOBAL_ERROR,
            action: () => {
              return this.btnEventhandeler();
            },
            message
          });
        })
        .finally(() => {
          this.copyQuoteLoading = false;
        });
      const {
        data: { getQuoteDetail }
      } = result;

      getQuoteDetail.dbQuoteId = null;
      getQuoteDetail.dbquote = {};

      if (getQuoteDetail.erpquote.availableOptions?.tyres === 'NONE') {
        getQuoteDetail.erpquote.availableOptions.tyres = 0;
      }

      getQuoteDetail.erpquote = {
        ...getQuoteDetail.erpquote,
        erpQuoteRef: null,
        status: null,
        bodyType: { id: getQuoteDetail.erpquote?.bodyType?.name },
        customer: { ...getQuoteDetail.erpquote.customer, salesforce: {} },
        quoteRef: {
          milesQuoteRef: null,
          quoteId: null
        },
        price: { onRoadCost: getQuoteDetail.erpquote.price.onRoadCost }
      };

      this.setQuote(getQuoteDetail);
      this.$router.push({
        name: 'quote',
        params: { quoteId: 'new-quote' }
      });
    }
  },
  created() {
    this.createNewQuote();
  },
  components: {
    QTable,
    QSelect,
    QBtn,
    QTd,
    QInput,
    QPopupEdit
  }
};
</script>

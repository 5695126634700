import gql from 'graphql-tag';
export default gql`
  mutation updateUserById($id: String!, $updateInfo: UpdateUser) {
    updateUserById(id: $id, updateInfo: $updateInfo) {
      id
      firstName
      lastName
      isActive
    }
  }
`;

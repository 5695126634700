<template>
  <QTabs
    align="justify"
    active-color="secondary"
    class="bg-white q-my-md rounded-borders text-grey-8 q-tabs--inset-indicator q-tabs--no-focus-helper"
  >
    <QRouteTab
      class="q-py-sm"
      :ripple="false"
      label="Administrators"
      :to="{ name: 'admins' }"
      v-if="$store.getters['auth/hasFeature']('DB::ManageAdmins')"
    />
    <QRouteTab
      class="q-py-sm"
      :ripple="false"
      label="Organisations"
      :to="{ name: 'organisations' }"
      v-if="$store.getters['auth/hasFeature']('DB::ManageOrganisations')"
    />
    <QRouteTab
      class="q-py-sm"
      :ripple="false"
      label="Signature Type"
      :to="{ name: 'signaturetype' }"
      v-if="$store.getters['auth/hasFeature']('DB::ManageAdmins')"
    />
  </QTabs>
</template>

<script>
import { QTabs, QRouteTab } from 'eclipx.ui';
import { mapState } from 'vuex';

/**
 * This component renders the tabs of the Quote pages.
 * The active tab is determined by matching the `:to` to the current route path.
 */
export default {
  name: 'SiteConfigTabs',
  props: {
    disabled: Boolean
  },
  components: {
    QTabs,
    QRouteTab
  }
};
</script>

<template>
  <div class="background">
    <div class="container">
      <div class="login">
        <div class="padding text-center">
          <div class="logo"></div>
          <h4>Set up New Password</h4>
        </div>
        <div class="padding">
          <div class="text-left" v-if="!submitted">
            <QForm @submit.prevent="onResetPassword">
              <InputField
                v-if="showEmail"
                class="full-width"
                label="Username"
                placeholder="yours@example.com"
                v-model="email"
                :required="true"
              />
              <InputField class="full-width" label="New Password" placeholder="" v-model="password" :required="true" type="password" />
              <InputField
                class="full-width"
                label="Confirm New Password"
                placeholder=""
                v-model="confirm_password"
                :required="true"
                type="password"
              />
              <ul>
                <li v-for="error in errors" class="text-danger" v-bind:key="error">
                  {{ error }}
                  <div class="q-mt-sm" v-if="error === 'Your token has expired, please reset your password.'">
                    <router-link
                      :to="{
                        path: `/login`,
                        query: { showLogin: false }
                      }"
                    >
                      Reset Password
                    </router-link>
                  </div>
                </li>
              </ul>
              <ul v-if="errosOnResponse.length">
                <li v-for="error in errosOnResponse" class="text-danger" v-bind:key="error">
                  {{ error }}
                  <div class="q-mt-sm" v-if="error === 'Your token has expired, please reset your password.'">
                    <router-link
                      :to="{
                        path: `/login`,
                        query: { showLogin: false }
                      }"
                    >
                      Reset Password
                    </router-link>
                  </div>
                </li>
              </ul>
              <QBtn class="full-width" color="primary" type="submit" no-caps :loading="submittingReset">SAVE NEW PASSWORD </QBtn>
            </QForm>
            <hr />
          </div>
          <div class="text-center" v-if="submitted">
            <hr />
            <h5>Your password has been updated.</h5>
            <hr />
          </div>
          <div class="text-right">
            <router-link to="/login">&lt; Back to Login</router-link>
          </div>
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QBtn, QForm } from 'quasar';
import InputField from '../../components/Atoms/InputField';
import MutateResetPassword from '../../graphql/mutations/MutateResetPasswordPublic';
import QueryOrganisationProfilesPublic from '../../graphql/queries/QueryOrganisationProfilesPublic';
import { GLOBAL_ERROR } from '../../components/GlobalError/GlobalError';

export default {
  name: 'ResetPassword',
  components: {
    QBtn,
    QForm,
    InputField
  },
  data: function () {
    return {
      email: '',
      password: '',
      confirm_password: '',
      submittingReset: false,
      errosOnResponse: [],
      submitted: false,
      showEmail: false
    };
  },
  async created() {
    // Call API to get whitelabel details for subdomain
    const theme = await this.getTheme();

    document.documentElement.style.setProperty('--primary-color', theme.primary);
    document.documentElement.style.setProperty('--background-color', theme.background);
    document.documentElement.style.setProperty('--login-background', theme.loginimage);
    document.documentElement.style.setProperty('--brand-image', theme.brandimage);
  },
  mounted() {
    setTimeout(() => {
      this.email = this.inputEmail ? decodeURIComponent(this.inputEmail) : '';
      this.showEmail = true;
    });
  },
  computed: {
    hash: function () {
      return this.$route.query.hash;
    },
    inputEmail: function () {
      return this.$route.query.email;
    },
    errors: function () {
      var errors = [];
      if (this.password != '') {
        if (this.password !== this.confirm_password) {
          errors.push('Passwords should match');
        }
        if (this.password.length < 8) {
          errors.push('Min password length of 8 characters');
        }
        var hasUpper = /[A-Z]/.test(this.password);
        var hasLower = /[a-z]/.test(this.password);
        var hasNumber = /[0-9]/.test(this.password);
        var hasConsecutive = /(.)\1{2,}/.test(this.password);
        if (!hasUpper) {
          errors.push('A least (1) upper case letter');
        }
        if (!hasLower) {
          errors.push('A least (1) lower case letter');
        }
        if (!hasNumber) {
          errors.push('A least (1) number, embedded in the password');
        }
        if (hasConsecutive) {
          errors.push('Max 2 consecutive identical characters');
        }
      }
      return errors;
    }
  },
  methods: {
    async getTheme() {
      let profile = {};

      let themeAppQuery = await this.$apollo.query({
        query: QueryOrganisationProfilesPublic,
        variables: {
          domain: window.location.hostname
        }
      });

      const profiles = themeAppQuery.data?.getOrgProfilesByDomainPublic;
      profile = profiles && profiles.length ? profiles[0] : this.MOCK_PROFILE;

      const auth0 = profile.auth0 || {};

      return {
        primary: auth0.primary || this.DEFAULT_PRIMARY,
        loginimage: auth0.backgroundImage || this.DEFAULT_IMAGE,
        brandimage: auth0.primaryLogo || this.DEFAULT_BRAND
      };
    },
    onResetPassword: async function () {
      if (this.errors.length == 0) {
        this.submittingReset = true;
        this.errosOnResponse = [];
        const res = await this.$apollo
          .mutate({
            mutation: MutateResetPassword,
            variables: {
              email: this.email,
              hash: this.hash,
              password: this.password
            }
          })
          .catch((error) => {
            let gqlError = error.graphQLErrors[0];
            this.error = gqlError ? gqlError.message : 'Error';
            if (error) {
              this.$euiNotifications.emit({
                notification: GLOBAL_ERROR
              });
            }
          });

        if (res && res.data && res.data.resetPassword) {
          if (res.data.resetPassword.error) this.errosOnResponse.push(res.data.resetPassword.error);
          else this.submitted = true;
        }
        this.submittingReset = false;
      }
    }
  }
};
</script>
<style lang="sass" scoped>
img
  width: 100%
  padding: 20px 0 0

p
  margin: 0

h4
  font-size: 23px
  line-height: 24px
  padding-bottom: 7px
  color: black

.container
  display: flex
  align-content: center
  align-items: center
  min-height: 100vh

.background
  background: var(--login-background)
  background-size: cover
  background-position: center
  margin-top: -25px
  padding-top: 25px
  min-height: calc(100vh + 25px)
  padding-left: 40px

.login
  width: 300px
  background: white

.padding
  padding: 0 20px

a
  color: var(--primary-color)

.logo
  background: var(--brand-image)
  margin: 10px 0
  height: 80px
  background-size: contain
  background-repeat: no-repeat
  background-position: center

ul
  list-style-type: circle
  padding: 0 0 0 20px
  margin-top: 0

li
  padding: 3px 0
  line-height: 17px
</style>

<template>
  <QIcon
    :class="{ 'cursor-pointer': !disabled }"
    :name="minusIcon"
    size="sm"
    outline
    round
    :disable="disabled"
    :color="disabled ? 'grey-5' : 'secondary'"
    @click="emit"
  />
</template>

<script>
import { QIcon, Icons } from 'eclipx.ui';

export default {
  name: 'RemoveIcon',
  props: {
    disabled: Boolean
  },
  methods: {
    emit() {
      if (!this.disabled) {
        this.$emit('input', null);
      }
    }
  },
  created() {
    this.minusIcon = Icons.evaMinusCircle;
  },
  components: {
    QIcon
  }
};
</script>

<template>
  <p v-if="show" class="flex text-red text-body3 q-mb-none error-text">
    You have entered a invalid value or have not completed the mandatory fields highlighted in red below. Please complete the mandatory
    fields highlighted in red below.
  </p>
</template>

<script>
/**
 * This component shows a common error message for forms
 */
export default {
  name: 'FormError',
  props: { show: Boolean }
};
</script>

<style lang="sass" scoped>
.error-text
  line-height: 16px
</style>

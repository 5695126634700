import { date, EnGbLang } from 'eclipx.ui';

export default [
  {
    name: 'firstName',
    label: 'First Name',
    align: 'left',
    field: 'firstName',
    sortable: true
  },
  {
    name: 'lastName',
    label: 'Last Name',
    align: 'left',
    field: 'lastName',
    sortable: true
  },
  {
    name: 'email',
    label: 'Email',
    align: 'left',
    field: 'email',
    sortable: true
  },
  {
    name: 'isActive',
    label: 'Status',
    align: 'left',
    field: 'isActive',
    sortable: true,
    format: (val, row) => {
      return row.isActive ? 'ACTIVE' : 'INACTIVE';
    }
  },
  {
    name: 'createdAt',
    label: 'Created At',
    align: 'left',
    sortable: true,
    field: 'createdAt',
    format: (val, row) => {
      const extractedDate = date.extractDate(row.createdAt, 'YYYY-MM-DDTHH:mm:ss.SSSZ', EnGbLang.date);
      return date.formatDate(extractedDate, 'DD/MM/YYYY', EnGbLang);
    }
  },
  {
    name: 'updatedAt',
    label: 'Last Updated',
    align: 'left',
    sortable: true,
    field: 'updatedAt',
    format: (val, row) => {
      const extractedDate = date.extractDate(row.updatedAt, 'YYYY-MM-DDTHH:mm:ss.SSSZ', EnGbLang.date);
      return date.formatDate(extractedDate, 'DD/MM/YYYY', EnGbLang);
    }
  }
];

<template>
  <div>
    <div class="row q-col-gutter-md q-mb-md">
      <span class="text-weight-bold col-12 text-grey-9"> Select the Vehicle Model</span>
      <div class="col-xs-12 col-md-6">
        <QSelect
          color="secondary"
          v-model="model"
          :options="models"
          outlined
          dense
          clearable
          emit-value
          :disable="isDisabled"
          :display-value="selectedModelObj ? selectedModelObj.name : null"
          option-label="name"
          option-value="id"
          :loading="$apollo.queries.models.loading"
        />
      </div>
    </div>
    <div class="row q-col-gutter-md q-mb-md">
      <div class="row col-xs-12 col-md-12" v-if="selectedModelObj && selectedModelObj.trimGroup && selectedModelObj.trimGroup.length > 1">
        <span class="text-weight-bold col-2 text-grey-9" style="margin-top: auto; margin-bottom: auto">Select the Variant</span>
        <div class="col-8">
          <QCheckbox
            v-for="trim in selectedModelObj.trimGroup"
            color="secondary"
            :disable="isDisabled"
            :key="trim.id"
            v-model="selectedTrim"
            :val="trim.id"
            :label="trim.name"
            @input="inputHandeler($event, 'trim', trim.id)"
          />
        </div>
      </div>
      <div
        class="row col-xs-12 col-md-12"
        v-if="selectedModelObj && selectedModelObj.transmissionGroups && selectedModelObj.transmissionGroups.length > 1"
      >
        <span class="col-2 text-weight-bold text-grey-9" style="margin-top: auto; margin-bottom: auto"> Select Transmission</span>
        <div class="col-8">
          <QCheckbox
            v-for="transmission in selectedModelObj.transmissionGroups"
            color="secondary"
            :disable="isDisabled"
            :key="transmission.id"
            v-model="selectedTransmissions"
            :val="transmission.id"
            :label="transmission.name"
            @input="inputHandeler($event, 'transmissionGroupId', transmission.id)"
          />
        </div>
      </div>

      <div
        class="row col-xs-12 col-md-12"
        v-if="selectedModelObj && selectedModelObj.fuleTypeGroup && selectedModelObj.fuleTypeGroup.length > 1"
      >
        <span class="col-2 text-weight-bold text-grey-9" style="margin-top: auto; margin-bottom: auto"> Select Fuel Type</span>
        <div class="col-8">
          <QCheckbox
            v-for="fuelType in selectedModelObj.fuleTypeGroup"
            color="secondary"
            :disable="isDisabled"
            :key="fuelType.id"
            v-model="selectedFule"
            :val="fuelType.id"
            :label="fuelType.name"
            @input="inputHandeler($event, 'fuelType', fuelType.id)"
          />
        </div>
      </div>
      <div
        class="row col-xs-12 col-md-12"
        v-if="selectedModelObj && selectedModelObj.driveTypeGroup && selectedModelObj.driveTypeGroup.length > 1"
      >
        <span class="col-2 text-weight-bold text-grey-9" style="margin-top: auto; margin-bottom: auto">Select the Drive Train</span>
        <div class="col-8">
          <QCheckbox
            v-for="driveTrain in selectedModelObj.driveTypeGroup"
            color="secondary"
            :disable="isDisabled"
            :key="driveTrain.id"
            v-model="selectedTrain"
            :val="driveTrain.id"
            :label="driveTrain.name"
            @input="inputHandeler($event, 'driveTrain', driveTrain.id)"
          />
        </div>
      </div>

      <div class="row col-xs-12 col-md-12" v-if="selectedModelObj && selectedModelObj.seatsGroup && selectedModelObj.seatsGroup.length > 1">
        <span class="col-2 text-weight-bold text-grey-9" style="margin-top: auto; margin-bottom: auto">Select Seats</span>
        <div class="col-8">
          <QCheckbox
            v-for="seat in selectedModelObj.seatsGroup"
            color="secondary"
            :disable="isDisabled"
            :key="seat.id"
            v-model="selectedSeat"
            :val="seat.id"
            :label="seat.name"
            @input="inputHandeler($event, 'seats', seat.id)"
          />
        </div>
      </div>
    </div>
    <QSeparator class="bg-grey-4" />

    <div v-if="variants" class="row q-col-gutter-md q-pt-md">
      <VehicleCard
        :card-styles="{
          'q-card--selectable': !isDisabled,
          'q-selected': variant === variantCard.id
        }"
        v-for="variantCard in this.variants"
        :disabled="isDisabled"
        @click="selectVariant"
        :key="variantCard.id"
        :id="variantCard.id"
        :image="variantCard.imageUrl"
        :trimImage="variantCard.trimImageUrl"
        :baseImage="variantCard.baseImageUrl"
        :price="variantCard.listPrice"
        :grossPrice="variantCard.listPriceGross"
        :title="`${selectedModelObj.makeName} ${selectedModelObj.name} ${variantCard.trim}`"
        :modelCode="variantCard.modelCode"
        :description="variantCard.derivative"
        :fuel-type="variantCard.fuelType"
        :transmission="variantCard.transmission"
        :engine="variantCard.engineDescription"
        :driveTrain="variantCard.driveTrain"
        :seats="variantCard.seats"
      />
    </div>
    <MilesQuoteNavigationActions back-to="quoteCarDetails" />
  </div>
</template>

<script>
import { QSelect, QSeparator, QCheckbox, Icons } from 'eclipx.ui';
import { mapGetters, mapState, mapActions } from 'vuex';
import VehicleCard from '@/components/VehicleCard/VehicleCard';
import { GlobalDialog } from '@/components/Dialogs/GlobalDialog';
import { GLOBAL_ERROR } from '@/components/GlobalError/GlobalError';
import MilesQuoteNavigationActions from '../MilesQuoteNavigationActions';
import QueryModels from '@/graphql/queries/QueryModels';

export default {
  name: 'MilesCarVariants',
  params: {
    quoteId: String
  },
  data() {
    return {
      model: null,
      variant: null,
      selectedModel: [],
      filtereBy: [],
      selectedTransmissions: [],
      selectedFule: [],
      selectedTrim: [],
      selectedTrain: [],
      selectedSeat: []
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapGetters({ residualValueVehicles: 'vehicle/getResidualValueVehicles' }),
    ...mapState({ erpquote: (state) => state.quote.quote.erpquote }),
    selectedModelObj: function () {
      if (!this.models) {
        return null;
      }
      return this.models.find((model) => model.id === this.model);
    },
    selectedVariant: function () {
      return this.variants.find((variant) => variant.id === this.variant);
    },
    variants: function () {
      let variants = [];

      if (this.selectedModelObj) {
        // Show all variants if no transmission is selected
        /*if (!this.selectedTransmissions.length) {
          return this.selectedModelObj.variants;
        }*/

        if (
          !this.selectedFule?.length &&
          !this.selectedTrim?.length &&
          !this.selectedTransmissions?.length &&
          !this.selectedTrain?.length &&
          !this.selectedSeat?.length
        ) {
          return this.selectedModelObj.variants;
        }

        this.selectedModelObj.variants.forEach((variant) => {
          if (this.getFilterCondition(variant, this.filtereBy)) {
            const date = new Date(variant.modelYear);
            const formatVariant = {
              ...variant,
              modelYear: variant?.modelYear ? date.getFullYear() : ''
            };

            variants.push(formatVariant);
          }
        });
        // Convert the selected transmission array into a map
        /*let transmissionMap = this.selectedTransmissions.reduce(
          (accumulator, currentValue) => ({
            ...accumulator,
            [currentValue]: true
          }),
          {}
        );
        // Filter by variants that have a selected transmission
        this.selectedModelObj.variants.forEach((variant) => {
          if (transmissionMap[variant.transmissionGroupId]) {
            const findVarient=variants.find(ele=>ele.id==variant.id)
            if(!findVarient) variants.push(variant);
          }
          if (transmissionMap[variant.fuelType]) {
            const findVarient=variants.find(ele=>ele.id==variant.id)
            if(!findVarient)variants.push(variant);
          }
          if (transmissionMap[variant.driveTrain]) {
            const findVarient=variants.find(ele=>ele.id==variant.id)
            if(!findVarient)variants.push(variant);
          }
        });*/
      }
      return variants;
    },
    isDisabled() {
      return Boolean(this.erpquote.erpQuoteRef);
    }
  },
  apollo: {
    models: {
      query: QueryModels,
      variables() {
        const erpquote = this.$store.getters['quote/quote'].erpquote;
        return {
          params: {
            productId: erpquote.product.id.toString(),
            makeId: erpquote.make.id,
            bodyTypeId: erpquote.bodyType.id
          }
        };
      },
      skip() {
        return !this.$store.getters['quote/quoteDetailsValid'];
      },
      error: function ({ graphQLErrors }) {
        const errMessage = graphQLErrors.map((err) => {
          return err.message;
        });
        /*Extract error message */
        let message = errMessage.join().replace(/Error:|{}/gi, '');
        if (message && message.startsWith('{"extensions":') && message.includes('"message"') && message.endsWith('}}}}}')) {
          message = message
            .substring(message.indexOf('"message'), message.indexOf('"}}}}}'))
            .replace(/message|:|"/gi, '')
            .trim();
        }
        if (message && message.trim().endsWith('.')) {
          message = message.slice(0, -1);
        }
        if (/:|{|}|"|\?|%/.test(message)) {
          message = '';
        }
        this.$euiNotifications.emit({
          notification: GLOBAL_ERROR,
          action: () => {
            return this.$apollo.queries.models.refresh();
          },
          message
        });
      }
    }
  },
  watch: {
    model: function () {
      // Deselect the transmissions when the model changes
      this.selectedTransmissions = [];
    }
  },
  methods: {
    selectVariant(variant, imageUrl) {
      if (this.isVariantInResidualValueVehicles(variant)) {
        const contactEmail = 'online@fleetpartners.co.nz';
        const message = `<p>This vehicle can not currently be quoted online. <br><br> Please contact our support team: <a href="mailto:${contactEmail}">${contactEmail}</a> or 0800 438 435 option 4 who will produce the quote for you. <p>`;

        return this.$euiNotifications.emit({
          notification: GlobalDialog,
          noEscDismiss: true,
          persistent: true,
          body:
            '<div class="col-12" style="text-align: center">' +
            '<img src="/assets/images/alert.png" class="header-logo" />' +
            '</div>' +
            `<p>${message}</p>`,
          qCardStyle: {
            padding: '0',
            width: '440px',
            maxWidth: '80vw'
          },
          closeBtnStyle: {
            display: 'none'
          },
          btnOne: {
            label: 'OK',
            outline: true,
            color: 'primary',
            action: () => {}
          }
        });
      }

      this.variant = variant;

      this.saveCarVariant({
        model: {
          id: this.model,
          name: this.selectedModelObj.name
        },
        variant: { ...this.selectedVariant, workingImageUrl: imageUrl }
      });

      this.$router.push({
        name: 'quoteLeaseDetails',
        params: { quoteId: this.$route.params.quoteId }
      });
    },
    ...mapActions({ saveCarVariant: 'quote/saveCarVariant' }),

    inputHandeler(selectedType, fieldType, value) {
      if (selectedType.length > 1 && fieldType == 'fuelType') {
        this.syncFilterBy('selectedFule', fieldType, value);
      } else if (selectedType.length > 1 && fieldType == 'trim') {
        this.syncFilterBy('selectedTrim', fieldType, value);
      } else if (selectedType.length > 1 && fieldType == 'transmissionGroupId') {
        this.syncFilterBy('selectedTransmissions', fieldType, value);
      } else if (selectedType.length > 1 && fieldType == 'driveTrain') {
        this.syncFilterBy('selectedTrain', fieldType, value);
      } else if (selectedType.length > 1 && fieldType == 'seats') {
        this.syncFilterBy('selectedSeat', fieldType, value);
      } else if (selectedType.length > 0) {
        const filterObjct = {};
        filterObjct[fieldType] = value;
        this.filtereBy.push(filterObjct);
      } else {
        const index = this.filtereBy.findIndex((object) => {
          return object[fieldType] === value;
        });
        this.filtereBy.splice(index, 1);
      }
    },
    syncFilterBy(selectedType, fieldType, value) {
      const filterObject = {};
      const valueToRemove = this[selectedType][0];
      this[selectedType] = [value];
      const index = this.filtereBy.findIndex((object) => {
        return object[fieldType] === valueToRemove;
      });
      this.filtereBy.splice(index, 1);
      filterObject[fieldType] = value;
      this.filtereBy.push(filterObject);
    },
    getFilterCondition(filterObject, filterByArry) {
      let keys = [];
      filterByArry.forEach((ele) => {
        const ks = Object.keys(ele);
        keys = [...keys, ...ks];
      });
      if (keys.length == 6) {
        return (
          filterObject[keys[0]] == filterByArry[0][keys[0]] &&
          filterObject[keys[1]] == filterByArry[1][keys[1]] &&
          filterObject[keys[2]] == filterByArry[2][keys[2]] &&
          filterObject[keys[3]] == filterByArry[3][keys[3]] &&
          filterObject[keys[4]] == filterByArry[4][keys[4]] &&
          filterObject[keys[5]] == filterByArry[5][keys[5]]
        );
      } else if (keys.length == 5) {
        return (
          filterObject[keys[0]] == filterByArry[0][keys[0]] &&
          filterObject[keys[1]] == filterByArry[1][keys[1]] &&
          filterObject[keys[2]] == filterByArry[2][keys[2]] &&
          filterObject[keys[3]] == filterByArry[3][keys[3]] &&
          filterObject[keys[4]] == filterByArry[4][keys[4]]
        );
      } else if (keys.length == 4) {
        return (
          filterObject[keys[0]] == filterByArry[0][keys[0]] &&
          filterObject[keys[1]] == filterByArry[1][keys[1]] &&
          filterObject[keys[2]] == filterByArry[2][keys[2]] &&
          filterObject[keys[3]] == filterByArry[3][keys[3]]
        );
      } else if (keys.length == 3) {
        return (
          filterObject[keys[0]] == filterByArry[0][keys[0]] &&
          filterObject[keys[1]] == filterByArry[1][keys[1]] &&
          filterObject[keys[2]] == filterByArry[2][keys[2]]
        );
      } else if (keys.length == 2)
        return filterObject[keys[0]] == filterByArry[0][keys[0]] && filterObject[keys[1]] == filterByArry[1][keys[1]];
      else if (keys.length == 1) return filterObject[keys[0]] == filterByArry[0][keys[0]];
      else return true;
    },
    isVariantInResidualValueVehicles(variantId) {
      return this.residualValueVehicles.some(({ catalogVehicleId }) => catalogVehicleId == variantId);
    }
  },
  created() {
    // If the prior page is invalid, go to the first incomplete page
    if (!this.$store.getters['quote/quoteDetailsValid']) {
      this.$router.replace({
        name: this.$store.getters['quote/firstIncompletePage'],
        params: { quoteId: this.$route.params.quoteId }
      });
    }

    this.model = this.erpquote.model.id;
    this.variant = this.erpquote.variant.id;

    this.backIcon = Icons.evaArrowBack;
  },
  components: {
    QSelect,
    QSeparator,
    QCheckbox,
    VehicleCard,
    MilesQuoteNavigationActions
  }
};
</script>

<style lang="sass" scoped></style>

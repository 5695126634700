<template>
  <div>
    <div class="row q-col-gutter-md q-mb-md">
      <div class="col col-grow col-lg-6">
        <QBtn
          class="q-pa-xs full-width"
          v-if="pdfDocumentLink"
          outline
          type="a"
          :ripple="false"
          color="grey-9"
          label="Signed PDF"
          :href="pdfDocumentLink"
        />
        <QBtn
          class="q-pa-xs full-width"
          v-else
          type="a"
          unelevated
          :ripple="false"
          color="primary"
          :loading="requestingPdf"
          :label="!isMilesNZ && existingCustomerWithCreditCoverage ? 'Download Quote' : 'Download Quote'"
          @click="downloadQuotePdf(false, 'quote_requisition')"
        />
      </div>
      <div class="col col-grow col-lg-6" v-if="existingCustomerWithCreditCoverage && !isMilesNZ">
        <QBtn
          class="q-pa-xs full-width"
          type="a"
          unelevated
          :ripple="false"
          color="secondary"
          :loading="requestingPdfEstimate"
          label="Download Quote Req"
          @click="downloadQuotePdf(true, 'estimate')"
        />
      </div>
      <div class="col col-grow" v-if="!isDisabled && (!hasPrice || (hasPrice && !existingCustomerWithCreditCoverage && !dragonBallOrg))">
        <QBtn
          v-if="!hasPrice"
          class="q-pa-xs full-width"
          color="grey-9"
          unelevated
          :disable="isAwaitingPrice || requestingPricing"
          :ripple="false"
          :label="isAwaitingPrice || requestingPricing ? 'Awaiting Quote...' : 'Request Quote'"
          @click="requestPricing"
        />
        <QBtn
          v-if="hasPrice && !existingCustomerWithCreditCoverage && !dragonBallOrg && !isStatusManualApplication"
          class="q-pa-xs full-width"
          color="secondary"
          unelevated
          :disable="isSubmitting || requestingSignature"
          :ripple="false"
          :label="saveAndSendLabel"
          @click="submitQuote"
        />
      </div>
    </div>
    <section v-if="existingCustomerWithCreditCoverage && isMilesNZ">
      <p class="text-grey-9">
        This is a current FleetPartners customer and does not need to be onboarded through our online lease application.
      </p>
      <p class="text-grey-9">
        Please contact our support team: <a href="mailto: online@fleetpartners.co.nz">online@fleetpartners.co.nz </a>a or 0800 438 435
        option 4.
      </p>
    </section>
    <section v-else-if="isMilesNZ && isStatusManualApplication && !existingCustomerWithCreditCoverage">
      <p class="text-grey-9">Your customer’s entity type is currently not supported by our online application.</p>
      <p class="text-grey-9">To start the application process, please contact our support team:</p>
      <p class="text-grey-9"><a :href="`mailto: online@fleetpartners.co.nz`">online@fleetpartners.co.nz</a> or 0800 438 435 option 4.</p>
    </section>
    <section v-else-if="!isMilesNZ && isStatusManualApplication && !existingCustomerWithCreditCoverage">
      <p class="text-grey-9">
        Your customer cannot be onboarded to our online lease application experience. For assistance, contact FleetPartners.
      </p>
    </section>
    <p class="text-grey-9" v-if="!isDisabled && caption">{{ caption }}</p>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { QBtn, date, EnGbLang } from 'eclipx.ui';
import MutateRequestSignature from '../../graphql/mutations/MutateRequestSignature';
import MutateSubmitQuote from '../../graphql/mutations/MutateSubmitQuote';
import MutateRequestPricing from '../../graphql/mutations/MutateRequestPricing';
import { GLOBAL_ERROR } from '../GlobalError/GlobalError';
import { GlobalDialog } from '../Dialogs/GlobalDialog';

import QueryQuotePdf from '@/graphql/queries/QueryQuotePdf';
import base64ToBlob from '@/utils/base64-to-blob';
import { createLinkAndDownload } from '@/utils/create-link-and-download';
import QueryDragonBallOrg from '../../graphql/queries/QueryDragonBallOrg';

/**
 * This component represents actions that can be taken against a quote at different stages of its completion.
 */
export default {
  name: 'QuoteReviewActions',
  data() {
    return {
      requestingPricing: false,
      requestingSignature: false,
      requestingPdf: false,
      requestingPdfEstimate: false
    };
  },
  computed: {
    ...mapState({
      dbquote: (state) => state.quote.quote.dbquote,
      erpquote: (state) => state.quote.quote.erpquote,
      user: (state) => state.auth.user,
      erpQuoteRef: (state) => state.quote.quote.erpquote.erpQuoteRef,
      milesQuoteRef: (state) => state.quote.quote.erpquote?.quoteRef?.milesQuoteRef
    }),
    ...mapGetters({
      isAwaitingPrice: 'quote/isAwaitingPrice',
      isSubmitting: 'quote/isSubmitting',
      isAccepted: 'quote/isAccepted',
      isExpired: 'quote/isExpired',
      isRequestPricing: 'quote/isRequestPricing',
      hasRequestedPricing: 'quote/hasRequestedPricing',
      hasPrice: 'quote/hasPrice',
      orgs: 'org/org',
      selected: 'org/selectedOrg',
      isOrgDealer: 'org/isOrgDealer'
    }),
    isDisabled: function () {
      return this.isAccepted || this.isExpired;
    },
    caption: function () {
      if (!this.hasPrice) {
        return this.isAwaitingPrice
          ? 'The price will automatically appear above once dealership have uploaded a quote'
          : 'Request a quote from your dealers';
      }

      if (this.isSubmitting) {
        return 'Quote and application has been sent to the customer';
      }

      if (this.existingCustomerWithCreditCoverage && !this.isMilesNZ) {
        return 'Existing customers are currently not supported by our online lease application experience. A quote requisition document is available via the Download Quote button above, please have it signed and return to your support team.';
      }

      return null;
    },
    pdfDocumentLink: function () {
      if (!this.isAccepted || !process.env.VUE_APP_PDF_DOCUMENT) {
        return null;
      }

      return `${process.env.VUE_APP_PDF_DOCUMENT}${this.dbquote.docusign_ref}`;
    },
    saveAndSendLabel: function () {
      if (this.requestingSignature) {
        return 'Sending Application...';
      }

      if (this.isSubmitting) {
        return 'Application Sent';
      }

      return 'Send Application';
    },
    docusignBlurb: function () {
      return `Dear ${this.erpquote.customer.customerName}, thank you for requesting this quote no. ${this.erpquote.erpQuoteRef}. By completing this form our team will be in contact with you shortly to answer any questions you may have and explain the next steps toward getting your new vehicle.`;
    },
    docusignSubject: function () {
      return `Quote Estimate ${this.erpquote.erpQuoteRef}`;
    },
    orgSfId() {
      if (!this.selected) {
        return this.user?.organisation?.salesForceAccountId;
      } else {
        return this.selected?.salesForceAccountId;
      }
    },
    organisationName() {
      return this.selected ? this.selected.name : this.user?.organisation?.name;
    },
    existingCustomerWithCreditCoverage() {
      const introducer = this.erpquote?.customer?.salesforce?.introducer;
      const creditTotalValue = this.erpquote?.customer?.salesforce?.creditTotalValue;

      if (this.orgSfId?.trim() === introducer?.trim() && creditTotalValue) {
        return true;
      }
      return false;
    },
    isMilesNZ() {
      const erpCode = this.selected?.erps[0]?.code || this.user?.organisation?.erps[0]?.code;
      return erpCode === 'MILESNZ';
    },
    isStatusManualApplication() {
      return this.dbquote?.quote_status_code === 'MANUALAPPLICATION';
    }
  },
  apollo: {
    dragonBallOrg: {
      query: QueryDragonBallOrg,
      variables() {
        return {
          abn: this.erpquote?.customer?.companyAbn
        };
      },
      skip() {
        return !this.erpquote?.customer?.companyAbn;
      },
      error: function () {
        this.$euiNotifications.emit({
          notification: GLOBAL_ERROR,
          action: () => {
            return this.$apollo.queries.dbQuoteStatuses.refresh();
          }
        });
      }
    }
  },
  methods: {
    ...mapMutations({ setDbQuoteFields: 'quote/setDbQuoteFields' }),
    requestPricing: async function () {
      this.requestingPricing = true;
      return await this.$apollo
        .mutate({
          mutation: MutateRequestPricing,
          variables: {
            params: {
              dbQuoteId: this.dbquote.id,
              erpQuoteRef: this.erpquote.erpQuoteRef,
              erpQuoteRevision: this.erpquote.erpQuoteRevision,
              brand: 'FleetPartners',
              createdBy: this.$store.getters['auth/user'].email
            }
          },
          update: (store, response) => {
            if (response.data.requestPricing) {
              let code = response.data.requestPricing.dealerPortal.quote_status_code;
              this.setDbQuoteFields({ quote_status_code: code });
            }
          }
        })
        .catch(() => {
          this.$euiNotifications.emit({
            notification: GLOBAL_ERROR,
            action: () => {
              return this.requestPricing();
            }
          });
        })
        .finally(() => {
          this.requestingPricing = false;
        });
    },
    downloadQuotePdf: async function (isFormalQuote, quoteType = 'quote_requisition') {
      if (quoteType == 'quote_requisition') {
        this.requestingPdf = true;
      } else if (quoteType == 'estimate') {
        this.requestingPdfEstimate = true;
      }
      const response = await this.$apollo
        .query({
          query: QueryQuotePdf,
          fetchPolicy: 'network-only',
          variables: {
            dbQuoteId: this.dbquote.id,
            formalQuote: isFormalQuote
          }
        })
        .catch(() => {
          this.requestingPdf = false;
          this.$euiNotifications.emit({
            notification: GLOBAL_ERROR,
            action: () => {
              return this.downloadQuotePdf(isFormalQuote);
            }
          });
        });

      if (response.data?.getQuotePDF?.pdfString) {
        const quoteId = this.milesQuoteRef || this.erpQuoteRef || this.dbquote?.id;
        let blob = base64ToBlob(response.data?.getQuotePDF?.pdfString, 'application/pdf');
        const url = URL.createObjectURL(blob);
        createLinkAndDownload(url, `Quote_${quoteId}.pdf`);
      }

      if (quoteType == 'quote_requisition') {
        this.requestingPdf = false;
      } else if (quoteType == 'estimate') {
        this.requestingPdfEstimate = false;
      }
    },
    saveAndSend: async function () {
      this.requestingSignature = true;
      return await this.$apollo
        .mutate({
          mutation: MutateRequestSignature,
          variables: {
            params: {
              dbQuoteId: this.dbquote.id,
              erpQuoteRef: this.erpquote.erpQuoteRef,
              emailSubject: this.docusignSubject,
              emailBlurb: this.docusignBlurb,
              customerName: this.erpquote.customer.customerName,
              customerEmail: this.erpquote.customer.customerContactEmail
            }
          },
          update: (store, response) => {
            if (response.data.requestSignature) {
              this.setDbQuoteFields(response.data.requestSignature.quote);
            }
          }
        })
        .catch(() => {
          this.$euiNotifications.emit({
            notification: GLOBAL_ERROR,
            action: () => {
              return this.saveAndSend();
            }
          });
        })
        .finally(() => {
          this.requestingSignature = false;
        });
    },
    submitQuote: async function () {
      this.requestingSignature = true;
      let deliveryDate = this.erpquote.customer.deliveryDate
        ? date.extractDate(this.erpquote.customer.deliveryDate, 'YYYY-MM-DD', EnGbLang.date)
        : date.extractDate(this.erpquote.leaseStart, 'YYYY-MM-DD', EnGbLang.date);
      return await this.$apollo
        .mutate({
          mutation: MutateSubmitQuote,
          variables: {
            params: {
              dbQuoteId: this.dbquote.id,
              dbQuoteOrgId: this.dbquote.id,
              erpQuoteRef: this.erpquote.erpQuoteRef,
              companyName: this.erpquote.customer.companyName,
              companyNumber: this.erpquote.customer.companyNumber,
              companyStateCode: this.erpquote.customer.companyStateCode,
              companyPostCode: this.erpquote.customer.companyPostCode,
              companyAbn: this.erpquote.customer.companyAbn,
              customerName: this.erpquote.customer.customerName,
              customerContactNumber: this.erpquote.customer.customerContactNumber,
              customerContactEmail: this.erpquote.customer.customerContactEmail,
              deliveryAddressLine1: this.erpquote.customer.deliveryAddressLine1,
              deliveryAddressLine2: this.erpquote.customer.deliveryAddressLine2,
              deliverySuburb: this.erpquote.customer.deliverySuburb,
              deliveryState: this.erpquote.customer.deliveryState,
              deliveryPostcode: this.erpquote.customer.deliveryPostcode,
              deliveryDate: date.formatDate(deliveryDate, 'DD/MM/YYYY', EnGbLang.date),
              dealership: this.erpquote.customer.dealership ? this.erpquote.customer.dealership : '',
              registrationPostcode: this.erpquote.customer.registrationPostcode,
              make: this.erpquote.make,
              bodyType: this.erpquote.bodyType,
              model: this.erpquote.model,
              rrp: this.erpquote.variant.listPriceGross,
              vehicleImage: this.erpquote.variant.imageUrl,
              variant: this.erpquote.variant,
              terms: parseInt(this.erpquote.leaseTerm),
              startDate: this.erpquote.leaseStart,
              endDate: this.erpquote.leaseEnd,
              options: this.erpquote.availableOptions,
              exteriorColour: this.erpquote.exteriorColour,
              interiorColour: this.erpquote.interiorColour,
              price: this.erpquote.price,
              kmsPerYear: parseInt(this.erpquote.kmsPerYear),
              accessories: this.erpquote.adhocEquipment,
              haveNZBN: this.erpquote.customer.haveNZBN,
              soleTrader: this.erpquote.customer.soleTrader,
              salesforce: this.erpquote.customer.salesforce,
              milesQuoteRef: this.erpquote?.quoteRef?.milesQuoteRef || null
            }
          },
          update: (store, response) => {
            if (response.data.submitQuote) {
              if (response.data.submitQuote.message === 'NOT_SUBMITTED' || response.data.submitQuote.message === 'INVALID_ENTITY') {
                let message =
                  'Your customer cannot be onboarded to our online lease application experience. For assistance, contact FleetPartners.';

                if (this.isMilesNZ) {
                  const email = 'online@fleetpartners.co.nz';

                  message =
                    response.data.submitQuote.message === 'INVALID_ENTITY'
                      ? `Your customer’s entity type is currently not supported by our online application.<br><br>To start the application process, please contact our support team:<br><a href="mailto: ${email}">${email}</a> or 0800 438 435 option 4.`
                      : `This is a current FleetPartners customer and does not need to be onboarded through our online lease application.<br><br>Please contact our support team: <a href="mailto: ${email}">${email}</a>  or 0800 438 435 option 4.`;
                }

                this.$euiNotifications.emit({
                  notification: GlobalDialog,
                  noEscDismiss: true,
                  persistent: true,
                  body:
                    '<div class="col-12" style="text-align: center">' +
                    '<img src="/assets/images/alert.png" class="header-logo" />' +
                    '</div>' +
                    `<p>${message}</p>`,
                  qCardStyle: {
                    padding: '0',
                    width: '400px',
                    maxWidth: '80vw'
                  },
                  closeBtnStyle: {
                    display: 'none'
                  },
                  btnOne: {
                    label: 'OK',
                    outline: true,
                    color: 'primary',
                    action: async () => {
                      this.setDbQuoteFields(response.data.submitQuote.quote);
                    }
                  }
                });
              } else {
                this.setDbQuoteFields(response.data.submitQuote.quote);
              }
            }
          }
        })
        .catch(({ graphQLErrors }) => {
          const errMessage = graphQLErrors.map((err) => {
            return err.message;
          });
          /*Extract error message */
          let message = errMessage.join().replace(/Error:|{}/gi, '');
          if (message && message.startsWith('{"extensions":') && message.includes('"message"') && message.endsWith('}}}}}')) {
            message = message
              .substring(message.indexOf('"message'), message.indexOf('"}}}}}'))
              .replace(/message|:|"/gi, '')
              .trim();
          }
          if (message && message.trim().endsWith('.')) {
            message = message.slice(0, -1);
          }
          if (/:|{|}|"|\?|%/.test(message)) {
            message = '';
          }
          this.$euiNotifications.emit({
            notification: GLOBAL_ERROR,
            action: () => {
              return this.submitQuote();
            },
            message
          });
        })
        .finally(() => {
          this.requestingSignature = false;
        });
    }
  },
  components: {
    QBtn
  }
};
</script>

<style lang="sass" scoped></style>

<template>
  <div class="flex q-mt-lg justify-between">
    <slot name="back">
      <QBtn v-if="!hideBack" label="Back" outline :icon="this.backIcon" color="grey-8" :ripple="false" @click="goBack" />
    </slot>
    <slot name="next" />
  </div>
</template>

<script>
import { QBtn, Icons } from 'eclipx.ui';

/**
 * This component encapsulates common actions for pages of a quote.
 * To omit the back button, set `hide-back` to true.
 * To omit the next button, do not include a template for the slot.
 */
export default {
  name: 'DriveQuoteNavigationActions',
  props: {
    hideBack: Boolean,
    backTo: String
  },
  methods: {
    goBack() {
      if (this.backTo) {
        this.$router.replace({
          name: this.backTo,
          params: { quoteId: this.$route.params.quoteId }
        });
      } else {
        this.$router.go(-1);
      }
    }
  },
  created() {
    this.backIcon = Icons.evaArrowBack;
  },
  components: {
    QBtn
  }
};
</script>

<style lang="sass" scoped></style>

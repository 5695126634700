<template>
  <section>
    <div v-if="loading" class="flex justify-center q-pa-md">
      <QSpinner size="md" color="grey-7" />
    </div>
    <div v-else>
      <div
        :class="{
          'q-px-lg q-py-md': !this.imageUrl,
          'q-mb-sm': erpquote.erpQuoteRef,
          'flex justify-center': true
        }"
      >
        <QImg
          v-if="variant.id"
          :src="this.imageUrl"
          :key="this.imageKey"
          spinner-color="grey-8"
          :ratio="16 / 9"
          basic
          contain
          :placeholder-src="this.placeholderUrl"
          @error="handleImageError"
        />
        <p v-else class="q-mb-none text-grey-7">No vehicle has been selected</p>
      </div>

      <QCardSection class="q-py-none" v-if="variant.id">
        <p class="q-ma-none text-weight-bold text-subtitle1 text-grey-9">
          {{ title }}
        </p>
        <p class="q-ma-none q-mt-xs text-body3 text-grey-8" v-if="variant.derivative">
          {{ variant.derivative }}
        </p>
        <div class="q-mt-sm" v-if="variant.transmission || variant.fuelType">
          <QChip v-if="variant.transmission" class="q-ma-none q-mr-sm text-uppercase text-weight-medium text-grey-8" size="sm">
            {{ variant.transmission }}
          </QChip>
          <QChip v-if="variant.fuelType" class="q-ma-none text-uppercase text-weight-medium text-grey-8" size="sm">
            {{ variant.fuelType }}
          </QChip>
        </div>
      </QCardSection>

      <div v-if="variant.id">
        <SummaryCardSpecifications />
        <SummaryCardIncludedExtras />
        <SummaryCardColoursAndExtras />
      </div>
    </div>
  </section>
</template>

<script>
import { QCardSection, QImg, QChip, QSpinner, Icons } from 'eclipx.ui';
import { mapState } from 'vuex';
import SummaryCardSpecifications from './SummaryCardSpecifications';
import SummaryCardIncludedExtras from './SummaryCardIncludedExtras';
import SummaryCardColoursAndExtras from './SummaryCardColoursAndExtras';

export default {
  name: 'SummaryCardCar',
  props: {
    loading: Boolean
  },
  data: function () {
    return {
      placeholderUrl: `${process.env.BASE_URL}assets/images/vehicleImagePlaceholder.svg`,
      imageUrl: '',
      imageKey: 0
    };
  },
  computed: {
    ...mapState({
      erpquote: (state) => state.quote.quote.erpquote,
      make: (state) => state.quote.quote.erpquote.make,
      model: (state) => state.quote.quote.erpquote.model,
      variant: (state) => state.quote.quote.erpquote.variant
    }),
    title: function () {
      return `${this.make.name || this.make.description || ''}
      ${this.model.name || ''}
      ${this.variant.trim || ''}`;
    }
  },
  watch: {
    variant: function (value) {
      if (value?.imageUrl) {
        this.imageUrl = this.erpquote.variant.workingImageUrl
          ? this.erpquote.variant.workingImageUrl
          : this.variant.trimImageUrl
          ? this.variant.trimImageUrl
          : this.variant.imageUrl;
      } else {
        this.imageUrl = '';
      }
    }
  },
  methods: {
    handleImageError() {
      if (this.imageKey == 5) {
        return;
      }

      if (this.variant.trimImageUrl == this.imageUrl && this.variant.imageUrl != this.imageUrl) {
        this.imageUrl = this.variant.imageUrl;
        this.imageKey += 1;
      } else if (this.variant.imageUrl == this.imageUrl && this.variant.baseImageUrl) {
        this.imageUrl = this.variant.baseImageUrl;
        this.imageKey += 1;
      }
    }
  },
  created() {
    this.close = Icons.evaClose;
    this.imageUrl = this.variant.workingImageUrl
      ? this.variant.workingImageUrl
      : this.variant.trimImageUrl
      ? this.variant.trimImageUrl
      : this.variant.imageUrl;
  },
  components: {
    QCardSection,
    QImg,
    QChip,
    QSpinner,
    SummaryCardSpecifications,
    SummaryCardIncludedExtras,
    SummaryCardColoursAndExtras
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped></style>

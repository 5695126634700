import gql from 'graphql-tag';
export default gql`
  query vehicleOptions($variantId: String, $templateId: String, $quoteDetail: JSON) {
    vehicleOptions(variantId: $variantId, templateId: $templateId, quoteDetail: $quoteDetail) {
      exteriorColours {
        id
        code
        name
        standard
        requires
        precludes
        catalogId
      }
      interiorColours {
        id
        code
        name
        standard
        requires
        precludes
        catalogId
      }
      optionalEquipment {
        id
        code
        name
        amountNet
        precludes
      }
      includedEquipment {
        id
        code
        name
        amountNet
      }
      quoteRef {
        quoteId
        milesQuoteRef
        vehicle {
          id
          vehicleTypeId
        }
      }
    }
  }
`;

<template>
  <div class="bg-white q-pa-lg" v-if="$store.getters['auth/whiteLabelEnabled']">
    <div class="bg-white q-pa-lg">
      <div class="row">
        <div class="col-xs-12 col-sm-3 col-md-3" style="padding: 25px">
          <h5>Log in Page</h5>
          <br />
          <p>This page will be the log in screen you and your customers will see.</p>
          <img src="/assets/images/whitelabel/passwordManagement.png" width="100%" />
        </div>
        <div class="col-xs-12 col-sm-9 col-md-9" style="padding: 25px">
          <strong>Your Companies Colours</strong>
          <p>By providing your company's colours, this will enable you to reflect your brand.</p>
          <br />
          <div class="row" style="width: 100%">
            <ColourPicker
              caption="Primary Colour"
              field="primary"
              info="Select your company's main colour or enter the HEX/ RGB code. This will be used for links and buttons."
              :colourProp="form.primary"
              @onColourChangeHandler="onColourChangeHandler"
            />
          </div>

          <hr style="border-top: 0.5px solid lightgray; margin: 30px 0px" />

          <strong>Image Library</strong>
          <p>Upload your company's logos and images.</p>
          <br />

          <div class="row" style="width: 100%">
            <ImagePicker
              caption="Company Logo"
              field="primaryLogo"
              info="Recommended aspect ratio 3:1<br/>Recommended size 250(w) x 90(h) px<br/>File format: PNG, JPG, GIF<br/>Max file size: 160KB"
              :imageProp="form.primaryLogo"
              @onImageChangeHandler="onImageChangeHandler"
            />

            <ImagePicker
              caption="Background Image"
              field="backgroundImage"
              info="Recommended aspect ratio 16:10<br/>Recommended size 1440(w) x 900(h) px<br/>File format: PNG, JPG, GIF<br/>Max file size: 160KB"
              :imageProp="form.backgroundImage"
              @onImageChangeHandler="onImageChangeHandler"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import ColourPicker from './Modules/ColourPicker';
import ImagePicker from './Modules/ImagePicker';

export default {
  name: 'AUTH0',
  data() {
    return {
      form: {
        primary: '',
        primaryLogo: '',
        backgroundImage: ''
      }
    };
  },
  computed: {
    ...mapGetters({ auth0: 'whiteLabel/auth0' })
  },
  created() {
    this.form.primary = this.auth0.primary;
    this.form.primaryLogo = this.auth0.primaryLogo;
    this.form.backgroundImage = this.auth0.backgroundImage;
  },
  mounted() {},
  methods: {
    ...mapMutations({ updateValueStatus: 'whiteLabel/updateValueStatus' }),
    onColourChangeHandler: function (colour, field) {
      this.form[field] = colour;
      this.updateValueStatus({
        object: 'auth0',
        variable: field,
        value: colour
      });
    },
    onImageChangeHandler: function (image, field) {
      this.form[field] = image;
      this.updateValueStatus({
        object: 'auth0',
        variable: field,
        value: image
      });
    }
  },
  components: {
    ColourPicker,
    ImagePicker
  }
};
</script>

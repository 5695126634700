<template>
  <MilesCarVariants v-if="isMilesNZ" />
  <DriveCarVariants v-else />
</template>

<script>
import { mapState } from 'vuex';
import MilesCarVariants from '@/components/MilesQuote/MilesCar/MilesCarVariants';
import DriveCarVariants from '@/components/DriveQuote/DriveCar/DriveCarVariants';

export default {
  name: 'CarVariants',
  computed: {
    ...mapState({
      selectedOrg: (state) => state.org.selectedOrg,
      user: (state) => state.auth.user
    }),
    isMilesNZ() {
      const erpCode = this.selectedOrg?.erps[0]?.code || this.user.organisation.erps[0].code;
      return erpCode === 'MILESNZ';
    }
  },
  components: {
    MilesCarVariants,
    DriveCarVariants
  }
};
</script>

<template>
  <div>
    <div v-if="isPending" style="padding: 20px; margin: 20px 0px; border: 1px solid gray; border-radius: 5px">
      <QIcon name="eva-alert-triangle" style="font-size: 30px; padding-right: 10px" /> Your changes are currently pending approval. You will
      be notified when the changes have been actioned.
    </div>
    <div class="bg-white q-pa-lg" v-if="$store.getters['auth/whiteLabelEnabled']">
      <WhiteLabelTabs />
      <div v-if="loading || saving" class="flex justify-center q-pa-md items-center" style="height: 400px">
        <QSpinner size="md" color="grey-7" />
      </div>
      <div v-if="!loading && !saving">
        <AUTH0 v-if="currentRouteName === 'white-label-auth0'" />
        <DragonBallContent v-if="currentRouteName === 'white-label-dragonball-content'" />
        <QuoteContent v-if="currentRouteName === 'white-label-quote-content'" />
        <ScoreBoardContent v-if="currentRouteName === 'white-label-scoreboard-content'" />
        <EmailTemplates v-if="currentRouteName === 'white-label-email-templates'" />
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12" style="padding-top: 25px">
        <FooterNav :nextUrlName="getNextLink" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { QSpinner, QIcon } from 'eclipx.ui';
import WhiteLabelTabs from './WhiteLabelTabs';
import FooterNav from './Modules/FooterNav';
import AUTH0 from './AUTH0';
import DragonBallContent from './DragonBallContent';
import QuoteContent from './QuoteContent';
import ScoreBoardContent from './ScoreBoardContent';
import EmailTemplates from './EmailTemplates';
import { GLOBAL_ERROR } from '../../components/GlobalError/GlobalError';
import QueryGetOrgProfileDetails from '../../graphql/queries/QueryGetOrgProfileDetails';

export default {
  name: 'WhiteLabelIndex',
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      saving: 'whiteLabel/saving',
      isPending: 'whiteLabel/isPending'
    }),
    currentRouteName() {
      return this.$route.name;
    },
    getNextLink() {
      if (this.currentRouteName === 'white-label-auth0') return 'white-label-dragonball-content';
      else if (this.currentRouteName === 'white-label-dragonball-content') return 'white-label-quote-content';
      else if (this.currentRouteName === 'white-label-quote-content') return 'white-label-scoreboard-content';
      else if (this.currentRouteName === 'white-label-scoreboard-content') return 'white-label-email-templates';
      else return null;
    }
  },
  apollo: {
    getOrgProfileDetails: {
      query: QueryGetOrgProfileDetails,
      fetchPolicy: 'network-only',
      result({ data, loading, networkStatus }) {
        if (
          data &&
          data.getOrgProfileDetails &&
          (data.getOrgProfileDetails.pending || data.getOrgProfileDetails.active) &&
          data.getOrgProfileDetails.whiteLabel &&
          data.getOrgProfileDetails.whiteLabel.enableWhiteLabel
        ) {
          this.clearWhiteLabel();
          if (data.getOrgProfileDetails.pending && data.getOrgProfileDetails.pending.auth0) {
            this.setWhiteLabel({ ...data.getOrgProfileDetails.pending, whiteLabel: data.getOrgProfileDetails.whiteLabel, isPending: true });
          } else {
            this.setWhiteLabel({ ...data.getOrgProfileDetails.active, whiteLabel: data.getOrgProfileDetails.whiteLabel });
            this.setIsPending(false);
          }
        } else this.$router.push({ name: 'quotes' });
      },
      watchLoading(isLoading, countModifier) {
        this.loading = isLoading;
      },
      error: function (error) {
        if (error) {
          this.$euiNotifications.emit({
            notification: GLOBAL_ERROR,
            action: () => {
              return this.$apollo.queries.getOrgProfileDetails.refresh();
            }
          });
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      setWhiteLabel: 'whiteLabel/setWhiteLabel',
      clearWhiteLabel: 'whiteLabel/clearWhiteLabel',
      setIsPending: 'whiteLabel/setIsPending'
    })
  },
  components: {
    QSpinner,
    QIcon,
    WhiteLabelTabs,
    AUTH0,
    DragonBallContent,
    QuoteContent,
    ScoreBoardContent,
    EmailTemplates,
    FooterNav
  }
};
</script>

<template>
  <button
    :disabled="disabled"
    type="submit"
    :class="{
      'button-override q-mt-sm text-weight-bold items-center flex': true,
      'text-secondary cursor-pointer': !disabled,
      'text-grey-6': disabled
    }"
  >
    <QIcon class="q-mr-sm" :name="plusIcon" size="xs" />
    <span class="q-ma-none text-body3">ADD {{ suffix }}</span>
  </button>
</template>

<script>
import { QIcon, Icons } from 'eclipx.ui';

/**
 * This component represents common UI for displaying a button to add content
 */
export default {
  name: 'AddButton',
  props: {
    suffix: String,
    disabled: Boolean
  },
  created() {
    this.plusIcon = Icons.evaPlusCircle;
  },
  components: {
    QIcon
  }
};
</script>

<style lang="sass" scoped>
.button-override
  border: none
  padding: 0
  outline: none
</style>

<template>
  <main class="q-page column q-pa-lg">
    <h6 class="q-mt-none q-mb-md text-weight-bold text-grey-9">New Dealer</h6>
    <div v-if="loading" class="flex justify-center q-pa-md">
      <QSpinner size="md" color="grey-7" />
    </div>
    <div class="row q-gutter-md">
      <div class="col q-pa-lg self-start q-card q-card--bordered">
        <div style="max-width: 400px">
          <QForm @submit="saveDealerDetails">
            <h6 class="q-mt-none q-mb-none text-grey-8">Dealer Details</h6>
            <div class="q-mt-md">
              <label class="text-body3" for="fname">Name:</label><br />
              <QInput v-model="name" filled maxlength="50" color="secondary" dense hide-bottom-space clearable required />
            </div>
            <div class="q-mt-md">
              <label class="text-body3" for="lname">Active:</label><br />
              <QCheckbox v-model="isActive" />
            </div>
            <div v-if="error" class="text-body3 validation-error">{{ error }}</div>
            <div class="q-mt-lg">
              <QBtn class="q-mr-sm" type="submit" :label="'Save'" color="secondary" :loading="saving" :disable="saving" :ripple="false" />
              <QBtn class="q-mr-sm" :label="'Cancel'" color="secondary" :ripple="false" @click="cancel" />
            </div>
          </QForm>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { QBtn, QForm, QInput, QCheckbox, QSpinner } from 'eclipx.ui';
import MutateDealer from '../../graphql/mutations/MutateDealer';
import QueryRoles from '../../graphql/queries/QueryRoles';

export default {
  name: 'Dealer',
  data() {
    return {
      saving: false,
      name: '',
      isActive: false,
      loading: false,
      error: null
    };
  },
  mounted() {
    this.id = this._uid;
  },
  methods: {
    saveDealerDetails: async function () {
      this.error = null;
      this.loading = true;
      const createDealer = await this.$apollo
        .mutate({
          mutation: MutateDealer,
          variables: {
            params: {
              name: this.name,
              isActive: this.isActive
            }
          }
        })
        .then((data) => {
          // Navigate to the new dealer
          this.loading = false;
          if (!this.error) {
            this.$router.push({
              name: 'dealers'
            });
          }
        })
        .catch((error) => {
          let gqlError = error.graphQLErrors[0];
          this.error = gqlError ? gqlError.message : 'Error';
        });
      return createDealer;
    },
    cancel: function () {
      this.$router.push({ name: 'dealers' });
    }
  },
  components: {
    QBtn,
    QForm,
    QInput,
    QCheckbox,
    QSpinner
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
.validation-error
    color: tomato
</style>

<template>
  <QForm @submit="addEquipment">
    <div class="row q-mb-sm q-col-gutter-md" v-for="(option, index) in mappedValues" :key="option.code">
      <QField class="col" dense read-only filled disable>
        <span class="q-field__native q-placeholder items-center row">
          {{ option.name }}
        </span>
      </QField>
      <div class="row col-shrink items-center" v-if="!required[option.id]">
        <RemoveIcon :disabled="disabled" @input="() => removeEquipment(index)" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <QSelect
          class="col"
          color="secondary"
          filled
          dense
          input-placeholder="test"
          clearable
          use-input
          hide-selected
          fill-input
          input-debounce="0"
          hide-bottom-space
          :label="selectInput || selectOpen ? undefined : 'Select or search'"
          v-model="selectedEquipment"
          :options="selectOptions"
          option-value="id"
          option-label="name"
          :read-only="disabled"
          :disable="disabled"
          :error="$v.selectedEquipment.$error"
          ref="select"
          popup-content-style="width: 0;"
          @clear="$v.$reset"
          @input-value="updateSelectInput"
          @filter="filterFn"
          @popup-show="selectOpen = true"
          @popup-hide="selectOpen = false"
        >
          <template v-slot:no-option>
            <QItem>
              <QItemSection class="text-grey"> No results </QItemSection>
            </QItem>
          </template>
        </QSelect>
        <AddButton :suffix="value.length > 0 ? 'ANOTHER' : ''" :disabled="disabled" />
      </div>
    </div>
  </QForm>
</template>

<script>
import { QSelect, QForm, QField, QItem, QItemSection } from 'eclipx.ui';
import AddButton from '@/components/AddButton/AddButton';
import RemoveIcon from '@/components/RemoveIcon/RemoveIcon';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'ColourAndExtrasOptionalEquipment',
  props: {
    value: {
      type: Array,
      required: true
    },
    disabled: Boolean,
    equipment: {
      type: Array,
      required: true
    },
    required: {
      type: Object,
      default: () => {}
    },
    precluded: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      selectedEquipment: null,
      selectOptions: [],
      selectInput: '',
      selectOpen: false
    };
  },
  watch: {
    equipment() {
      this.selectOptions = this.getSelectOptions();
      this.$refs.select.refresh();
    }
  },
  computed: {
    mappedValues: function () {
      return this.value.map((value) => {
        return this.equipment.find((equipment) => {
          return value === equipment.id;
        });
      });
    }
  },
  validations: {
    selectedEquipment: { required }
  },
  methods: {
    getSelectOptions: function () {
      // Hide precluded options
      let options = this.equipment.filter((equipment) => !this.precluded[equipment.id]);

      // Do not filter if there is no select input value
      if (!this.selectInput) {
        return options;
      }

      // Filter the options by the select input value
      return options.filter((equipment) => {
        let searchVal = `${equipment.name.toLowerCase()} ${equipment.code.toLowerCase()}`;
        return searchVal.indexOf(this.selectInput.toLowerCase()) > -1;
      });
    },
    filterFn: function (val, update) {
      update(() => {
        this.selectOptions = this.getSelectOptions();
      });
    },
    updateSelectInput: function (value) {
      this.selectInput = value;
    },
    addEquipment: function () {
      this.$v.$touch();
      if (this.$v.$invalid || !this.selectedEquipment) {
        return;
      }
      this.$emit('input', [...this.value, this.selectedEquipment.id]);
      this.selectedEquipment = null;
      this.$v.$reset();
    },
    removeEquipment: function (index) {
      if (this.disabled) {
        return;
      }
      let updatedValues = [...this.value];
      updatedValues.splice(index, 1);
      this.$emit('input', updatedValues);
    }
  },
  components: {
    QSelect,
    AddButton,
    QForm,
    QField,
    QItem,
    QItemSection,
    RemoveIcon
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped></style>

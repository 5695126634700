import Vue from 'vue';
import VueGtm from '@gtm-support/vue2-gtm';
import Vuelidate from 'vuelidate';
import VueCurrencyFilter from 'vue-currency-filter';

import { Eclipx, Router, Auth0, Auth0Guard, EUINotifications } from 'eclipx.ui';
import appConfig from './utils/app-config';
import loadGoogleMaps from './utils/load-google-maps';

import routes from './router/Routes';

import { createProvider } from './vue-apollo';

import store from './store';

import DragonballApp from './DragonballApp';

import './styles/index.sass';

import EUISessionKicker from '@eui-plugin/session-kicker/src';
import { Notify, Dialog } from 'quasar';

Vue.config.productionTip = false;

// Install the base Eclipx UI plugin
Vue.use(Eclipx, {
  quasar: {
    plugins: {
      Notify,
      Dialog
    }
  }
});

Vue.use(EUINotifications);

async function mount() {
  await loadGoogleMaps({
    key: process.env.VUE_APP_GOOGLE_KEY,
    libraries: ['places']
  });
}

// Setup Routing with Eclipx UI Vue Router plugin and application routes
const router = Router(Vue, { routes });

// Setup GTM
Vue.use(VueGtm, {
  id: appConfig.VUE_APP_GTM_ID,
  queryParams: {
    gtm_auth: appConfig.VUE_APP_GTM_AUTH,
    gtm_preview: appConfig.VUE_APP_GTM_PREVIEW
  }
});

// Setup Authentication with the Eclipx UI Auth0 plugin
Vue.use(Auth0, {
  domain: appConfig.VUE_APP_AUTH_DOMAIN,
  clientId: appConfig.VUE_APP_AUTH_CLIENT_ID,
  connection: appConfig.VUE_APP_AUTH_CONNECTION,
  onRedirectCallback: (appState) => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  }
});

// Add Authentication redirection
router.beforeEach(Auth0Guard);

// Add Vuelidate for component form validation
Vue.use(Vuelidate);

// Filters
Vue.use(VueCurrencyFilter, {
  symbol: '$',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: true
});

// 15 min kickout
Vue.use(EUISessionKicker, {
  /*sessionTimer:20*1000,
  warningTimer:5*1000,*/
  extendSessionCallBack: async () => {
    //const auth = Vue.prototype.$auth;
    const sessionKicker = Vue.prototype.$euiSessionKicker;
    //await getAccessToken(auth, sessionKicker);
    sessionKicker.resetTimer();
  },
  preLogoutHandler: () => {
    const auth = Vue.prototype.$auth;
    //auth.logoutSilently();
    const url = auth.auth0Client.buildLogoutUrl();
    auth.auth0Client.logout({ localOnly: true });
    auth.$set(auth, 'isAuthenticated', false);

    /*return fetch(url, {method: 'GET', credentials: 'include'});*/
  },
  logoutCallback: () => {
    router.push({ name: 'logout' });
  }
});

new Vue({
  router,
  store,
  apolloProvider: createProvider(),
  render: (h) => h(DragonballApp)
}).$mount('#app');

mount().catch((e) => {
  // eslint-disable-next-line
  console.error(e);
});

import gql from 'graphql-tag';
export default gql`
  query models($params: VehicleQuery) {
    models(params: $params) {
      id
      name
      makeName
      transmissionGroups {
        id
        name
      }
      fuleTypeGroup{
        id
        name
      }
      driveTypeGroup{
        id
        name
      }
      seatsGroup{
        id
        name
      }
      trimGroup{
        id
        name
      }
      variants {
        id
        imageUrl
        baseImageUrl
        trimImageUrl
        listPrice
        listPriceGross
        modelYear
        modelCode
        trim
        derivative
        fuelType
        transmission
        transmissionGroupId
        engineDescription
        driveTrain
        seats
      }
    }
  }
`;

import gql from 'graphql-tag';
export default gql`
  query getSalesforceAccountById($accountId: String) {
    getSalesforceAccountById(accountId: $accountId) {
      Id
      Type
      Name
    }
  }
`;

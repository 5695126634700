/**
 * @description
 * Validate the total distance of lease term
 * if it would reach morethan the max distance
 *
 * @param {Int} kmsPerYear
 * @param {Int} leaseTerm
 * @returns {Boolean}
 */
export default function validateTotalDistance(kmsPerYear, leaseTerm) {
  const MAX_DISTANCE = 200000;
  kmsPerYear = parseInt(kmsPerYear);
  leaseTerm = parseInt(leaseTerm);

  if (isNaN(kmsPerYear)) {
    kmsPerYear = 0;
  }

  if (isNaN(leaseTerm)) {
    leaseTerm = 0;
  }

  const isDistanceOverMax = (parseInt(kmsPerYear) * parseInt(leaseTerm)) / 12 > MAX_DISTANCE;

  return isDistanceOverMax;
}

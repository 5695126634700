<template>
  <div>
    <EUIApp :loading="loading" />
    <GlobalError />
    <GlobalDialog />
  </div>
</template>

<script>
import { EUIApp } from 'eclipx.ui';
import { mapMutations } from 'vuex';

import GlobalError from './components/GlobalError/GlobalError';

import GlobalDialog from './components/Dialogs/GlobalDialog';

import { onLogin } from './vue-apollo';

import QueryUser from './graphql/queries/QueryUser';

import appConfig from './utils/app-config';

/**
 * This component handles fetching a user one the authentication service has finished requesting data.
 * The authentication service email will be used to query for the users details.
 *
 * Before a user is loaded, a full page loading screen will be displayed.
 */
export default {
  name: 'DragonballApp',
  computed: {
    user: function () {
      return this.$store.getters['auth/user'];
    },
    // Show an application loading state while the authentication service is in progress or there is no fetched
    // application user
    loading: function () {
      if (this.$route.meta.skipAuthentication) {
        return false;
      }
      return !this.user || this.$auth.loading;
    }
  },
  watch: {
    // When this component first loads it is likely the authentication service is still fetching data.
    // Only call to get a user when the authentication service has finished
    '$auth.loading': function (loading) {
      if (!loading) {
        this.getUser();
      }
    }
  },
  created() {
    // If the authentication service has finished fetching data before the component was created, we can fetch a user
    if (!this.$auth.loading) {
      this.getUser();
    }
  },
  methods: {
    ...mapMutations({
      setUser: 'auth/setUser',
      clearUser: 'auth/clearUser',
      setFeature: 'auth/setFeature'
    }),
    async getUser() {
      // If the authentication is invalid, kick the user back to the login page
      if (!this.$auth.isAuthenticated) {
        return;
      }

      try {
        const token = await this.$auth.getIdTokenClaims();
        onLogin(this.$apollo, token.__raw);

        // Do not fetch a user if the route does not require authentication
        // Do not fetch a user if there is one with the same email as the authentication service
        if (this.$route.meta.skipAuthentication || (this.user && this.user.email === this.$auth.user.email)) {
          return;
        }

        // Prevent the loading state from being removed while the new user request is in progress
        this.clearUser();

        // Fetch details of the authenticated user
        let query = await this.$apollo.query({
          query: QueryUser,
          variables: {
            email: this.$auth.user.email,
            appCode: appConfig.VUE_APP_CODE
          }
        });

        const user = query.data.user;
        if (user) {
          const hasQuoteAccess = user.featureAllocation.find((el) => el.feature.code.includes('DB::ReadQuote'));
          const hasAdminAccess = user.featureAllocation.find((el) => el.feature.code.includes('DB::ManageAdmins'));
          const hasUserAccess = user.featureAllocation.find((el) => el.feature.code.includes('DB::ManageUsers'));

          this.setUser(query.data.user);
          if (!hasAdminAccess && user.organisation.isActive === false) {
            this.$router.push({ name: 'orgaccess' });
          } else if (!hasAdminAccess && (!user.isActive || !hasQuoteAccess)) {
            if (hasUserAccess) {
              this.$router.push({ name: 'orgaccess' });
            } else {
              this.$router.push({ name: 'useraccess' });
            }
          } else {
            const arrays = query.data.user.featureAllocation.map((f) => (f ? f.feature.code : null));
            const feature = [].concat.apply([], arrays);

            this.setFeature(feature);
          }
        } else {
          this.$auth.loading = false;
          this.$router.push({ name: 'error' });
        }
      } catch (e) {
        // Log out the authentication service if there was an error getting the user
        this.$auth.loading = false;
        this.$router.push({ name: 'error' });
      }
    }
  },
  components: {
    EUIApp,
    GlobalError,
    GlobalDialog
  }
};
</script>

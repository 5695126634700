<template>
  <div class="col">
    <QSelect
      color="secondary"
      filled
      dense
      clearable
      emit-value
      :disable="disabled"
      :read-only="disabled"
      :value="value"
      :options="options"
      option-value="id"
      option-label="name"
      :display-value="selectedColour.name"
      :error="error"
      @input="emit"
      ref="select"
    />
  </div>
</template>

<script>
import { QSelect } from 'eclipx.ui';

export default {
  name: 'ColourAndExtrasInteriorColour',
  props: {
    value: undefined,
    error: Boolean,
    disabled: Boolean,
    colours: {
      type: Array,
      required: true
    },
    precluded: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    options() {
      this.$refs.select.refresh();
    }
  },
  computed: {
    selectedColour: function () {
      let filterKey = 'id';
      if (!/^I/.test(this.value)) {
        filterKey = 'code';
      }
      return (
        this.colours.find((colour) => {
          return colour[filterKey] === this.value;
        }) || {}
      );
    },
    options: function () {
      return this.colours.filter((colour) => {
        return !this.precluded[colour.id];
      });
    }
  },
  methods: {
    emit: function (value) {
      this.$emit('input', value);
    }
  },
  components: {
    QSelect
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped></style>

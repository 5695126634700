<template>
  <MilesQuote v-if="isMilesNZ" />
  <DriveQuote v-else />
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import MilesQuote from '@/components/MilesQuote/MilesQuote';
import DriveQuote from '@/components/DriveQuote/DriveQuote';
import QuerySalesforceAccountById from '@/graphql/queries/QuerySalesforceAccountById';

export default {
  name: 'Quote',
  computed: {
    ...mapState({
      selectedOrg: (state) => state.org.selectedOrg,
      user: (state) => state.auth.user
    }),
    isMilesNZ() {
      const erpCode = this.selectedOrg?.erps[0]?.code || this.user.organisation.erps[0].code;
      return erpCode === 'MILESNZ';
    }
  },
  methods: {
    ...mapMutations({
      setOrgSalesforce: 'org/setOrgSalesforce'
    })
  },
  apollo: {
    getSalesforceAccountById: {
      query: QuerySalesforceAccountById,
      fetchPolicy: 'network-only',
      result: function (res) {
        if (res?.data?.getSalesforceAccountById) {
          const { Id: id, Name: name, Type: type } = res.data.getSalesforceAccountById;

          this.setOrgSalesforce({
            id,
            name,
            type
          });
        }
      },
      error: function ({ graphQLErrors }) {
        const errMessage = graphQLErrors.map((err) => {
          return err.message;
        });
        /*Extract error message */
        let message = errMessage.join().replace(/Error:|{}/gi, '');
        if (message && message.startsWith('{"extensions":') && message.includes('"message"') && message.endsWith('}}}}}')) {
          message = message
            .substring(message.indexOf('"message'), message.indexOf('"}}}}}'))
            .replace(/message|:|"/gi, '')
            .trim();
        }
        if (message && message.trim().endsWith('.')) {
          message = message.slice(0, -1);
        }
        if (/:|{|}|"|\?|%/.test(message)) {
          message = '';
        }

        this.$euiNotifications.emit({
          notification: GLOBAL_ERROR,
          action: () => {
            return this.$apollo.queries.dealers.refresh();
          },
          message
        });
      }
    }
  },
  components: {
    MilesQuote,
    DriveQuote
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped></style>

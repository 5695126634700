import gql from 'graphql-tag';
export default gql`
  mutation createDealer($params: DealerCreate!) {
    createDealer(params: $params) {
      code
      success
      message
      dealer {
        id
        name
        org_id
        isActive
      }
    }
  }
`;

import gql from 'graphql-tag';
export default gql`
  query options($typeId: String) {
    options(params: { typeId: $typeId }) {
      MWSOptions {
        MWSOption {
          id
          description
          consumerPrice
          catalogPrice
          isSelected
          catalogOptionType
        }
      }
      MWSAccessoires {
        MWSOption {
          id
          description
          consumerPrice
          catalogPrice
          isSelected
          catalogOptionType
        }
      }
    }
  }
`;

export default [
  {
    name: 'name',
    label: 'NAME',
    align: 'left',
    field: 'name',
    sortable: true
  },
  {
    name: 'abn',
    label: 'ABN',
    align: 'left',
    field: 'abn',
    sortable: true
  },
  {
    name: 'isActive',
    label: 'STATUS',
    align: 'left',
    field: 'isActive',
    sortable: true,
    format: (val, row) => {
      return row.isActive ? 'ACTIVE' : 'INACTIVE';
    }
  },
  {
    name: 'pricingConfiguration',
    label: 'PRICING CONFIGURATION',
    align: 'left'
  }
];

<template>
  <div class="col">
    <QSelect
      color="secondary"
      filled
      dense
      clearable
      emit-value
      hide-bottom-space
      :value="value"
      :options="options"
      option-value="id"
      option-label="name"
      :disable="disabled"
      :display-value="selectedColour.name"
      :error="error"
      @input="emit"
      ref="select"
    />
    <QRadio
      class="q-mr-sm"
      :val="true"
      color="secondary"
      label="Standard"
      :disable="!hasStandardColours || disabled"
      v-model="standard"
      @input="() => this.emit(null)"
    />
    <QRadio
      :val="false"
      color="secondary"
      label="Premium"
      :disable="!hasNonStandardColours || disabled"
      v-model="standard"
      @input="() => this.emit(null)"
    />
  </div>
</template>

<script>
import { QRadio, QSelect } from 'eclipx.ui';

export default {
  name: 'ColourAndExtrasColour',
  props: {
    value: undefined,
    error: Boolean,
    colours: {
      type: Array,
      required: true
    },
    disabled: Boolean,
    precluded: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      standard: true
    };
  },
  watch: {
    options() {
      this.$refs.select.refresh();
    }
  },
  computed: {
    hasStandardColours: function () {
      return this.colours.find((colour) => colour.standard);
    },
    hasNonStandardColours: function () {
      return this.colours.find((colour) => !colour.standard);
    },
    selectedColour: function () {
      let filterKey = 'id';
      if (!/^E/.test(this.value)) {
        filterKey = 'code';
      }
      return (
        this.colours.find((colour) => {
          return colour[filterKey] === this.value;
        }) || {}
      );
    },
    options: function () {
      return this.colours.filter((colour) => {
        return !this.precluded[colour.id] && colour.standard === this.standard;
      });
    }
  },
  methods: {
    emit: function (value) {
      this.$emit('input', value);
    }
  },
  mounted() {
    // Initially set the standard flag based on the selected colour
    // If there is no selected colour default to standard colours if there are any
    this.standard = this.selectedColour.id ? Boolean(this.selectedColour.standard) : Boolean(this.hasStandardColours);
  },
  components: {
    QRadio,
    QSelect
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped></style>

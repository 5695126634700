import gql from 'graphql-tag';
export default gql`
  mutation submitQuote($params: SubmitQuoteInput) {
    submitQuote(params: $params) {
      code
      message
      quote {
        docusign_ref
        quote_status_code
      }
      entityType
    }
  }
`;

export default {
  checkbox: {
    color: 'secondary',
    size: '28px'
  },
  select: {
    'color': 'secondary',
    'filled': true,
    'dense': true,
    'clearable': true,
    'emit-value': true,
    'hide-bottom-space': true
  },
  input: {
    'color': 'secondary',
    'filled': true,
    'dense': true,
    'hide-bottom-space': true,
    'debounce': 300
  },
  textarea: {
    'color': 'secondary',
    'filled': true,
    'dense': true,
    'hide-bottom-space': true,
    'debounce': 300,
    'autogrow': true
  }
};

<template>
  <div class="q-pa-xs col-12 col-sm-6">
    <label class="field-label">{{ label }}{{ required ? '*' : '' }}</label>
    <QInput
      ref="field"
      outlined
      v-model="localValue"
      :placeholder="placeholder"
      :rules="getRules()"
      :type="type"
      v-on:blur="onBlur()"
      :readonly="readonly"
      lazy-rules
    />
  </div>
</template>

<script>
import { QInput } from 'quasar';
/**
 * This component represents common UI for an input field with label
 */
export default {
  name: 'InputField',
  props: {
    label: String,
    placeholder: String,
    required: Boolean,
    value: String,
    type: String,
    readonly: Boolean,
    minLength: Number,
    maxLength: Number,
    length: Number,
    colClass: String
  },
  data() {
    return {
      localValue: '',
      validate: false
    };
  },
  methods: {
    onBlur: function () {
      this.validate = true;
      this.$refs.field.validate();
    },
    getRules: function () {
      const rules = [];
      if (this.required) {
        rules.push((val) => {
          if (!val || val == '') {
            return 'Field is required';
          }
          return true;
        });
      }
      if (this.minLength && this.minLength > 0) {
        rules.push((val) => {
          if (val && val.length < this.minLength) {
            return `Field value must have at least ${this.minLength} characters.`;
          }
          return true;
        });
      }
      if (this.maxLength && this.maxLength > 0) {
        rules.push((val) => {
          if (val && val.length > this.maxLength) {
            return `Field value must have at most ${this.maxLength} characters.`;
          }
          return true;
        });
      }
      if (this.length && this.length > 0) {
        rules.push((val) => {
          if (val && val.length != this.length) {
            return `Field value length must equal to ${this.length}`;
          }
          return true;
        });
      }
      if (this.type == 'email') {
        rules.push((val) => {
          if (this.validate && !this.validateEmail(val)) {
            return 'Field needs to be an email address';
          }
          return true;
        });
      }
      if (this.type == 'tel') {
        rules.push((val) => {
          if (!this.validateTel(val)) {
            return 'Field needs to be a telephone number';
          }
          return true;
        });
      }
      if (this.type == 'number') {
        rules.push((val) => {
          if (!this.validateTel(val)) {
            return 'Field needs to be digits';
          }
          return true;
        });
      }
      if (this.type == 'auState') {
        rules.push((val) => {
          if (!this.validateAUState(val)) {
            return 'Invalid state, please re-enter';
          }
          return true;
        });
      }
      if (this.type == 'nzState') {
        rules.push((val) => {
          if (!this.validateNZState(val)) {
            return 'Invalid town, please re-enter';
          }
          return true;
        });
      }
      return rules;
    },
    validateEmail: function (email) {
      const re =
        /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
      return re.test(String(email).toLowerCase());
    },
    validateAUState: function (state) {
      if (state) return ['nsw', 'qld', 'sa', 'tas', 'vic', 'wa', 'act', 'nt'].includes(state.toLowerCase());
      else return false;
    },
    validateNZState: function (city) {
      const re = /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/;
      return re.test(String(city));
    },
    validateTel: function (phone) {
      const re = /^[+]{0,1}[()0-9 ]*$/;
      return re.test(String(phone));
    }
  },
  created() {
    this.localValue = this.value;
    this.$watch('localValue', (value) => {
      this.$emit('input', value);
    });
  },
  components: { QInput }
};
</script>

<template>
  <div>
    <EUIPageLoading v-show="loading" />
    <EUIPrimaryLayout v-show="!loading">
      <template slot="header">
        <Header />
      </template>

      <template slot="footer">
        <Footer />
      </template>
    </EUIPrimaryLayout>
  </div>
</template>

<script>
import { EUIPageLoading, EUIPrimaryLayout } from 'eclipx.ui';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import { mapMutations, mapState } from 'vuex';
import QueryWhiteLabelByUser from '@/graphql/queries/QueryWhiteLabelByUser';
import { GLOBAL_ERROR } from '@/components/GlobalError/GlobalError';

export default {
  name: 'DragonballLayout',
  mounted() {
    this.root = document.documentElement;
    this.root.style.setProperty('--q-color-accent', '#e0e0e0');
  },
  computed: {
    ...mapState({
      whiteLabel: (state) => state.whiteLabel.whiteLabel
    }),
    loading() {
      return this.$apollo.queries.getWhiteLabelByUser.loading;
    }
  },
  methods: {
    ...mapMutations({
      setOrgProfile: 'orgProfile/setOrgProfile'
    })
  },
  apollo: {
    getWhiteLabelByUser: {
      query: QueryWhiteLabelByUser,
      fetchPolicy: 'network-only',
      error: function (error) {
        if (error) {
          this.$euiNotifications.emit({
            notification: GLOBAL_ERROR,
            action: () => {
              return this.$apollo.queries.getWhiteLabelByUser.refresh();
            }
          });
        }
      },
      result: function (res) {
        this.$store.commit('auth/clearWhiteLabel');
        const whiteLabelByUser = res && res.data && res.data.getWhiteLabelByUser && res.data.getWhiteLabelByUser;

        if (whiteLabelByUser && whiteLabelByUser.whiteLabel) {
          this.$store.commit('auth/setWhiteLabel', whiteLabelByUser.whiteLabel);
        }
        if (whiteLabelByUser) {
          this.setOrgProfile(whiteLabelByUser);
          // Create New Styles
          const primary = whiteLabelByUser.dragonball?.primary;
          const secondary = whiteLabelByUser.dragonball?.secondary;
          const background = whiteLabelByUser.dragonball?.background;
          this.root = document.documentElement;
          if (primary) {
            this.root.style.setProperty('--q-color-primary', primary);
            this.root.style.setProperty('--primary-color', primary);
          }
          if (secondary) {
            this.root.style.setProperty('--q-color-secondary', secondary);
          }
          if (background) {
            this.root.style.setProperty('--q-color-accent', background);
          }
        }
      }
    }
  },
  components: {
    EUIPageLoading,
    EUIPrimaryLayout,
    Header,
    Footer
  }
};
</script>

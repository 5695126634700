<template>
  <div class="bg-white q-pa-lg" v-if="$store.getters['auth/hasFeature']('DB::ManageUsers')">
    <AdminTabs />
    <!-- <div class="row justify-between items-center q-mb-lg">
      <h6 class="q-ma-none text-weight-bold text-grey-9">Users</h6>
    </div> -->
    <div class="row justify-end items-center" style="text-align: right; padding-bottom: 15px">
      <div>
        <span class="text-weight-medium text-grey-8">Filter (min 4 letters) : &nbsp;&nbsp;</span>
      </div>
      <div class="col-md-2 col-sm-4 col-xs-6">
        <QInput class="q-mr-sm" v-model.lazy.trim="keyword" filled maxlength="50" color="secondary" dense hide-bottom-space clearable />
      </div>
      <div>
        <QBtn class="q-mr-sm" :to="{ name: 'NewUser', params: { userId: this.NewUserRouteParam } }" color="secondary" label="New User" />
      </div>
    </div>
    <QTable
      flat
      :loading="$apollo.queries.getUserByRole.loading"
      :columns="columns"
      :data="getUserByRole.rows"
      :pagination.sync="pagination"
      @row-click="this.editUser"
      @request="onRequest"
    >
      <template v-slot:body-cell-commission="props">
        <QTd :props="props">
          <div @click.stop>
            <QBtn
              dense
              color="grey-8"
              :icon="getCommissionSettings(props.row.pricingConfiguration.commissions.status).icon"
              outline
              style="border-radius: 22.5px; width: 45px"
            >
              <QTooltip :offset="[-3, -3]" anchor="bottom right" self="top left">
                {{ getCommissionSettings(props.row.pricingConfiguration.commissions.status).label }}
              </QTooltip>
            </QBtn>
          </div>
        </QTd>
      </template>
      <template v-slot:body-cell-quoteVisibility="props">
        <QTd :props="props">
          <div @click.stop>
            <QBtn
              dense
              color="grey-8"
              :icon="getVisibility(props.row.roles[0].name).icon"
              outline
              style="border-radius: 22.5px; width: 45px"
            >
              <QTooltip :offset="[-3, -3]" anchor="bottom right" self="top left">
                {{ getVisibility(props.row.roles[0].name).label }}
              </QTooltip>
            </QBtn>
          </div>
        </QTd>
      </template>
    </QTable>
  </div>
</template>

<script>
import { QTable, QBtn, QInput, QTd, QTooltip, Icons } from 'eclipx.ui';
import { mapState } from 'vuex';
import usersTableColumns from './usersTableColumns';
import QueryUsers from '../../graphql/queries/QueryUsers';
import { GLOBAL_ERROR } from '../../components/GlobalError/GlobalError';
import AdminTabs from './AdminTabs';
import { NewUserRouteParam } from '../../router/Routes';

export default {
  name: 'Users',
  data() {
    return {
      columns: usersTableColumns,
      getUserByRole: {},
      sortBy: 'createdAt',
      descending: true,
      page: 1,
      rowsPerPage: 20,
      keyword: '',
      NewUserRouteParam: NewUserRouteParam
    };
  },
  computed: {
    ...mapState({
      selectedOrg: (state) => state.org.selectedOrg,
      user: (state) => state.auth.user
    }),
    pagination: {
      get: function () {
        return {
          sortBy: this.sortBy,
          descending: this.descending,
          page: this.page,
          rowsPerPage: this.rowsPerPage,
          rowsNumber: this.getUserByRole.count
        };
      },
      set: function (newValue) {}
    },
    hasRole() {
      return this.$store.getters['auth/hasRole'];
    },
    isMilesNZ() {
      const erpCode = this.selectedOrg?.erps[0]?.code || this.user.organisation.erps[0].code;
      return erpCode === 'MILESNZ';
    }
  },
  apollo: {
    getUserByRole: {
      query: QueryUsers,
      fetchPolicy: 'network-only',
      variables() {
        return {
          params: {
            page: this.page - 1, // Quasar table is 1 based, the api is 0 based
            size: this.rowsPerPage,
            sortBy: this.sortBy,
            sortType: this.descending ? '-' : ' ',
            keyword: this.keyword && this.keyword.length >= 4 ? this.keyword : null,
            role: 'DB%Broker',
            hasPricingConfig: true
          }
        };
      },
      error: function ({ graphQLErrors }) {
        const errMessage = graphQLErrors.map((err) => {
          return err.message;
        });
        /*Extract error message */
        let message = errMessage.join().replace(/Error:|{}/gi, '');
        if (message && message.startsWith('{"extensions":') && message.includes('"message"') && message.endsWith('}}}}}')) {
          message = message
            .substring(message.indexOf('"message'), message.indexOf('"}}}}}'))
            .replace(/message|:|"/gi, '')
            .trim();
        }
        if (message && message.trim().endsWith('.')) {
          message = message.slice(0, -1);
        }
        if (/:|{|}|"|\?|%/.test(message)) {
          message = '';
        }
        this.$euiNotifications.emit({
          notification: GLOBAL_ERROR,
          action: () => {
            return this.$apollo.queries.getUserByRole.refresh();
          },
          message
        });
      }
    }
  },
  created() {
    this.editIcon = Icons.evaEditOutline;
    this.peopleIcon = Icons.evaPeopleOutline;
    this.personIcon = Icons.evaPersonOutline;
    this.eyeOffIcon = Icons.evaEyeOffOutline;
    this.eyeIcon = Icons.evaEyeOutline;

    if (this.isMilesNZ) {
      this.columns = [
        ...this.columns,
        {
          name: 'quoteVisibility',
          label: 'Quote Visibility',
          align: 'left'
        },
        {
          name: 'commission',
          label: 'Commission',
          align: 'left'
        }
      ];
    }
  },
  mounted() {
    this.onRequest({
      pagination: this.pagination,
      filter: undefined
    });
  },
  methods: {
    getVisibility: function (value) {
      const visibility = [
        { role: 'DB Broker Restricted', label: 'Own quotes', icon: this.personIcon },
        { role: 'DB Broker Unrestricted', label: 'All quotes', icon: this.peopleIcon },
        { role: 'DB Broker Admin', label: 'All quotes', icon: this.peopleIcon }
      ];

      return visibility.find(({ role }) => role == value) || '';
    },
    getCommissionSettings: function (value) {
      if (!value) {
        return { label: 'Hidden', icon: this.eyeOffIcon };
      }
      const commissionSettings = [
        { status: 'show', label: 'Visible', icon: this.eyeIcon },
        { status: 'edit', label: 'Can edit', icon: this.editIcon },
        { status: 'hide', label: 'Hidden', icon: this.eyeOffIcon }
      ];

      return commissionSettings.find(({ status }) => status == value) || { label: 'Hidden', icon: this.eyeOffIcon };
    },
    editUser: function (event, row) {
      this.$router.push({
        name: 'userEdit',
        params: { userId: row.id }
      });
    },
    onRequest: function (props) {
      const { page, rowsPerPage, sortBy, descending } = props.pagination;
      const filter = props.filter;

      setTimeout(() => {
        this.page = page;
        this.rowsPerPage = rowsPerPage;
        this.sortBy = sortBy;
        this.descending = descending;
      }, 500);
    }
  },
  components: {
    QTable,
    QBtn,
    QInput,
    AdminTabs,
    QTd,
    QTooltip
  }
};
</script>

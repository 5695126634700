import { date, EnGbLang } from 'eclipx.ui';

export default [
  {
    name: 'id',
    label: 'Quote Ref#',
    align: 'left',
    field: 'erp_quote_ref',
    sortable: true,
    format: (val, row) => {
      return row.miles_quote_ref ? row.miles_quote_ref : row.erp_quote_ref;
    }
  },
  {
    name: 'vehicle_name',
    label: 'Vehicle',
    align: 'left',
    field: 'vehicle_name',
    sortable: true
  },
  {
    name: 'customer_company_name',
    label: 'Customer Company Name',
    align: 'left',
    field: 'customer_company_name',
    sortable: true
  },
  {
    name: 'customer_full_name',
    label: 'Customer Full Name',
    align: 'left',
    field: 'customer_full_name',
    sortable: true
  },
  // {
  //   name: 'dealer_name',
  //   label: 'Dealership',
  //   align: 'left',
  //   field: 'dealer_name',
  //   sortable: true
  // },
  {
    name: 'quote_status_code',
    label: 'Status',
    align: 'left',
    field: 'quote_status_code',
    sortable: true
  },
  {
    name: 'created_at',
    label: 'Created At',
    align: 'left',
    sortable: true,
    field: 'created_at',
    format: (val, row) => {
      const extractedDate = date.extractDate(row.created_at, 'YYYY-MM-DDTHH:mm:ss.SSSZ', EnGbLang.date);
      return date.formatDate(extractedDate, 'DD/MM/YYYY', EnGbLang.date);
    }
  },
  {
    name: 'updated_at',
    label: 'Last Updated',
    align: 'left',
    sortable: true,
    field: 'updated_at',
    format: (val, row) => {
      const extractedDate = date.extractDate(row.updated_at, 'YYYY-MM-DDTHH:mm:ss.SSSZ', EnGbLang.date);
      return date.formatDate(extractedDate, 'DD/MM/YYYY', EnGbLang);
    }
  },
  {
    name: 'createdBy',
    label: 'Created By',
    align: 'left',
    sortable: true,
    field: 'created_by'
  },
  {
    name: 'actions',
    label: 'Action',
    align: 'left'
  }
];

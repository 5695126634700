<template>
  <div>
    <QSelect
      v-model="companySelected"
      filled
      color="secondary"
      dense
      use-input
      fill-input
      hide-selected
      popup-content-class="limit-q-menu-height"
      :options="filterCompanyOptions"
      :option-label="getLabel"
      :loading="$apollo.queries.getAccountContactDetails.loading"
      @filter="handleFilter"
      :disable="!companyOptions.length"
    />
    <p v-if="getAccountContactDetails && getAccountContactDetails.rows.length < 1" style="color: red">
      There are no existing customers for your business.
    </p>
  </div>
</template>

<script>
import { QSelect } from 'eclipx.ui';
import QueryExistingCustomers from '@/graphql/queries/QueryExistingCustomer';
import { mapMutations } from 'vuex';

export default {
  name: 'ExistingCustomer',
  data() {
    return {
      companySelected: null,
      companyOptions: [],
      filterCompanyOptions: []
    };
  },
  apollo: {
    getAccountContactDetails: {
      query: QueryExistingCustomers,
      debounce: 300,
      fetchPolicy: 'network-only',
      variables() {
        return {
          params: {
            productType: 'Operating',
            role: 'Authorised Signatory (Operating)'
          }
        };
      },
      error: function ({ graphQLErrors }) {
        const errMessage = graphQLErrors.map((err) => {
          return err.message;
        });
        /*Extract error message */
        let message = errMessage.join().replace(/Error:|{}/gi, '');
        if (message && message.startsWith('{"extensions":') && message.includes('"message"') && message.endsWith('}}}}}')) {
          message = message
            .substring(message.indexOf('"message'), message.indexOf('"}}}}}'))
            .replace(/message|:|"/gi, '')
            .trim();
        }
        if (message && message.trim().endsWith('.')) {
          message = message.slice(0, -1);
        }
        if (/:|{|}|"|\?|%/.test(message)) {
          message = '';
        }

        this.$euiNotifications.emit({
          notification: GLOBAL_ERROR,
          action: () => {
            return this.$apollo.queries.getAccountContactDetails.refresh();
          },
          message
        });
      },
      result: function ({ data }) {
        const companyOptions = this.sortCompanyOptions(data.getAccountContactDetails.rows);

        this.companyOptions = companyOptions;
        this.filterCompanyOptions = companyOptions;
      }
    }
  },
  watch: {
    companySelected: function (company) {
      if (company) {
        const companyDetails = {
          companyName: company.name,
          companyAbn: company.abn,
          customerName: company.contactDetail?.name,
          customerContactNumber: company.contactDetail?.contactNumber,
          customerContactEmail: company.contactDetail?.email,
          deliveryAddressLine1: company.contactDetail?.street,
          deliverySuburb: company.contactDetail?.suburb,
          deliveryState: company.contactDetail?.state,
          deliveryPostcode: company.contactDetail?.postCode
        };

        this.saveDetails(companyDetails);
      }
    }
  },
  methods: {
    ...mapMutations({
      saveDetails: 'quote/saveCompanyDetails'
    }),
    getLabel(option) {
      return `${option.name} (ABN: ${option.abn})`;
    },
    sortCompanyOptions(options) {
      return options.sort((accountA, accountB) => {
        const nameA = accountA.name.toUpperCase();
        const nameB = accountB.name.toUpperCase();

        return nameA.localeCompare(nameB);
      });
    },
    handleFilter(value, update) {
      update(() => {
        const filterName = value.toUpperCase();

        const filterByNearestTerm = this.sortCompanyOptions(
          this.companyOptions.filter(({ name, abn }) => {
            const companyName = this.getLabel({ name, abn }).toUpperCase();

            return companyName.slice(0, filterName.length) === filterName;
          })
        );

        const filterBySimilarLetterPattern = this.sortCompanyOptions(
          this.companyOptions.filter(({ name, abn }) => {
            const companyName = this.getLabel({ name, abn }).toUpperCase();

            return companyName.indexOf(filterName) > -1;
          })
        );

        this.filterCompanyOptions = [...filterByNearestTerm, ...filterBySimilarLetterPattern].reduce((previousValue, current) => {
          const existingIndex = previousValue.findIndex((option) => option.abn === current.abn && option.name === current.name);

          if (existingIndex === -1) {
            previousValue.push(current);
          }

          return previousValue;
        }, []);
      });
    }
  },
  components: {
    QSelect
  }
};
</script>

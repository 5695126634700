<template>
  <QTabs
    align="justify"
    active-color="secondary"
    class="bg-white q-my-md rounded-borders text-grey-8 q-tabs--inset-indicator q-tabs--no-focus-helper"
  >
    <QRouteTab
      class="q-py-sm"
      :ripple="false"
      label="Vehicle"
      :disable="disabled"
      :to="{ name: 'quoteCar', params: { quoteId: $route.params.quoteId } }"
    />
    <QRouteTab
      class="q-py-sm"
      :ripple="false"
      label="Lease Details"
      :disable="!$store.getters['quote/quoteVariantValid'] || disabled"
      :to="{
        name: 'quoteLeaseDetails',
        params: { quoteId: $route.params.quoteId }
      }"
    />
    <QRouteTab
      class="q-py-sm"
      :ripple="false"
      label="Accessories & Options"
      :disable="!$store.getters['quote/quoteLeaseValid'] || disabled"
      :to="{
        name: 'quoteColourAndExtras',
        params: { quoteId: $route.params.quoteId }
      }"
    />
    <QRouteTab
      class="q-py-sm"
      :ripple="false"
      label="Customer Details"
      :disable="!$store.getters['quote/quoteColourAndExtrasValid'] || disabled || tabDisable"
      :to="{
        name: 'quoteCustomerDetails',
        params: { quoteId: $route.params.quoteId }
      }"
    />
    <QRouteTab
      class="q-py-sm"
      :ripple="false"
      label="Summary"
      :disable="!$store.getters['quote/quoteCustomerDetailsValid'] || disabled"
      :to="{ name: 'quoteReview', params: { quoteId: $route.params.quoteId } }"
    />
  </QTabs>
</template>

<script>
import { QTabs, QRouteTab } from 'eclipx.ui';
import { mapState } from 'vuex';

/**
 * This component renders the tabs of the Quote pages.
 * The active tab is determined by matching the `:to` to the current route path.
 */
export default {
  name: 'DriveQuoteTabs',
  props: {
    disabled: Boolean
  },
  computed: {
    ...mapState({
      quote: (state) => state.quote.quote,
      selected: (state) => state.org.selectedOrg,
      user: (state) => state.auth.user
    }),
    erpQuoteRef() {
      return this.quote.erpquote.erpQuoteRef;
    },
    tabDisable() {
      if (!this.quote?.erpquote?.exteriorColour?.id?.startsWith('E') && !this.quote.erpquote.price.monthlyRentalNet) {
        return true;
      } else {
        return false;
      }
    }
  },
  components: {
    QTabs,
    QRouteTab
  }
};
</script>

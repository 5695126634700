import gql from 'graphql-tag';
export default gql`
  mutation saveQuote($params: ErpQuoteInput) {
    saveQuote(params: $params) {
      code
      message
      response {
        price {
          onRoadCost
          monthlyRentalNet
          weeklyRentalNet
        }
        dbQuoteId
        dbStatus
        erpQuoteRef
        erpStatus
        salesforce {
          accountId
          opportunityId
          erpRef
          leadId
          creditTotalValue
          creditRemaining1
          introducer
        }
        tyres
        itemisedPricing
      }
    }
  }
`;

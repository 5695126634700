<template>
  <QInput class="EUIDateInput" v-bind="$attrs" :color="color" :value="value" @input="$emit('input', $event)" :mask="inputMask">
    <template slot="append">
      <QIcon :name="this.calendarIcon" class="cursor-pointer" size="20px">
        <QPopupProxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
          <QDate :color="color" :value="value" @input="changeDate" :options="options" :mask="dateMask" />
        </QPopupProxy>
      </QIcon>
    </template>
  </QInput>
</template>

<script>
import { QInput, QIcon, QPopupProxy, QDate } from 'quasar';
import { evaCalendar } from '@quasar/extras/eva-icons';

/**
 * This component combines a Quasar input with a Quasar date.
 * The date popup can be opened by clicking the date icon on the right of the input.
 *
 * Both updating the input value and changing the date through the popup will update the model bound to this component.
 */
export default {
  name: 'EuiDateInput',
  props: {
    value: {
      required: true
    },
    color: String,
    dateMask: {
      type: String,
      default: 'DD/MM/YYYY'
    },
    options: undefined
  },
  computed: {
    inputMask() {
      // Replace all alpha numeric characters with a hash symbol
      return this.dateMask.replace(/[a-z0-9]/gi, '#');
    }
  },
  methods: {
    changeDate(value) {
      this.$emit('input', value);
      this.$refs.qDateProxy.hide();
    }
  },
  created() {
    this.calendarIcon = evaCalendar;
  },
  components: {
    QInput,
    QIcon,
    QPopupProxy,
    QDate
  }
};
</script>

<style lang="sass">
.EUIDateInput.q-input .q-field__append
  display: flex
</style>

import store from '../store';

/**
 * Prevent the user from accessing certain user pages if they do not have required permissions.

 * If a user can not create a user and are on a new user route they will be moved to the users list
 * @param to The route going to
 * @param from The route leaving
 * @param next The callback for vue router to progress
 */
export default function (to, from, next) {
  const homeRoute = {
    name: 'users',
    params: { userId: to.params.userId }
  };

  if (!store.getters['auth/canCreate']) {
    // Kick the user back to the users list of they are creating a new user and do not have permission
    if (to.name === 'NewUser') {
      return next(homeRoute);
    }
  }

  next();
}

import gql from 'graphql-tag';
export default gql`
  mutation getFeatureAllocations {
    getFeatureAllocations {
      feature {
        name
        code
        description
      }
    }
  }
`;

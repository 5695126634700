import gql from 'graphql-tag';
export default gql`
  query dragonBallOrg($abn: String!) {
    dragonBallOrg(abn: $abn) {
      id
      name
      abn
    }
  }
`;

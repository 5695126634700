<template>
  <div>
    <p><b>Ann error has occurred.</b></p>
    <p>If this continues, please contact your relationship manager or us on 1300 666 001.</p>
  </div>
</template>
<script>
export default {
  name: 'Error'
};
</script>

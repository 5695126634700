import gql from 'graphql-tag';
export default gql`
  query getAccountContactDetails($params: AccountContact) {
    getAccountContactDetails(params: $params) {
      rows {
        abn
        name
        contactDetail {
          name
          email
          contactNumber
          street
          suburb
          state
          postCode
        }
      }
      status
    }
  }
`;

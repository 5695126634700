<template>
  <QForm @submit="addAccessory">
    <div class="row q-col-gutter-md">
      <div class="col-6">
        <p class="text-body3 text-grey-7 q-mb-xs text-weight-medium">Accessory Name</p>
      </div>
    </div>
    <div class="row q-col-gutter-md q-pt-sm" v-for="(accessory, index) in value" :key="index">
      <div class="col-6">
        <QInput color="secondary" filled readonly dense hide-bottom-space disable :value="accessory.name" />
      </div>
      <div class="row col-shrink items-center">
        <RemoveIcon @input="() => removeAccessory(index)" :disabled="disabled" />
      </div>
    </div>
    <div class="row q-col-gutter-md q-pt-sm" v-if="value.length < limit">
      <div class="col-6">
        <QInput color="secondary" filled dense :disable="disabled" hide-bottom-space v-model="$v.name.$model" :error="$v.name.$error" />
        <div v-if="!$v.name.maxLength" class="text-body3 validation-error" style="color: red">
          Accessory Name has a max length of {{ $v.name.$params.maxLength.max }} characters!
        </div>
      </div>
    </div>
    <AddButton :suffix="value.length > 0 ? 'ANOTHER' : ''" :disabled="disabled" />
  </QForm>
</template>

<script>
import { QInput, QForm } from 'eclipx.ui';
import AddButton from '@/components/AddButton/AddButton';
import RemoveIcon from '@/components/RemoveIcon/RemoveIcon';
import { required, maxLength } from 'vuelidate/lib/validators';

export default {
  name: 'ColourAndExtrasAdditionalAccessories',
  props: {
    value: {
      type: Array,
      required: true
    },
    limit: {
      type: Number,
      default: 20
    },
    disabled: Boolean
  },
  data() {
    return {
      name: null,
      price: null
    };
  },
  validations: {
    name: { required, maxLength: maxLength(50) }
  },
  methods: {
    addAccessory: function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      let addition = {
        name: this.$v.name.$model,
        price: 0.0,
        amountNet: 0.0
      };
      this.$emit('input', [...this.value, addition]);

      this.name = null;
      this.price = null;
      this.$v.$reset();
    },
    removeAccessory: function (index) {
      let updatedAccessories = [...this.value];
      updatedAccessories.splice(index, 1);
      this.$emit('input', updatedAccessories);
    }
  },
  components: { QInput, QForm, AddButton, RemoveIcon }
};
</script>

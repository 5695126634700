import QueryBusinessLookup from '../graphql/queries/QueryBusinessLookup';
import { GLOBAL_ERROR } from '../components/GlobalError/GlobalError';

export default {
  methods: {
    searchCompanies: async function (params) {
      const { data: getBusinessInfo } = await this.$apollo
        .query({
          query: QueryBusinessLookup,
          debounce: 300,
          fetchPolicy: 'network-only',
          variables: { param: params }
        })
        .catch(() => {
          this.$euiNotifications.emit({
            notification: GLOBAL_ERROR,
            action: () => {
              return this.searchCompanies();
            }
          });
        });
      return getBusinessInfo.getBusinessInfo;
    }
  }
};

import gql from 'graphql-tag';
export default gql`
  mutation createUser($userInfo: UserCreate!) {
    createUser(userInfo: $userInfo) {
      id
      firstName
      lastName
      email
    }
  }
`;

<template>
  <div>
    <QField
      v-if="requiresQuasarField"
      dense
      :style="attributes.title ? 'margin-top: 22px' : null"
      color="dark"
      class="self-start"
      hide-bottom-space
      borderless
      :error="error"
    >
      <component
        :is="mappedComponent"
        v-bind="mappedAttributes"
        :value="value"
        :options="attributes.options"
        :error="error"
        :type="type"
        @input="$emit('input', $event)"
      />
    </QField>
    <div v-else class="column">
      <span v-if="attributes.title" class="text-body3 text-grey-7 q-pb-xs text-weight-medium">
        {{ attributes.title }}{{ required ? '*' : '' }}
      </span>
      <component
        :is="mappedComponent"
        v-bind="mappedAttributes"
        :value="value"
        :options="attributes.options"
        :error="error"
        :type="type"
        @input="$emit('input', $event)"
      />
    </div>
  </div>
</template>

<script>
import { QSelect, QInput, QRadio, QCheckbox, QField } from 'quasar';
import defaultFieldAttributes from './default-field-attributes';

const inputsRequiringField = ['radio', 'checkbox'];

/**
 * This component will render a common form component dynamically based on given props.
 * It will convert from standard html form elements like 'select' and 'checkbox' into appropriate Eclipx.UI or Quasar
 * components like QSelect and QCheckbox.
 */
export default {
  name: 'EUIFormField',
  props: {
    value: {
      required: true
    },
    type: {
      type: String,
      required: true
    },
    required: Boolean,
    error: Boolean,
    attributes: {
      type: undefined,
      default: { options: [] }
    }
  },
  computed: {
    requiresQuasarField: function () {
      return inputsRequiringField.find((inputType) => inputType === this.type);
    },
    mappedComponent: function () {
      const componentMap = {
        select: QSelect,
        checkbox: QCheckbox,
        radio: QRadio,
        input: QInput,
        textarea: QInput
      };
      let component = componentMap[this.type];
      if (!component) {
        throw new Error(`EUIFormField requires a type of ${Object.keys(componentMap).toString()}`);
      }
      return component;
    },
    mappedAttributes: function () {
      let attributes = {
        ...defaultFieldAttributes[this.type],
        ...this.attributes
      };

      // Add a placeholder for select
      if (this.type === 'select' && !this.attributes['display-value']) {
        let placeholder = this.attributes.placeholder || 'Select';
        attributes['display-value'] = this.value ? this.value : placeholder;
      }

      // Ensure labels are not used except for radio and checkbox
      if (!this.requiresQuasarField) {
        delete attributes.label;
      }

      return attributes;
    }
  },
  components: {
    QField
  }
};
</script>

import gql from 'graphql-tag';
export default gql`
  query getUserByEmail($email: String!) {
    getUserByEmail(email: $email) {
      firstName
      lastName
      email
      isActive
      roles {
        name
      }
    }
  }
`;

import gql from 'graphql-tag';
export default gql`
  {
    dbQuoteStatuses {
      id
      name
      description
      code
    }
  }
`;

<template>
  <QToolbar>
    <QToolbarTitle class="flex">
      <router-link to="/" class="text-none text-grey-8 text-weight-bold flex">
        <img class="brand-logo" :src="organisationImage" />
      </router-link>
      <div class="nav row">
        <QBtn
          :flat="routeName.toLowerCase().indexOf('quote') == -1"
          class="q-mr-sm"
          :to="{ name: 'quotes' }"
          color="primary"
          label="Quotes"
          v-if="dbViewQuotes"
        />
        <QBtn
          :flat="!['users', 'dealers'].includes(routeName.toLowerCase())"
          class="q-mr-sm"
          :to="{ name: dbManageUsers ? 'users' : 'dealers' }"
          color="primary"
          label="Administration"
          v-if="dbManageUsers || dbManageDealers"
        />
        <QBtn
          :flat="!['admins', 'organisations', 'signaturetype'].includes(routeName.toLowerCase())"
          class="q-mr-sm"
          :to="{ name: dbManageAdmins ? 'admins' : 'organisations' }"
          color="primary"
          label="Site Config"
          v-if="dbManageAdmins || dbManageOrganisations"
        />
        <QBtn
          :flat="
            ![
              'white-label-auth0',
              'white-label-dragonball-content',
              'white-label-quote-content',
              'white-label-scoreboard-content',
              'white-label-email-templates'
            ].includes(routeName.toLowerCase())
          "
          class="q-mr-sm"
          :to="{ name: 'white-label-auth0' }"
          color="primary"
          label="Brand Config"
          v-if="$store.getters['auth/whiteLabelEnabled'] && (hasRole('DB Eclipx Admin') || hasRole('DB Broker Admin'))"
        />
        <QSelect
          class="dropdown"
          v-model="selected"
          :options="organisations"
          option-value="id"
          option-label="name"
          map-options
          color="primary"
          label="Act as Organisation"
          @input="setOrg($event)"
          v-if="dbManageOrganisations"
          style="max-width: 100%; overflow: hidden; text-overflow: ellipsis"
        />
      </div>
    </QToolbarTitle>
    <div class="row items-center">
      <QBtn
        v-if="dbCreateQuotes && this.$router.currentRoute.fullPath === '/quotes'"
        class="q-mr-sm"
        @click="createNewQuote"
        :to="{ name: 'quote', params: { quoteId: this.NewQuoteRouteParam } }"
        color="secondary"
        label="New Quote"
      />
      <EUIProfileMenu :title="user ? user.firstName + ' ' + user.lastName : this.$auth.user.name">
        <template slot="menu">
          <QList>
            <QItem v-close-popup clickable>
              <QItemSection @click="this.logout">Logout</QItemSection>
            </QItem>
          </QList>
        </template>
      </EUIProfileMenu>
    </div>
  </QToolbar>
</template>

<script>
import { QToolbar, QToolbarTitle, QSelect, QBtn, QList, QItem, QItemSection, ClosePopup, EUIProfileMenu } from 'eclipx.ui';
import { NewQuoteRouteParam } from '../../router/Routes';
import { NewUserRouteParam } from '../../router/Routes';
import { NewDealerRouteParam } from '../../router/Routes';
import { NewAdminRouteParam } from '../../router/Routes';
import QuerygetOrgByAppCode from '../../graphql/queries/QueryOrganisations';
import QueryImageByUUID from '../../graphql/queries/QueryImageByUUID';
import QueryWhiteLabelByUser from '../../graphql/queries/QueryWhiteLabelByUser';
import { mapState, mapMutations, mapGetters } from 'vuex';
import { GLOBAL_ERROR } from '../../components/GlobalError/GlobalError';
import getFeatureAllocations from '@/graphql/mutations/FeatureAllocations';

/**
 * This component represents the header which is to be displayed as part of all page layouts.
 */
export default {
  name: 'Header',
  props: {
    title: String
  },
  data() {
    return {
      NewQuoteRouteParam: NewQuoteRouteParam,
      NewUserRouteParam: NewUserRouteParam,
      NewDealerRouteParam: NewDealerRouteParam,
      NewAdminRouteParam: NewAdminRouteParam,
      organisationLogoPlaceholder: `${process.env.BASE_URL}assets/logos/EclipxLogo-small.svg`,
      selected: '',
      getFileByUUID: '',
      email: '',
      error: null
    };
  },
  computed: {
    ...mapState({
      user: (state) => {
        return state.auth.user;
      }
    }),
    ...mapState({
      roles: (state) => {
        var roles = [];
        if (state.auth.user) {
          for (var i = 0; i < state.auth.user.roles.length; i++) {
            roles.push(state.auth.user.roles[i].name);
          }
        }
        return roles;
      }
    }),
    ...mapGetters({
      orgProfile: 'orgProfile/dragonball'
    }),
    organisationImage() {
      if (this.orgProfile?.primaryLogo) {
        return this.orgProfile.primaryLogo;
      } else if (!this.dbManageAdmins && this.$store.getters['auth/user']) {
        return this.getFileByUUID?.signedUrl
          ? this.getFileByUUID?.signedUrl
          : `${process.env.BASE_URL}assets/logos/FleetPartners-small.svg`;
      } else {
        return `${process.env.BASE_URL}assets/logos/FleetPartners-small.svg`;
      }
    },
    routeName() {
      return this.$route.name;
    },
    features() {
      return this.$store.getters['auth/feature'];
    },
    dbManageUsers() {
      return this.features && this.features.indexOf('DB::ManageUsers') > -1;
    },
    dbManageDealers() {
      return this.features && this.features.indexOf('DB::ManageDealership') > -1 && this.features.indexOf('DB::OwnDealer') == -1;
    },
    dbManageOrganisations() {
      return this.features && this.features.indexOf('DB::ManageOrganisations') > -1;
    },
    dbManageAdmins() {
      return this.features && this.features.indexOf('DB::ManageAdmins') > -1;
    },
    dbViewQuotes() {
      return this.features && this.features.indexOf('DB::ReadQuote') > -1;
    },
    dbCreateQuotes() {
      return this.features && this.features.indexOf('DB::CreateQuote') > -1;
    },
    organisations() {
      return this.$store.getters['org/org'];
    },
    hasRole() {
      return this.$store.getters['auth/hasRole'];
    }
  },
  apollo: {
    getOrgByAppCode: {
      query: QuerygetOrgByAppCode,
      fetchPolicy: 'network-only',
      variables() {
        return {
          appCode: 'DB'
        };
      },
      error: function ({ graphQLErrors }) {
        const errMessage = graphQLErrors.map((err) => {
          return err.message;
        });
        /*Extract error message */
        let message = errMessage.join().replace(/Error:|{}/gi, '');
        if (message && message.startsWith('{"extensions":') && message.includes('"message"') && message.endsWith('}}}}}')) {
          message = message
            .substring(message.indexOf('"message'), message.indexOf('"}}}}}'))
            .replace(/message|:|"/gi, '')
            .trim();
        }
        if (message && message.trim().endsWith('.')) {
          message = message.slice(0, -1);
        }
        if (/:|{|}|"|\?|%/.test(message)) {
          message = '';
        }
        this.$euiNotifications.emit({
          notification: GLOBAL_ERROR,
          action: () => {
            return this.$apollo.queries.getOrgByAppCode.refresh();
          },
          message
        });
      },
      result: function (res) {
        this.selected = this.$store.getters['org/selectedOrg'];
        this.$store.commit('org/setOrg', res.data.getOrgByAppCode.rows);

        this.$apollo.mutate({
          mutation: getFeatureAllocations,
          update: (store, response) => {
            if (response.data.getFeatureAllocations && response.data.getFeatureAllocations.length <= 0) return;

            const orgFeats = response.data.getFeatureAllocations.map((feat) => feat.feature.code);
            this.$store.commit('auth/setOrgFeatures', orgFeats);
          }
        });
      }
    },
    getFileByUUID: {
      query: QueryImageByUUID,
      fetchPolicy: 'network-only',
      variables() {
        return {
          id: this.$store.getters['auth/user'] ? this.$store.getters['auth/user'].organisation.id : null
        };
      }
    }
  },
  methods: {
    ...mapMutations({ createNewQuote: 'quote/createNewQuote' }),
    logout() {
      this.$router.push({ name: 'logout' });
    },
    setOrg: function (event) {
      var selected = this.$store.getters['org/selectedOrg'];

      if (selected != event) {
        this.$store.commit('org/setSelectedOrg', event);
        this.$store.commit('auth/clearOrgFeatures');
        this.$router.go();
      }
    }
  },

  directives: {
    ClosePopup
  },
  components: {
    QBtn,
    QToolbar,
    QToolbarTitle,
    QList,
    QSelect,
    QItem,
    QItemSection,
    EUIProfileMenu
  }
};
</script>

<style lang="sass" scoped>
.brand-logo
    height: 50px
    padding-right: 50px
    color: green
.nav
  padding-top: 7px
.dropdown
    width: 200px
    padding-left: 50px

.frameContainer
  border: solid
  border-color: black
  position: absolute
  width: 700px
  right: 0px
  top: 70px
  height: 800px
</style>

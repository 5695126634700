const OPTIONS_KEYS = ['key', 'language', 'region'];
const url = 'https://maps.googleapis.com/maps/api/js?';

/**
 * @description
 * Function to load the google maps api
 * https://developers.google.com/maps/documentation/javascript/overview#Loading_the_Maps_API
 *
 * @param options
 * @param options.key       Authentication key for access to google maps
 * @param options.language  Specify language (https://developers.google.com/maps/documentation/javascript/localization)
 * @param options.region    Specify a region code, which alters the map's behavior based on a given country or territory
 * @param options.libraries Array of specific libraries to load along with the base google maps code (https://developers.google.com/maps/documentation/javascript/libraries)
 *
 * @returns {Promise<>}
 */
export default async function loadGoogleMaps(options = {}) {
  return new Promise(function (resolve, reject) {
    // Check if the Google Maps API has already been loaded
    if (window.google && window.google.maps) {
      return resolve(window.google.maps);
    }

    window.resolveGoogleMapsInit = function () {
      return resolve(window.google.maps);
    };

    // Prepare the `script` tag to be inserted into the page
    let scriptElement = document.createElement('script');

    let params = ['callback=resolveGoogleMapsInit'];
    OPTIONS_KEYS.forEach((key) => {
      if (options[key]) {
        params.push(key + '=' + options[key]);
      }
    });
    if (options.libraries && options.libraries.length) {
      params.push('libraries=' + options.libraries.join(','));
    }

    scriptElement.src = url + params.join('&');

    document.body.appendChild(scriptElement);
  });
}

<template>
  <QExpansionItem
    :expand-icon="chevronDown"
    :expandedIcon="chevronUp"
    header-class="text-body3 text-weight-bold text-grey-9"
    label="Included Extras"
    dense
  >
    <div class="q-ml-lg q-my-sm">
      <p v-for="extra in erpquote.includedExtras" :key="extra" class="q-mb-xs text-grey-8 text-body3">
        {{ extra }}
      </p>
    </div>
  </QExpansionItem>
</template>

<script>
import { QExpansionItem, Icons } from 'eclipx.ui';
import { mapState } from 'vuex';

export default {
  name: 'SummaryCardIncludedExtras',
  computed: mapState({ erpquote: (state) => state.quote.quote.erpquote }),
  created() {
    this.chevronDown = Icons.evaChevronDown;
    this.chevronUp = Icons.evaChevronUp;
  },
  components: { QExpansionItem }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped></style>

import gql from 'graphql-tag';
export default gql`
  query dealerById($id: String!) {
    dealerById(id: $id) {
      id
      name
      isActive
    }
  }
`;

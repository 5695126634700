import gql from 'graphql-tag';
export default gql`
  mutation updateDealerById($id: String!, $updateInfo: UpdateDealer) {
    updateDealerById(id: $id, updateInfo: $updateInfo) {
      id
      name
      isActive
    }
  }
`;

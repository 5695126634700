import gql from 'graphql-tag';
export default gql`
  query userById($id: String!) {
    userById(id: $id) {
      id
      firstName
      lastName
      email
      phone
      isActive
      updatedAt
      roles {
        id
        name
      }
      orgProfile
      pricingConfiguration {
        isItemisedPricingEnabled
        commissions {
          isCommissionsEnabled
          status
        }
      }
    }
  }
`;

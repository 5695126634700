<template>
  <div class="bg-white q-pa-lg" v-if="$store.getters['auth/whiteLabelEnabled']">
    <div class="bg-white q-pa-lg">
      <div class="row">
        <div class="col-xs-12 col-sm-3 col-md-3" style="padding: 25px">
          <h5>Global Styles</h5>
          <br />
          <p>Select your company's colours and logos to reflect your brand's online experience.</p>
          <img src="/assets/images/whitelabel/quote1.png" width="100%" />
        </div>
        <div class="col-xs-12 col-sm-9 col-md-9" style="padding: 25px">
          <strong>Global Colours</strong>
          <p>Select your company's colours or enter the HEX/RGB codes. This will change the colours on the quote estimate.</p>
          <br />
          <div class="row" style="width: 100%">
            <ColourPicker
              caption="Primary (Dark)"
              field="primary"
              info="This colour is used for headings and important information."
              :colourProp="form.primary"
              @onColourChangeHandler="onColourChangeHandler"
            />

            <ColourPicker
              caption="Secondary"
              field="secondary"
              info="This will display as the tick background colour on the lease features."
              :colourProp="form.secondary"
              @onColourChangeHandler="onColourChangeHandler"
            />

            <ColourPicker
              caption="Footer (Dark)"
              field="footer"
              info="This colour is the background colour on the footer."
              :colourProp="form.footer"
              @onColourChangeHandler="onColourChangeHandler"
            />
          </div>

          <hr style="border-top: 0.5px solid lightgray; margin: 30px 0px" />

          <strong>Image Library</strong>
          <p>Upload your company's logos, they will be positioned adjacent to each other in the top right corner of the quote.</p>
          <p>
            For a singular logo, please upload it in the right logo position. Alternatively the FleetPartners logo may be uploaded here for
            co-branding.
          </p>

          <div class="row" style="width: 100%">
            <ImagePicker
              caption="Left Logo"
              field="logo1"
              info="Recommended aspect ratio: 3:1<br/>Recommended size 120px (w) x 54px (h)<br/>File format: PNG, JPG,GIF<br/>Max file size: 160KB"
              :imageProp="form.logo1"
              @onImageChangeHandler="onImageChangeHandler"
            />
            <ImagePicker
              caption="Right Logo"
              field="logo2"
              info="Recommended aspect ratio: 3:1<br/>Recommended size 120px (w) x 54px (h)<br/>File format: PNG, JPG,GIF<br/>Max file size: 160KB"
              :imageProp="form.logo2"
              @onImageChangeHandler="onImageChangeHandler"
            />
          </div>
          <hr style="border-top: 0.5px solid lightgray; margin: 30px 0px" />
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-3 col-md-3" style="padding: 25px">
          <h5>Footer Information</h5>
          <br />
          <p>Customise your footer with your company's information and logos.</p>
          <img src="/assets/images/whitelabel/quote2.png" width="100%" />
        </div>
        <div class="col-xs-12 col-sm-9 col-md-9" style="padding: 25px">
          <strong>Footer Content</strong>
          <p>You can edit the text within the footer. Only plain text is permitted.</p>
          <br />
          <div class="row" style="width: 100%">
            <div class="q-pa-xs col-12 col-sm-12">
              <QInput
                v-model.lazy="form.footerText"
                outlined
                input-style="min-height: 350px"
                type="textarea"
                placeholder="Footer text"
                @input="onInputChangeHandler('footerText')"
                :disable="!hasFeature('DB::ManageAdmins')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QInput } from 'eclipx.ui';
import { mapGetters, mapMutations } from 'vuex';
import ColourPicker from './Modules/ColourPicker';
import ImagePicker from './Modules/ImagePicker';

export default {
  name: 'QuoteContent',
  data() {
    return {
      form: {
        primary: '',
        secondary: '',
        footer: '',
        footerText: '',
        logo1: '',
        logo2: ''
      }
    };
  },
  computed: {
    ...mapGetters({
      quote: 'whiteLabel/quote',
      hasFeature: 'auth/hasFeature'
    })
  },
  created() {
    const defaultFooter =
      'Important Information: This Quote Estimate is an indicative quote only. It does not constitute an offer to enter into, or an agreement to provide, an operating lease. Entry into an operating lease is subject to FleetPartners’ credit approval criteria and signing a formal Quote/Requisition and lease agreement. This Quote Estimate is based on information current and available as at the Date of Quote, and is subject to change at the time of preparation of the formal Quote/Requisition and/or delivery of the vehicle (for example, the monthly rental amount may change if there is a change in the cost of the vehicle or to FleetPartners’ cost of funding the operating lease). FleetPartners Master Lease Agreement terms and conditions, fees and charges apply. The vehicle must remain comprehensively insured during the lease term and the costs of comprehensive insurance are not included in this Quote Estimate. The vehicle must be returned to FleetPartners at the end of the lease term, and excess usage and unfair wear and tear costs may apply at lease end. You agree that the Introducer may receive a commission from FleetPartners if you enter into an operating lease and acknowledge that the cost of such commission has been taken into account in determining the rent payable under the operating lease. You should obtain independent financial and tax advice. Privacy Statement: Your details have been provided to FleetPartners for the purpose of generating this Quote Estimate. If you progress with your lease application, you agree that FleetPartners may contact you for that purpose. Fleet Partners` Privacy Policy, contains information about how you can access and correct your personal information, how to make a privacy related complaint, and how we deal with those complaints. https://eclipxgroup.com/privacy-policy/ Operating leases are provided by Fleet Partners Pty Ltd ABN 63 006 706 832.';
    this.form.primary = this.quote.primary;
    this.form.secondary = this.quote.secondary;
    this.form.footer = this.quote.footer;
    this.form.footerText = this.quote && this.quote.footerText == null ? defaultFooter : this.quote.footerText;
    this.form.logo1 = this.quote.logo1;
    this.form.logo2 = this.quote.logo2;

    if (this.form.footerText) this.onInputChangeHandler('footerText');
  },
  mounted() {},
  methods: {
    ...mapMutations({ updateValueStatus: 'whiteLabel/updateValueStatus' }),
    onColourChangeHandler: function (colour, field) {
      this.form[field] = colour;
      this.updateValueStatus({
        object: 'quote',
        variable: field,
        value: colour
      });
    },
    onImageChangeHandler: function (image, field) {
      this.form[field] = image;
      this.updateValueStatus({
        object: 'quote',
        variable: field,
        value: image
      });
    },
    onInputChangeHandler: function (field) {
      this.updateValueStatus({
        object: 'quote',
        variable: field,
        value: this.form[field]
      });
    }
  },
  components: {
    QInput,
    ColourPicker,
    ImagePicker
  }
};
</script>

<template>
  <main class="q-page column q-pa-lg">
    <h6 class="q-mt-none q-mb-md text-weight-bold text-grey-9">New Administrator</h6>
    <div v-if="loading" class="flex justify-center q-pa-md">
      <QSpinner size="md" color="grey-7" />
    </div>
    <div class="row q-gutter-md" v-else>
      <div class="col q-pa-lg self-start q-card q-card--bordered">
        <div style="max-width: 400px">
          <QForm @submit="saveUserDetails">
            <h6 class="q-mt-none q-mb-none text-grey-8">User Details</h6>
            <FormError :show="$v.$invalid && $v.$dirty" class="q-mt-md" />
            <div class="q-mt-md">
              <label class="text-body3" for="fname">First name:</label><br />
              <QInput
                v-model="fname"
                :error="$v.fname.$error"
                filled
                maxlength="50"
                color="secondary"
                dense
                hide-bottom-space
                clearable
                required
              />
              <div v-if="$v.fname.$dirty">
                <div v-if="!$v.fname.minLength" class="text-body3 validation-error">
                  First name must have at least {{ $v.fname.$params.minLength.min }} letters.
                </div>
                <div v-if="!$v.fname.maxLength" class="text-body3 validation-error">
                  First name must have at most {{ $v.fname.$params.maxLength.max }} letters.
                </div>
              </div>
            </div>
            <div class="q-mt-md">
              <label class="text-body3" for="lname">Last name:</label><br />
              <QInput
                v-model="lname"
                :error="$v.lname.$error"
                filled
                maxlength="50"
                color="secondary"
                dense
                hide-bottom-space
                clearable
                required
              />
              <div v-if="$v.lname.$dirty">
                <div v-if="!$v.lname.minLength" class="text-body3 validation-error">
                  Last name must have at least {{ $v.lname.$params.minLength.min }} letters.
                </div>
                <div v-if="!$v.lname.maxLength" class="text-body3 validation-error">
                  Last name must have at most {{ $v.lname.$params.maxLength.max }} letters.
                </div>
              </div>
            </div>
            <div class="q-mt-md">
              <label class="text-body3" for="lname">Email:</label><br />
              <QInput
                v-model="email"
                :error="$v.email.$error"
                filled
                maxlength="50"
                color="secondary"
                dense
                hide-bottom-space
                clearable
                type="email"
                required
                @keyup="email = email.toLowerCase()"
              />
              <div v-if="$v.email.$dirty">
                <div v-if="!$v.email.email" class="text-body3 validation-error">Input should be email address.</div>
                <div v-if="!$v.email.maxLength" class="text-body3 validation-error">
                  Email must have at most {{ $v.email.$params.maxLength.max }} letters.
                </div>
              </div>
            </div>
            <div class="q-mt-md">
              <p class="text-body3 q-mb-xs">User Role</p>
              <QSelect
                dense
                hide-bottom-space
                filled
                :clearable="true"
                v-model="selected"
                :options="visibility"
                option-value="role"
                option-label="name"
                map-options
                color="primary"
              />
            </div>
            <div v-if="error" class="text-body3 validation-error">{{ error }}</div>
            <div class="q-mt-lg">
              <QBtn class="q-mr-sm" type="submit" :label="'Save'" color="secondary" :loading="saving" :disable="saving" :ripple="false" />
              <QBtn class="q-mr-sm" :label="'Cancel'" color="secondary" :ripple="false" @click="cancel" />
            </div>
          </QForm>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { QBtn, QForm, QInput, QSpinner, QSelect } from 'eclipx.ui';
import MutateUser from '../../graphql/mutations/MutateUser';
import FormError from '../../components/FormError/FormError';
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators';

export default {
  name: 'User',
  data() {
    return {
      saving: false,
      fname: '',
      lname: '',
      email: '',
      error: null,
      loading: false,
      selected: '',
      visibility: [
        { role: 'DB Eclipx Admin', name: 'DB Eclipx Admin' },
        { role: 'DB Eclipx User', name: 'DB Eclipx User' }
      ]
    };
  },
  validations() {
    return {
      fname: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(40)
      },
      lname: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(40)
      },
      email: {
        required,
        email,
        maxLength: maxLength(80)
      }
    };
  },
  mounted() {
    this.id = this._uid;
  },
  methods: {
    saveUserDetails: async function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      // eslint-disable-next-line
      this.error = null;
      this.loading = true;
      const createUser = await this.$apollo
        .mutate({
          mutation: MutateUser,
          variables: {
            userInfo: {
              firstName: this.fname,
              lastName: this.lname,
              email: this.email,
              role: this.selected.role
            }
          }
        })
        .then(() => {
          // Navigate to the new admin
          this.loading = false;
          if (!this.error) {
            this.$router.push({
              name: 'admins'
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          let gqlError = error.graphQLErrors[0];
          this.error = gqlError ? gqlError.message : 'Error';
        });
      return createUser;
    },
    cancel: function () {
      this.$router.push({ name: 'admins' });
    }
  },
  components: {
    FormError,
    QBtn,
    QForm,
    QInput,
    QSpinner,
    QSelect
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
.validation-error
    color: tomato
</style>

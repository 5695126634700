<template>
  <main class="q-page column q-pa-lg">
    <h6 class="q-mt-none q-mb-md text-weight-bold text-grey-9">Dealer</h6>
    <div v-if="loading" class="flex justify-center q-pa-md">
      <QSpinner size="md" color="grey-7" />
    </div>
    <div class="row q-gutter-md" v-else>
      <div class="col q-pa-lg self-start q-card q-card--bordered">
        <div style="max-width: 400px">
          <QForm @submit="saveDealerDetails">
            <h6 class="q-mt-none q-mb-none text-grey-8">Dealer Details</h6>
            <div class="q-mt-md">
              <label class="text-body3" for="fname">Name:</label><br />
              <QInput v-model="dealerById.name" filled maxlength="50" color="secondary" dense hide-bottom-space clearable required />
            </div>
            <div class="q-mt-md">
              <label class="text-body3" for="lname">Active:</label><br />
              <QCheckbox v-model="dealerById.isActive" />
            </div>
            <div class="q-mt-lg">
              <QBtn class="q-mr-sm" type="submit" :label="'Save'" color="secondary" :loading="saving" :disable="saving" :ripple="false" />
              <QBtn class="q-mr-sm" :label="'Cancel'" color="secondary" :ripple="false" @click="cancel" />
            </div>
          </QForm>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { QBtn, QForm, QInput, QCheckbox, ClosePopup, QSpinner } from 'eclipx.ui';
import { GLOBAL_ERROR } from '../../components/GlobalError/GlobalError';
import QueryDealerById from '../../graphql/queries/QueryDealerById';
import MutateDealerById from '../../graphql/mutations/MutateDealerById';

export default {
  name: 'Dealer',
  data() {
    return {
      saving: false,
      dealerById: {},
      loading: false
    };
  },
  computed: {
    routeId() {
      return this.$route.params.dealerId;
    }
  },
  apollo: {
    dealerById: {
      query: QueryDealerById,
      fetchPolicy: 'network-only',
      variables() {
        return {
          id: this.routeId
        };
      },
      error: function (error) {
        if (error) {
          this.$euiNotifications.emit({
            notification: GLOBAL_ERROR,
            action: () => {
              return this.$apollo.queries.QueryDealerById.refresh();
            }
          });
        }
      }
    }
  },
  methods: {
    saveDealerDetails: async function () {
      this.loading = true;
      const createUser = this.$apollo
        .mutate({
          mutation: MutateDealerById,
          variables: {
            id: this.routeId,
            updateInfo: {
              name: this.dealerById.name,
              isActive: this.dealerById.isActive
            }
          }
        })
        .then((data) => {
          this.loading = false;
          this.$router.push({
            name: 'dealers'
          });
        })
        .catch((error) => {
          let gqlError = error.graphQLErrors[0];
          this.error = gqlError ? gqlError.message : 'Error';
        });
      return createUser;
    },
    cancel: function () {
      this.$router.push({ name: 'dealers' });
    }
  },
  directives: {
    ClosePopup
  },
  components: {
    QBtn,
    QForm,
    QInput,
    QCheckbox,
    QSpinner
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped></style>

import gql from 'graphql-tag';
export default gql`
  query makes($params: VehicleQuery) {
    makes(params: $params) {
      id
      name
      description
      popular
      bodyTypes {
        id
        name
      }
    }
  }
`;

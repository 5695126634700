import { render, staticRenderFns } from "./MilesCustomerDetails.vue?vue&type=template&id=bcca5764&scoped=true&"
import script from "./MilesCustomerDetails.vue?vue&type=script&lang=js&"
export * from "./MilesCustomerDetails.vue?vue&type=script&lang=js&"
import style0 from "./MilesCustomerDetails.vue?vue&type=style&index=0&id=bcca5764&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcca5764",
  null
  
)

export default component.exports
import gql from 'graphql-tag';
export default gql`
  query searchQuote($params: DBQuoteQuery, $userId: String) {
    searchQuote(params: $params, userId: $userId) {
      count
      rows {
        id
        org_id
        erp_quote_ref
        vehicle_name
        customer_full_name
        customer_company_name
        dealer_name
        quote_status_code
        created_at
        updated_at
        created_by
      }
    }
  }
`;

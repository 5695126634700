import gql from 'graphql-tag';
export default gql`
  mutation requestSignature($params: RequestSignatureInput) {
    requestSignature(params: $params) {
      code
      message
      quote {
        docusign_ref
        quote_status_code
      }
    }
  }
`;

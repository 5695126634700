<template>
  <div>
    <QSelect
      v-model="selectedOption"
      filled
      color="secondary"
      dense
      use-input
      fill-input
      clearable
      hide-selected
      popup-content-class="limit-q-menu-height predictive-search-width"
      :options="filterOptions"
      :option-label="getLabel"
      :loading="isLoading"
      @filter="handleFilter"
      :disable="isLoading || isDisabled"
      :error="hasError"
      @input="emitSelection"
      @input-value="inputedOption"
      :label="!selectedOption ? label : void 0"
      ref="select"
      emit-value
      new-value-mode="add-unique"
      @new-value="addNewOption"
    >
      <!--TODO: Make template to be reusable-->
      <template #before-options>
        <QItem dense class="row q-my-sm" style="align-items: baseline !important">
          <QItemLabel class="col-6 q-mr-xl text-body2">Description</QItemLabel>
          <QItemLabel class="col text-body2">Dealer Price</QItemLabel>
        </QItem>
        <QSeparator class="col-12" />
      </template>
      <template #after-options>
        <QSeparator class="col-12" />
        <QItem
          v-if="!isLoading && selectedOption.length > 2"
          clickable
          @click="addNewOption"
          :disable="isOptionCreating"
          class="cursor-pointer"
        >
          <QItemSection>
            <QItemLabel class="text-grey-7 items-center row">
              <QIcon :name="plusIcon" class="q-mr-sm" color="secondary" size="20px" />
              <span class="text-secondary col-grow"> Add this option </span>
              <QSpinner color="secondary" size="xs" v-if="isOptionCreating" />
            </QItemLabel>
          </QItemSection>
        </QItem>
      </template>
      <template v-slot:option="scope">
        <QItem dense style="align-items: center !important" class="row" v-bind="scope.itemProps" v-on="scope.itemEvents">
          <QItemLabel class="col-6 text-body2">{{ scope.opt.description }}</QItemLabel>
          <QSeparator style="height: 25px" vertical inset class="q-mx-lg" />
          <QItemLabel class="text-body2">$ {{ formatCurrency(scope.opt.catalogPrice) }}</QItemLabel>
        </QItem>
      </template>
      <template slot="no-option">
        <QItem
          v-if="!isLoading && selectedOption.length > 2"
          clickable
          @click="addNewOption"
          :disable="isOptionCreating"
          class="cursor-pointer"
        >
          <QItemSection>
            <QItemLabel class="text-grey-7 items-center row">
              <QIcon :name="plusIcon" class="q-mr-sm" color="secondary" size="20px" />
              <span class="text-secondary col-grow"> Add this option </span>
              <QSpinner color="secondary" size="xs" v-if="isOptionCreating" />
            </QItemLabel>
          </QItemSection>
        </QItem>
      </template>
    </QSelect>
  </div>
</template>

<script>
import { QSelect, QItem, QSeparator, QItemLabel, QItemSection, QSpinner, QIcon, Icons } from 'eclipx.ui';

export default {
  name: 'PredictiveSearchbox',
  data() {
    return {
      selectedOption: '',
      selectOptions: [],
      filterOptions: [],
      currentOptions: [],
      sortedBy: null,
      filteredBy: null,
      isOptionCreating: false
    };
  },
  props: {
    value: {
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    filterByColumn: {
      type: String
    },
    sortByColumn: {
      type: String
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    label: String
  },
  watch: {
    value(value) {
      this.selectedOption = value ? value : '';
    }
  },
  created() {
    this.plusIcon = Icons.evaPlusCircle;
    // Parse props
    this.sortedBy = this.sortByColumn || Object.keys(options[0])[0];
    this.filteredBy = this.filterByColumn || Object.keys(options[0])[0];

    this.currentOptions = this.options;
    this.selectOptions = this.sortOptions(this.currentOptions);
  },
  methods: {
    formatCurrency: function (value) {
      value = parseFloat(value);

      if (!value || value == '0.00') {
        return '0.00';
      }

      return value.toLocaleString('en-AU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 10
      });
    },
    inputedOption(value) {
      this.selectedOption = value;
    },
    addNewOption() {
      this.isOptionCreating = true;
      setTimeout(() => {
        this.isOptionCreating = false;
      }, 1000);

      const option = {
        name: this.selectedOption,
        description: this.selectedOption,
        catalogPrice: '0.0',
        consumerPrice: '0.0'
      };

      this.currentOptions = [...this.currentOptions, option];
      this.selectOptions = this.sortOptions(this.currentOptions);

      this.filterOptions = [...this.filterOptions, option];

      // Set the selected option to the newly added option
      this.selectedOption = option;
      this.emitSelection();

      this.$emit('update-options', this.selectOptions);

      this.$refs.select.refresh();
      this.$refs.select.hidePopup();
    },
    getLabel(option) {
      return option[this.filterByColumn];
    },
    sortOptions(options) {
      if (!this.sortByColumn) {
        this.sortedBy = Object.keys(options[0])[0];
      }
      return options.sort((accountA, accountB) => {
        if (typeof accountA[this.sortedBy] === 'string') {
          const sortA = accountA[this.sortedBy].toUpperCase();
          const sortB = accountB[this.sortedBy].toUpperCase();

          return sortA.localeCompare(sortB);
        }

        return sortA[sortByColumn] > sortB[sortByColumn];
      });
    },
    handleFilter(value, update) {
      update(() => {
        const fitlerValue = value.toUpperCase();

        const filterByNearestTerm = this.sortOptions(
          this.currentOptions.filter((option) => {
            return option[this.filteredBy].toUpperCase().slice(0, fitlerValue.length) === fitlerValue;
          })
        );

        const filterBySimilarLetterPattern = this.sortOptions(
          this.selectOptions.filter((option) => {
            return option[this.filteredBy].toUpperCase().indexOf(fitlerValue) > -1;
          })
        );

        this.filterOptions = [...filterByNearestTerm, ...filterBySimilarLetterPattern].reduce((previousValue, current) => {
          const existingIndex = previousValue.findIndex((option) => option[this.filteredBy] === current[this.filteredBy]);

          if (existingIndex === -1) {
            previousValue.push(current);
          }

          return previousValue;
        }, []);
      });
    },
    emitSelection() {
      this.$emit('input', this.selectedOption);
    }
  },
  components: {
    QSelect,
    QSeparator,
    QItem,
    QItemLabel,
    QIcon,
    QItemSection,
    QSpinner
  }
};
</script>

<style lang="sass">
.predictive-search-width
  min-width: 55% !important
</style>

<template>
  <QSelect v-bind="$attrs" :value="value" :options="options" :display-value="value ? value : 'Select'" @input="$emit('input', $event)" />
</template>

<script>
import { QSelect } from 'eclipx.ui';

/**
 * This component represents A quasar select with preset options for Australian states.
 */
export default {
  name: 'StateSelect',
  props: {
    value: {
      required: true
    }
  },
  data() {
    return {
      options: ['NSW', 'VIC', 'QLD', 'WA', 'SA', 'TAS', 'ACT', 'NT']
    };
  },
  components: {
    QSelect
  }
};
</script>

<style lang="sass" scoped></style>

<template>
  <div>
    <p>Dear {{ user ? user.firstName : 'user' }},</p>
    <p><b>Access to your FleetPartners account has been revoked by FleetPartners.</b></p>
    <p>If access is still required, please organise for your Relationship Manager to contact us at:</p>
    <div>
      <ul>
        <li><a href="https://www.fleetpartners.co.nz/contact-us">Contact Us</a> for New Zealand</li>
        <li><a href="https://www.fleetpartners.com.au/SME-contact-us">Contact Us</a> for Australia</li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  name: 'Orgaccess',
  computed: {
    ...mapState({
      user: (state) => {
        return state.auth.user;
      }
    })
  }
};
</script>

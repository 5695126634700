<template>
  <QCard bordered>
    <QuoteSummaryCarDetails :loading="vehicleLoading" v-if="!reviewState" />
    <QSeparator v-if="!reviewState" />
    <QuoteSummaryLeaseDetails :loading="financeLoading" :has-price="hasPrice" />
  </QCard>
</template>

<script>
import { QCard, QSeparator } from 'eclipx.ui';
import QuoteSummaryLeaseDetails from './SummaryCardLease/SummaryCardLease';
import QuoteSummaryCarDetails from './SummaryCardCar/SummaryCardCar';

export default {
  name: 'QuoteSummaryCard',
  props: {
    vehicleLoading: Boolean,
    financeLoading: Boolean,
    hasPrice: Boolean
  },
  computed: {
    reviewState: function () {
      return this.$route.name === 'quoteReview';
    }
  },
  components: {
    QCard,
    QSeparator,
    QuoteSummaryCarDetails,
    QuoteSummaryLeaseDetails
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped></style>

import gql from 'graphql-tag';
export default gql`
  query getResidualValueVehicles {
    getResidualValueVehicles {
      catalogVehicleId
      description
      modelId
    }
  }
`;

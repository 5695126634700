<template>
  <div>
    <h6 class="q-mt-none q-mb-md text-grey-9">Quote Details</h6>
    <ReviewQuoteCar
      :image="variant.imageUrl"
      :title="`${make.name} ${model.name} ${variant.trim && variant.trim.length > 1 ? variant.trim : ''}`"
      :description="variant.derivative"
      :fuel-type="variant.fuelType"
      :transmission="variant.transmission"
      :engine="variant.engineDescription"
      :driveTrain="variant.driveTrain"
      :seats="variant.seats"
    />
    <QSeparator class="q-my-md" />
    <ReviewQuoteColourAndExtras :disabled="isExpired || hasRequestedPricing" />
    <QSeparator class="q-my-md" />
    <ReviewQuoteOrderDetails :disabled="isExpired" />
  </div>
</template>

<script>
import { QSeparator } from 'eclipx.ui';
import { mapState, mapGetters } from 'vuex';
import ReviewQuoteCar from '@/components/ReviewQuote/ReviewQuoteCar';
import ReviewQuoteColourAndExtras from '@/components/ReviewQuote/ReviewQuoteColourAndExtras';
import ReviewQuoteOrderDetails from '@/components/ReviewQuote/ReviewQuoteOrderDetails';

export default {
  name: 'DriveReview',
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapState({
      make: (state) => state.quote.quote.erpquote.make,
      variant: (state) => state.quote.quote.erpquote.variant,
      model: (state) => state.quote.quote.erpquote.model
    }),
    ...mapGetters({
      isExpired: 'quote/isExpired',
      hasRequestedPricing: 'quote/hasRequestedPricing'
    })
  },
  created() {
    // If the prior page is invalid, go to the first incomplete page
    if (!this.$store.getters['quote/quoteCustomerDetailsValid']) {
      this.$router.replace({
        name: this.$store.getters['quote/firstIncompletePage'],
        params: { quoteId: this.$route.params.quoteId }
      });
    }
  },
  components: {
    QSeparator,
    ReviewQuoteCar,
    ReviewQuoteColourAndExtras,
    ReviewQuoteOrderDetails
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped></style>

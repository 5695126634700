<template>
  <main class="q-page column q-pa-lg">
    <h6 class="q-mt-none q-mb-md text-weight-bold text-grey-9">Edit User</h6>
    <div v-if="loading" class="flex justify-center q-pa-md">
      <QSpinner size="md" color="grey-7" />
    </div>
    <div class="row q-gutter-md" v-else>
      <div class="col q-pa-lg self-start q-card q-card--bordered">
        <QForm @submit="saveUserDetails">
          <div class="row">
            <h6 class="col-12 q-mt-none q-mb-none text-grey-8">User Details</h6>
            <FormError :show="$v.$invalid && $v.$dirty" class="col-12 q-mt-md" />
            <div class="col-12 col-md-6 col-lg-4 col-xl-3 q-mt-md">
              <label class="text-body2" for="fname">First name</label><br />
              <QInput
                v-model="userById.firstName"
                :error="$v.userById.firstName.$error"
                filled
                maxlength="50"
                color="secondary"
                dense
                hide-bottom-space
                clearable
                required
                :disable="!userById.isActive"
              />
              <div v-if="$v.userById.firstName.$dirty">
                <div v-if="!$v.userById.firstName.minLength" class="text-body3 validation-error">
                  First name must have at least {{ $v.userById.firstName.$params.minLength.min }} letters.
                </div>
                <div v-if="!$v.userById.firstName.maxLength" class="text-body3 validation-error">
                  First name must have at most {{ $v.userById.firstName.$params.maxLength.max }} letters.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4 col-xl-3 q-mt-md">
              <label class="text-body2" for="lname">Last name</label><br />
              <QInput
                v-model="userById.lastName"
                :error="$v.userById.lastName.$error"
                filled
                maxlength="50"
                color="secondary"
                dense
                hide-bottom-space
                clearable
                required
                :disable="!userById.isActive"
              />
              <div v-if="$v.userById.lastName.$dirty">
                <div v-if="!$v.userById.lastName.minLength" class="text-body3 validation-error">
                  Last name must have at least {{ $v.userById.lastName.$params.minLength.min }} letters.
                </div>
                <div v-if="!$v.userById.lastName.maxLength" class="text-body3 validation-error">
                  Last name must have at most {{ $v.userById.lastName.$params.maxLength.max }} letters.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4 col-xl-3 q-mt-md">
              <label class="text-body2" for="lname">Email</label><br />
              <QInput
                v-model="userById.email"
                :error="$v.userById.email.$error"
                disable
                filled
                maxlength="50"
                color="secondary"
                dense
                hide-bottom-space
                clearable
              />
              <div v-if="$v.userById.email.$dirty">
                <div v-if="!$v.userById.email.email" class="text-body3 validation-error">Input should be email address.</div>
                <div v-if="!$v.userById.email.maxLength" class="text-body3 validation-error">
                  Email must have at most {{ $v.userById.email.$params.maxLength.max }} letters.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4 col-xl-3 q-mt-md">
              <p class="text-body2 q-mb-xs">Quote Visibility</p>
              <QSelect
                color="primary"
                v-model="selected"
                dense
                map-options
                hide-bottom-space
                filled
                :clearable="true"
                emit-value
                :options="visibility"
                option-value="role"
                option-label="name"
                :disable="!userById.isActive"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4 col-xl-3 q-mt-md">
              <label class="text-body2" for="lname">Active</label>
              <QCheckbox color="secondary" v-model="userById.isActive" />
            </div>
          </div>
          <QSeparator v-if="isOrganisationCommissionEnabled && this.isItemisedPricingEnabled" class="q-my-lg" />
          <div class="row">
            <div
              class="col-12 col-md-6 col-lg-4 col-xl-3 q-mt-md"
              v-if="
                isOrganisationCommissionEnabled &&
                this.isItemisedPricingEnabled &&
                (hasRole('DB Eclipx Admin') || hasRole('DB Broker Admin'))
              "
            >
              <h6 class="text-body2 text-bold col-12 q-mb-md" for="lname">Commissions</h6>
              <div class="col-12 row">
                <QToggle
                  class="col-4 q-mr-lg text-body3"
                  v-model="commissions.isCommissionsEnabled"
                  size="lg"
                  color="secondary"
                  label="Commissions"
                  :disable="!userById.isActive"
                />
                <QSeparator vertical inset class="q-mr-lg" />
                <QSelect
                  class="col-grow"
                  color="primary"
                  v-model="commissions.status"
                  dense
                  hide-bottom-space
                  filled
                  :clearable="true"
                  :options="commissionOptions"
                  :disable="!commissions.isCommissionsEnabled || !userById.isActive"
                  :error="$v.commissions.status.$error"
                />
              </div>
            </div>

            <p v-if="userById.updatedAt" class="q-mt-lg q-mb-lg text-body3 text-grey-8 col-12">{{ getLastUpdatedDate }}</p>
          </div>

          <QSeparator v-if="userById.updatedAt" class="q-my-md" />
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4 col-xl-3 q-mt-md">
              <QBtn class="q-mr-sm" label="Reset Password" color="secondary" @click="prompt = true" :disable="!userById.isActive" />
              <QBtn class="q-mr-sm" type="submit" :label="'Save'" color="primary" :loading="saving" :disable="saving" :ripple="false" />

              <QDialog v-model="prompt" persistent>
                <QCard style="min-width: 350px">
                  <QCardSection>
                    <div class="text-h6">Send password reset email</div>
                  </QCardSection>

                  <QCardSection class="q-pt-none">
                    <QInput dense v-model="userById.email" disable autofocus @keyup.enter="prompt = false" />
                  </QCardSection>

                  <QCardActions align="right" class="text-primary">
                    <QBtn flat label="Cancel" v-close-popup />
                    <QBtn flat label="Confirm" @click="resetPassword" v-close-popup />
                  </QCardActions>
                </QCard>
              </QDialog>
            </div>
          </div>
        </QForm>
      </div>
    </div>
  </main>
</template>

<script>
import {
  QBtn,
  QDialog,
  QCard,
  QCardSection,
  QCardActions,
  QForm,
  QInput,
  QCheckbox,
  QSelect,
  ClosePopup,
  QSpinner,
  QToggle
} from 'eclipx.ui';
import { mapState, mapMutations } from 'vuex';
import { GLOBAL_ERROR } from '../../components/GlobalError/GlobalError';
import QueryUserById from '../../graphql/queries/QueryUserById';
import MutateUserById from '../../graphql/mutations/MutateUserById';
import MutateResetPassword from '../../graphql/mutations/MutateResetPasswordByEmail';
import FormError from '../../components/FormError/FormError';
import { required, requiredIf, minLength, maxLength, email } from 'vuelidate/lib/validators';
import { QSeparator } from 'quasar';

export default {
  name: 'User',
  data() {
    return {
      saving: false,
      userById: {},
      prompt: false,
      visibility: [
        { role: 'DB Broker Restricted', name: 'Can only see own quotes created' },
        { role: 'DB Broker Unrestricted', name: 'Can see all quotes in Dealership' }
      ],
      loading: true,
      isItemisedPricingEnabled: false,
      isOrganisationCommissionEnabled: false,
      commissions: {
        isCommissionsEnabled: false,
        status: ''
      },
      commissionOptions: ['Hide', 'Show', 'Edit']
    };
  },
  watch: {
    'commissions.isCommissionsEnabled'(value) {
      if (!value) {
        this.commissions.status = '';
      }
    },
    'userById.isActive'(value) {
      if (!value) {
        this.commissions.isCommissionsEnabled = false;
        this.commissions.status = '';
      }
    }
  },
  validations() {
    return {
      userById: {
        firstName: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(40)
        },
        lastName: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(40)
        },
        email: {
          required,
          email,
          maxLength: maxLength(80)
        }
      },
      commissions: {
        status: {
          required: requiredIf((component) => component.isCommissionsEnabled)
        }
      }
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user
    }),
    routeId() {
      return this.$route.params.userId;
    },
    selected: {
      get() {
        if (this.userById && this.userById.roles && this.userById.roles.length) {
          return this.userById.roles[0].name;
        } else {
          return '';
        }
      },
      set(val) {
        if (this.userById && this.userById.roles && this.userById.roles.length) {
          this.userById.roles[0].name = val;
        } else {
        }
      }
    },
    getLastUpdatedDate() {
      if (!this.userById?.updatedAt) {
        return;
      }

      const inputDate = new Date(this.userById.updatedAt);
      const locales = 'en-US';

      const year = inputDate.toLocaleString(locales, {
        year: 'numeric'
      });
      const month = inputDate.toLocaleString(locales, {
        month: 'long'
      });
      const day = inputDate.toLocaleString(locales, {
        day: 'numeric'
      });

      const time = inputDate.toLocaleString(locales, {
        hour: 'numeric',
        minute: 'numeric'
      });

      const outputDateFormatted = `${day} ${month} ${year}, ${time}`;

      return `Last Updated ${outputDateFormatted}`;
    },
    hasRole() {
      return this.$store.getters['auth/hasRole'];
    }
  },
  apollo: {
    userById: {
      query: QueryUserById,
      fetchPolicy: 'network-only',
      variables() {
        return {
          id: this.routeId
        };
      },
      update(data) {
        const { orgProfile, pricingConfiguration: userPricingConfiguration } = data.userById;

        if (orgProfile) {
          const { pricingConfiguration: orgPricingConfiguration } = orgProfile;
          this.isOrganisationCommissionEnabled = orgPricingConfiguration?.commissions?.isCommissionsEnabled || false;

          this.isItemisedPricingEnabled = orgPricingConfiguration?.isItemisedPricingEnabled || false;

          if (userPricingConfiguration?.isItemisedPricingEnabled && userPricingConfiguration?.commissions?.isCommissionsEnabled !== null) {
            this.commissions = {
              ...userPricingConfiguration?.commissions,
              status:
                userPricingConfiguration?.commissions.status.charAt(0).toUpperCase() +
                userPricingConfiguration?.commissions.status.slice(1).toLowerCase()
            };
          }
        }

        return data.userById;
      },
      error: function ({ graphQLErrors }) {
        const errMessage = graphQLErrors.map((err) => {
          return err.message;
        });
        /*Extract error message */
        let message = errMessage.join().replace(/Error:|{}/gi, '');
        if (message && message.startsWith('{"extensions":') && message.includes('"message"') && message.endsWith('}}}}}')) {
          message = message
            .substring(message.indexOf('"message'), message.indexOf('"}}}}}'))
            .replace(/message|:|"/gi, '')
            .trim();
        }
        if (message && message.trim().endsWith('.')) {
          message = message.slice(0, -1);
        }
        if (/:|{|}|"|\?|%/.test(message)) {
          message = '';
        }
        this.$euiNotifications.emit({
          notification: GLOBAL_ERROR,
          action: () => {
            return this.$apollo.queries.getUserByRole.refresh();
          },
          message
        });
      },
      result: function (res) {
        this.loading = false;
      }
    }
  },
  methods: {
    ...mapMutations({
      setUserPricingConfiguration: 'auth/setUserPricingConfiguration'
    }),
    saveUserDetails: async function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      // Prepare Pricing Configuration
      const pricingConfiguration = {
        isItemisedPricingEnabled: this.isItemisedPricingEnabled,
        commissions: {
          isCommissionsEnabled: this.isItemisedPricingEnabled ? this.commissions.isCommissionsEnabled : false,
          status: this.isItemisedPricingEnabled && this.commissions.isCommissionsEnabled ? this.commissions.status.toLowerCase() : ''
        }
      };

      this.loading = true;
      this.$apollo
        .mutate({
          mutation: MutateUserById,
          variables: {
            id: this.routeId,
            updateInfo: {
              firstName: this.userById.firstName,
              lastName: this.userById.lastName,
              isActive: this.userById.isActive,
              role: this.selected.role ? this.selected.role : this.selected,
              ...(this.isItemisedPricingEnabled && { pricingConfiguration })
            }
          }
        })
        .then(() => {
          if (this.user.id === this.userById.id) {
            this.setUserPricingConfiguration(pricingConfiguration);
          }

          this.loading = false;
          this.$router.push({
            name: 'users'
          });
        })
        .catch(() => {
          this.loading = false;
          let gqlError = error.graphQLErrors[0];
          this.error = gqlError ? gqlError.message : 'Error';
        });
    },
    resetPassword: async function () {
      this.loading = true;
      const resetPasswordByEmail = await this.$apollo
        .mutate({
          mutation: MutateResetPassword,
          variables: {
            email: this.userById.email,
            id: this.routeId
          }
        })
        .then((data) => data)
        .catch((error) => {
          let gqlError = error.graphQLErrors[0];
          this.error = gqlError ? gqlError.message : 'Error';
        });
      if (resetPasswordByEmail.data != null) {
        //alert(resetPasswordByEmail?.data?.resetPasswordByEmail?.message);
        this.loading = false;
        this.$router.push({
          name: 'users'
        });
      } else {
        this.loading = false;
        alert(this.error);
      }
      return resetPasswordByEmail;
    }
  },
  directives: {
    ClosePopup
  },
  components: {
    FormError,
    QBtn,
    QForm,
    QInput,
    QCheckbox,
    QDialog,
    QCard,
    QCardSection,
    QSelect,
    QSpinner,
    QCardActions,
    QToggle,
    QSeparator
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped></style>

import { LoginRedirect, LogoutRedirect } from 'eclipx.ui';
import store from '../store';
import DragonBallLayout from '../layouts/DragonballLayout';
import Quotes from '../pages/Quotes/Quotes';
import Quote from '../pages/Quote/Quote';
import LeaseDetails from '../pages/Quote/LeaseDetails/LeaseDetails';
import ColourAndExtras from '../pages/Quote/ColourAndExtras/ColourAndExtras';
import CustomerDetails from '../pages/Quote/CustomerDetails/CustomerDetails';
import QuoteDetails from '../pages/Quote/QuoteDetails/QuoteDetails';
import Review from '../pages/Quote/Review/Review';
import Car from '../pages/Quote/Car/Car';
import CarDetails from '../pages/Quote/Car/CarDetails';
import CarVariants from '../pages/Quote/Car/CarVariants';
import QuoteGuard from './QuoteGuard';
import Users from '../pages/Users/Users';
import User from '../pages/User/EditUser';
import NewUser from '../pages/User/NewUser';
import Dealers from '../pages/Dealers/Dealers';
import Dealer from '../pages/Dealer/EditDealer';
import NewDealer from '../pages/Dealer/NewDealer';
import Admins from '../pages/Admins/Admins';
import EditAdmin from '../pages/Admins/EditAdmin';
import NewAdmin from '../pages/Admins/NewAdmin';
import organisations from '../pages/Organisations/Organisations';
import organisationEdit from '../pages/Organisations/EditOrganisation';
import wetSignature from '../pages/Organisations/WetSignature';
import pricingConfiguration from '../pages/Organisations/PricingConfiguration';
import signatureType from '../pages/SignatureType/Index';
import whiteLabelAUTH0 from '../pages/WhiteLabel/AUTH0';
import whiteLabelDragonBallContent from '../pages/WhiteLabel/DragonBallContent';
import whiteLabelScoreBoardContent from '../pages/WhiteLabel/ScoreBoardContent';
import whiteLabelEmailTemplates from '../pages/WhiteLabel/EmailTemplates';
import whiteLabelIndex from '../pages/WhiteLabel/Index';
import UserGuard from './UserGuard';
import Useraccess from '../pages/Useraccess';
import Orgaccess from '../pages/Orgaccess';
import Error from '../pages/Error';
import ResetPassword from '@/pages/Auth/ResetPassword';

export const NewQuoteRouteParam = 'new-quote';
export const NewUserRouteParam = 'new-user';
export const NewDealerRouteParam = 'new-dealer';
export const NewAdminRouteParam = 'new-admin';
import { onLogout } from '../vue-apollo';

export default [
  // Un Authenticated Routes
  {
    path: '/login',
    component: LoginRedirect,
    meta: { skipAuthentication: true },
    props: function (route) {
      const showLoginQuery = route.query.showLogin;
      const showLoginCondition = typeof showLoginQuery === 'string' && showLoginQuery === 'false';

      return {
        redirectLoginOptions: {
          ...(showLoginCondition && { showLogin: showLoginQuery }),
          fragment: `domainkey=${encodeURIComponent(window.location.hostname)}`
        }
      };
    }
  },
  {
    path: '/logout',
    name: 'logout',
    meta: { skipAuthentication: true },
    component: LogoutRedirect,
    beforeEnter: (to, from, next) => {
      // Clear the  local user when logging out
      store.commit('auth/clearUser');
      store.commit('org/clearOrg');
      next((vm) => {
        onLogout(vm.$apollo);
      });
    }
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    meta: { skipAuthentication: true }
  },
  { path: '/', redirect: '/quotes', name: 'home' },
  {
    path: '/quotes',
    name: 'quotes',
    meta: { layout: DragonBallLayout },
    component: Quotes
  },
  {
    name: 'quote',
    path: '/quotes/:quoteId',
    redirect: '/quotes/:quoteId/car',
    props: true,
    component: Quote,
    beforeEnter: QuoteGuard,
    children: [
      {
        name: 'quoteCar',
        path: 'car',
        redirect: '/quotes/:quoteId/car/details',
        meta: { layout: DragonBallLayout },
        props: true,
        component: Car,
        beforeEnter: QuoteGuard,
        children: [
          {
            name: 'quoteCarDetails',
            path: 'details',
            meta: { layout: DragonBallLayout },
            component: CarDetails,
            beforeEnter: QuoteGuard
          },
          {
            name: 'quoteCarVariants',
            path: 'variants',
            meta: { layout: DragonBallLayout },
            component: CarVariants,
            beforeEnter: QuoteGuard
          }
        ]
      },
      {
        name: 'quoteLeaseDetails',
        path: 'lease-details',
        meta: { layout: DragonBallLayout },
        component: LeaseDetails,
        beforeEnter: QuoteGuard
      },
      {
        name: 'quoteColourAndExtras',
        path: 'colour-and-extras',
        meta: { layout: DragonBallLayout },
        component: ColourAndExtras,
        beforeEnter: QuoteGuard
      },
      {
        name: 'quoteCustomerDetails',
        path: 'customer-details',
        meta: { layout: DragonBallLayout },
        component: CustomerDetails,
        beforeEnter: QuoteGuard
      },
      {
        name: 'quoteReview',
        path: 'review-quote',
        meta: { layout: DragonBallLayout },
        component: Review,
        beforeEnter: QuoteGuard
      },
      {
        name: 'quoteDetails',
        path: 'quote-details',
        meta: { layout: DragonBallLayout },
        component: QuoteDetails,
        beforeEnter: QuoteGuard
      }
    ]
  },
  {
    path: '/users',
    name: 'users',
    meta: { layout: DragonBallLayout },
    component: Users
  },
  {
    name: 'NewUser',
    path: '/users/new-user',
    meta: { layout: DragonBallLayout },
    component: NewUser,
    beforeEnter: UserGuard
  },
  {
    name: 'userEdit',
    path: '/users/:userId',
    props: true,
    component: User,
    meta: { layout: DragonBallLayout }
  },
  {
    path: '/dealers',
    name: 'dealers',
    meta: { layout: DragonBallLayout },
    component: Dealers
  },
  {
    name: 'NewDealer',
    path: '/dealers/new-dealer',
    meta: { layout: DragonBallLayout },
    component: NewDealer
  },
  {
    name: 'dealerEdit',
    path: '/dealers/:dealerId',
    props: true,
    component: Dealer,
    meta: { layout: DragonBallLayout }
  },
  {
    name: 'admins',
    path: '/admins',
    props: true,
    component: Admins,
    meta: { layout: DragonBallLayout }
  },
  {
    name: 'adminEdit',
    path: '/admins/:userId',
    props: true,
    component: EditAdmin,
    meta: { layout: DragonBallLayout }
  },
  {
    name: 'NewAdmin',
    path: '/admins/new-admin',
    meta: { layout: DragonBallLayout },
    component: NewAdmin
  },
  {
    name: 'organisations',
    path: '/organisations',
    props: true,
    component: organisations,
    meta: { layout: DragonBallLayout }
  },
  {
    name: 'organisationEdit',
    path: '/organisations/:orgId',
    props: true,
    component: organisationEdit,
    meta: { layout: DragonBallLayout }
  },
  {
    name: 'wetSignature',
    path: '/organisations/:orgId/white-label',
    props: true,
    component: wetSignature,
    meta: { layout: DragonBallLayout }
  },
  {
    name: 'pricingConfiguration',
    path: '/organisations/:orgId/pricing-configuration',
    props: true,
    component: pricingConfiguration,
    meta: { layout: DragonBallLayout },
    beforeEnter: (to, from, next) => {
      const homeRoute = {
        name: 'organisationEdit',
        params: { orgId: to.params.orgId }
      };

      const erpCode = store.getters['org/getOrgErpCode'](to.params.orgId);

      if (!erpCode) {
        return next({ name: 'organisations' });
      }

      if (erpCode !== 'MILESNZ') {
        return next(homeRoute);
      }

      next();
    }
  },
  {
    path: '/signature-type',
    name: 'signaturetype',
    component: signatureType,
    meta: { skipAuthentication: true, layout: DragonBallLayout }
  },
  {
    path: '/white-label/dragonball-content',
    name: 'white-label-dragonball-content',
    component: whiteLabelIndex,
    meta: { skipAuthentication: true, layout: DragonBallLayout }
  },
  {
    path: '/white-label/quote-content',
    name: 'white-label-quote-content',
    component: whiteLabelIndex,
    meta: { skipAuthentication: true, layout: DragonBallLayout }
  },
  {
    path: '/white-label/scoreboard-content',
    name: 'white-label-scoreboard-content',
    component: whiteLabelIndex,
    meta: { skipAuthentication: true, layout: DragonBallLayout }
  },
  {
    path: '/white-label/auth0',
    name: 'white-label-auth0',
    component: whiteLabelIndex,
    meta: { skipAuthentication: true, layout: DragonBallLayout }
  },
  {
    path: '/white-label/email-templates',
    name: 'white-label-email-templates',
    component: whiteLabelIndex,
    meta: { skipAuthentication: true, layout: DragonBallLayout }
  },
  {
    path: '/useraccess',
    name: 'useraccess',
    component: Useraccess,
    meta: { skipAuthentication: true, layout: DragonBallLayout }
  },
  {
    path: '/orgaccess',
    name: 'orgaccess',
    component: Orgaccess,
    meta: { skipAuthentication: true, layout: DragonBallLayout }
  },
  {
    path: '/error',
    name: 'error',
    component: Error,
    meta: { skipAuthentication: true, layout: DragonBallLayout }
  }
];

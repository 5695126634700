import gql from 'graphql-tag';
export default gql`
  query dealers($params: DealerQuery) {
    dealers(params: $params) {
      count
      rows {
        id
        name
        org_id
        isActive
        createdAt
        updatedAt
      }
    }
  }
`;

<template>
  <div class="col-xs-12 col-sm-6 col-md-6" style="padding: 25px 50px 25px 0px">
    <div class="row">
      <div class="col-6" style="margin: auto">
        <strong>{{ caption }}</strong>
        <p v-if="info" v-html="info" style="font-size: 12px; padding-top: 5px"></p>
      </div>
      <div class="col-6 image-picker" style="text-align: right">
        <div style="height: 140px; max-width: 150px" v-if="loading">
          <QSpinner size="3em" color="grey-7" style="margin: 50px 10px" />
        </div>
        <QImg
          v-else
          :src="image ? `${imageUri}/${image}` : '/assets/images/placeholder.png'"
          spinner-color="white"
          style="height: 140px; max-width: 150px; border-style: solid; border-color: lightgray; cursor: pointer"
          @click="onClickUpload('file')"
        />

        <div style="padding-top: 10px; margin-right: 47px">
          <span style="cursor: pointer" @click="onClickUpload('file')">Edit</span> &nbsp;&nbsp;|&nbsp;&nbsp;
          <span style="cursor: pointer" @click="onRemoveImage('logo')">Remove</span>
        </div>
        <QFile ref="file" @input="onUpload($event, 'logo')" style="display: none" accept=".jpg, image/*" />
      </div>
    </div>
  </div>
</template>

<script>
import { QImg, QFile, QSpinner } from 'eclipx.ui';
import { GLOBAL_ERROR } from '../../../components/GlobalError/GlobalError';
import UploadImage from '../../../graphql/mutations/UploadOrgImage';
import appConfig from '../../../utils/app-config';

export default {
  name: 'ImagePicker',
  data() {
    return {
      imageUri: appConfig.IMAGE_ASSETS_URI ? appConfig.IMAGE_ASSETS_URI : 'https://assets.eclipx.xyz',
      image: null,
      loading: false
    };
  },
  props: {
    caption: String,
    info: String,
    field: String,
    imageProp: String
  },
  computed: {},
  created() {
    this.image = this.imageProp;
  },
  mounted() {},
  methods: {
    onClickUpload(ref) {
      this.$refs[ref].pickFiles();
    },
    onRemoveImage: function (field) {
      this.image = '';
      this.$emit('onImageChangeHandler', this.image, this.field);
    },
    uploadFile: async function (file, signedUrl) {
      return await fetch(signedUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type
        }
      }).catch((error) => {
        this.error = true;
        if (error) {
          this.$euiNotifications.emit({
            notification: GLOBAL_ERROR
          });
        }
      });
    },
    onUpload: async function (file, field) {
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: UploadImage,
          variables: {
            file: file
          }
        })
        .then(async (data) => {
          await this.uploadFile(file, data.data.upload.uploadOrgImage.signedUrl);
          this.image = data.data.upload.uploadOrgImage.uuid;
          this.loading = false;
          this.$emit('onImageChangeHandler', this.image, this.field);
        })
        .catch((error) => {
          let gqlError = error.graphQLErrors[0];
          this.error = gqlError ? gqlError.message : 'Error';
          this.loading = false;
        });
    }
  },
  components: {
    QImg,
    QFile,
    QSpinner
  }
};
</script>

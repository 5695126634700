var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('QTabs',{staticClass:"bg-white q-my-md rounded-borders text-grey-8 q-tabs--inset-indicator q-tabs--no-focus-helper",attrs:{"align":"justify","active-color":"secondary"}},[_c('QRouteTab',{staticClass:"q-py-sm",attrs:{"ripple":false,"label":"Vehicle","disable":_vm.disabled,"to":{ name: 'quoteCar', params: { quoteId: _vm.$route.params.quoteId } }}}),_c('QRouteTab',{staticClass:"q-py-sm",attrs:{"ripple":false,"label":"Lease Details","disable":!_vm.$store.getters['quote/quoteVariantValid'] || _vm.disabled,"to":{
      name: 'quoteLeaseDetails',
      params: { quoteId: _vm.$route.params.quoteId }
    }}}),_c('QRouteTab',{staticClass:"q-py-sm",attrs:{"ripple":false,"label":"Accessories & Options","disable":!_vm.$store.getters['quote/quoteLeaseValid'] || _vm.disabled,"to":{
      name: 'quoteColourAndExtras',
      params: { quoteId: _vm.$route.params.quoteId }
    }}}),_c('QRouteTab',{staticClass:"q-py-sm",attrs:{"ripple":false,"label":"Customer Details","disable":!_vm.$store.getters['quote/quoteColourAndExtrasValid'] || _vm.disabled || _vm.tabDisable,"to":{
      name: 'quoteCustomerDetails',
      params: { quoteId: _vm.$route.params.quoteId }
    }}}),_c('QRouteTab',{staticClass:"q-py-sm",attrs:{"ripple":false,"label":"Summary","disable":!_vm.$store.getters['quote/quoteCustomerDetailsValid'] || _vm.disabled,"to":{ name: 'quoteReview', params: { quoteId: _vm.$route.params.quoteId } }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import gql from 'graphql-tag';
export default gql`
  query getUserByRole($params: UserRoleQuery) {
    getUserByRole(params: $params) {
      count
      rows {
        id
        firstName
        lastName
        email
        phone
        isActive
        createdAt
        updatedAt
      }
    }
  }
`;

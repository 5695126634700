<template>
  <div>
    <div class="row justify-between items-center q-mb-md">
      <p class="q-mb-none text-weight-bold text-grey-9">Colour, Accessories, Options</p>
      <QBtn
        flat
        size="sm"
        v-if="$store.getters['quote/canCreate'] && !disabled"
        :disable="!$store.getters['quote/quoteLeaseValid']"
        :to="{
          name: 'quoteColourAndExtras',
          params: { quoteId: $route.params.quoteId }
        }"
      >
        <QIcon :name="editIcon" color="grey-9" class="q-mr-xs" />
        <span class="text-grey-9 text-weight-medium">Edit</span>
      </QBtn>
    </div>
    <div class="row q-mb-xs">
      <span class="col-5 col-md-3 col-lg-2 text-grey-7 text-body3 text-weight-medium"> Exterior Colour: </span>
      <span class="text-body3 col text-grey-7">
        {{ erpquote.exteriorColour.name }}
      </span>
    </div>
    <div class="row q-mb-xs" v-if="erpquote.interiorColour">
      <span class="col-5 col-md-3 col-lg-2 text-grey-7 text-body3 text-weight-medium"> Interior Colour: </span>
      <span class="text-body3 col text-grey-7">
        {{ erpquote.interiorColour.name }}
      </span>
    </div>
    <div class="row">
      <span class="col-5 col-md-3 col-lg-2 text-grey-7 text-body3 text-weight-medium"> Extras: </span>
      <div class="col text-body3">
        <p class="q-mb-none q-mb-xs text-grey-7" v-for="extra in erpquote.optionalEquipment" :key="extra.id">
          {{ extra.name }}
        </p>
        <p class="q-mb-none q-mb-xs text-grey-7" v-for="accessory in erpquote.adhocEquipment" :key="accessory.id">
          {{ accessory.name }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { QBtn, QIcon, Icons } from 'eclipx.ui';
import { mapState } from 'vuex';

export default {
  name: 'ReviewQuoteColourAndExtras',
  props: {
    disabled: Boolean
  },
  computed: {
    ...mapState({ erpquote: (state) => state.quote.quote.erpquote })
  },
  created() {
    this.editIcon = Icons.evaEdit;
  },
  components: {
    QBtn,
    QIcon
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped></style>

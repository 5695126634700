<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12">
      <hr style="border-top: 0.5px solid lightgray; margin: 30px 0px" />
    </div>
    <div class="col-xs-12 col-sm-6 col-md-6">
      <QBtn flat class="q-mr-sm" color="primary" label="Discard All Changes" @click="onDiscardHandler" />| &nbsp;&nbsp;
      <QBtn class="q-mr-sm" color="primary" label="Submit All Changes" @click="onClickHandler" />
      <QBtn class="q-mr-sm" color="primary" label="Deploy Pending" @click="onDeployHandler" v-if="dbManageAdmins && isPending" />
    </div>
    <div class="col-xs-12 col-sm-6 col-md-6">
      <QBtn
        v-if="nextUrlName"
        flat
        class="q-mr-sm float-right"
        color="primary"
        label="Continue"
        :icon-right="arrowIcon"
        @click="onNextHandler"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { QBtn, Icons } from 'eclipx.ui';
import { GlobalDialog } from '../../../components/Dialogs/GlobalDialog';
import SubmitWhiteLabelContents from '../../../graphql/mutations/SubmitWhiteLabelContent';
import DeployWhiteLabelContent from '../../../graphql/mutations/DeployWhiteLabelContents';
import QueryGetOrgProfileDetails from '../../../graphql/queries/QueryGetOrgProfileDetails';
import { GLOBAL_ERROR } from '../../../components/GlobalError/GlobalError.vue';

export default {
  name: 'FooterNav',
  props: {
    nextUrlName: String
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      auth0: 'whiteLabel/auth0',
      dragonball: 'whiteLabel/dragonball',
      quote: 'whiteLabel/quote',
      scoreboard: 'whiteLabel/scoreboard',
      email: 'whiteLabel/email',
      // whiteLabel:'whiteLabel/whiteLabel',
      isPending: 'whiteLabel/isPending'
    }),
    features() {
      return this.$store.getters['auth/feature'];
    },
    dbManageAdmins() {
      return this.features && this.features.indexOf('DB::ManageAdmins') > -1;
    }
  },
  created() {
    this.arrowIcon = Icons.evaArrowIosForwardOutline;
  },
  mounted() {},
  methods: {
    ...mapMutations({
      setWhiteLabel: 'whiteLabel/setWhiteLabel',
      clearWhiteLabel: 'whiteLabel/clearWhiteLabel',
      setIsPending: 'whiteLabel/setIsPending',
      setSaving: 'whiteLabel/setSaving',
      setOrgProfile: 'orgProfile/setOrgProfile'
    }),
    onNextHandler: async function () {
      this.$router.push({ name: this.nextUrlName });
    },
    onClickHandler: async function () {
      this.$euiNotifications.emit({
        notification: GlobalDialog,
        noEscDismiss: true,
        persistent: true,
        body:
          '<div class="col-12" style="text-align: center">' +
          '<img src="/assets/images/alert.png" class="header-logo" />' +
          '</div>' +
          '<p style="text-align:center">Please ensure you have completed all steps before submitting for approval</p>',
        qCardStyle: {
          padding: '0',
          width: '400px',
          maxWidth: '80vw'
        },
        closeBtnStyle: {
          display: 'none'
        },
        btnOne: {
          label: 'CANCEL',
          outline: true,
          color: 'primary',
          action: async () => {
            return;
          }
        },
        btnTwo: {
          label: 'SUBMIT',
          outline: false,
          color: 'primary',
          action: () => {
            this.submit();
          }
        }
      });
    },
    onDiscardHandler: async function () {
      this.$euiNotifications.emit({
        notification: GlobalDialog,
        noEscDismiss: true,
        persistent: true,
        body:
          '<div class="col-12" style="text-align: center">' +
          '<img src="/assets/images/alert.png" class="header-logo" />' +
          '</div>' +
          '<p style="text-align:center">Are you sure to discard the changes?</p>',
        qCardStyle: {
          padding: '0',
          width: '400px',
          maxWidth: '80vw'
        },
        closeBtnStyle: {
          display: 'none'
        },
        btnOne: {
          label: 'CANCEL',
          outline: true,
          color: 'primary',
          action: async () => {
            return;
          }
        },
        btnTwo: {
          label: 'SUBMIT',
          outline: false,
          color: 'primary',
          action: () => {
            this.discard();
          }
        }
      });
    },
    discard: async function () {
      this.setSaving(true);
      let query = await this.$apollo.query({
        query: QueryGetOrgProfileDetails,
        fetchPolicy: 'network-only'
      });

      if (
        query &&
        query.data &&
        query.data.getOrgProfileDetails &&
        (query.data.getOrgProfileDetails.pending || query.data.getOrgProfileDetails.active) &&
        query.data.getOrgProfileDetails.whiteLabel &&
        query.data.getOrgProfileDetails.whiteLabel.enableWhiteLabel
      ) {
        this.clearWhiteLabel();
        if (query.data.getOrgProfileDetails.pending && query.data.getOrgProfileDetails.pending.auth0) {
          this.setWhiteLabel({ ...query.data.getOrgProfileDetails.pending, isPending: true });
        } else {
          this.setWhiteLabel(query.data.getOrgProfileDetails.active);
        }
      } else this.$router.push({ name: 'quotes' });
      this.setSaving(false);
    },
    submit: async function () {
      const payload = {
        auth0: this.auth0,
        dragonball: this.dragonball,
        quote: this.quote,
        scoreboard: this.scoreboard,
        email: this.email
        /*whiteLabel:this.whiteLabel*/
      };
      this.setSaving(true);
      await this.$apollo
        .mutate({
          mutation: SubmitWhiteLabelContents,
          variables: {
            params: payload
          }
        })
        .then((data) => {
          const pending = data.data.saveWhiteLabelContent.pending;
          if (Object.keys(pending).length > 0) {
            this.setIsPending(true);
          }
        })
        .catch(({ graphQLErrors }) => {
          const errMessage = graphQLErrors.map((err) => {
            return err.message;
          });
          /*Extract error message */
          let message = errMessage.join().replace(/Error:|{}/gi, '');
          if (message && message.startsWith('{"extensions":') && message.includes('"message"') && message.endsWith('}}}}}')) {
            message = message
              .substring(message.indexOf('"message'), message.indexOf('"}}}}}'))
              .replace(/message|:|"/gi, '')
              .trim();
          }
          if (message && message.trim().endsWith('.')) {
            message = message.slice(0, -1);
          }
          if (/:|{|}|"|\?|%/.test(message)) {
            message = '';
          }
          this.$euiNotifications.emit({
            notification: GLOBAL_ERROR,
            action: () => {
              return this.submit();
            },
            message
          });
        })
        .finally(() => {
          this.setSaving(false);
        });
    },
    onDeployHandler: async function () {
      this.$euiNotifications.emit({
        notification: GlobalDialog,
        noEscDismiss: true,
        persistent: true,
        body:
          '<div class="col-12" style="text-align: center">' +
          '<img src="/assets/images/alert.png" class="header-logo" />' +
          '</div>' +
          '<p style="text-align:center">Are you sure you want to deploy the changes?</p>',
        qCardStyle: {
          padding: '0',
          width: '400px',
          maxWidth: '80vw'
        },
        closeBtnStyle: {
          display: 'none'
        },
        btnOne: {
          label: 'CANCEL',
          outline: true,
          color: 'primary',
          action: async () => {
            return;
          }
        },
        btnTwo: {
          label: 'DEPLOY',
          outline: false,
          color: 'primary',
          action: () => {
            this.deploy();
          }
        }
      });
    },
    deploy: async function () {
      const payload = {
        auth0: this.auth0,
        dragonball: this.dragonball,
        quote: this.quote,
        scoreboard: this.scoreboard,
        email: this.email
        /*whiteLabel:this.whiteLabel*/
      };
      this.setSaving(true);
      await this.$apollo
        .mutate({
          mutation: DeployWhiteLabelContent,
          variables: {
            params: payload
          }
        })
        .then(({ data }) => {
          const pending = data.deployWhiteLabelContent?.response?.pending;
          if (Object.keys(pending).length === 0) {
            this.setIsPending(false);
          }

          /* Apply changes */
          if (data.deployWhiteLabelContent?.activeOrgProfile) {
            this.setOrgProfile(data.deployWhiteLabelContent?.activeOrgProfile);
            const primary = data.deployWhiteLabelContent?.activeOrgProfile?.dragonball?.primary;
            const secondary = data.deployWhiteLabelContent?.activeOrgProfile?.dragonball?.secondary;
            const background = data.deployWhiteLabelContent?.activeOrgProfile?.dragonball?.background;
            this.root = document.documentElement;

            if (primary) {
              this.root.style.setProperty('--q-color-primary', primary);
              this.root.style.setProperty('--primary-color', primary);
            }
            if (secondary) {
              this.root.style.setProperty('--q-color-secondary', secondary);
            }
            if (background) {
              this.root.style.setProperty('--q-color-accent', background);
            }
          }
        })
        .catch(({ graphQLErrors }) => {
          const errMessage = graphQLErrors.map((err) => {
            return err.message;
          });
          /*Extract error message */
          let message = errMessage.join().replace(/Error:|{}/gi, '');
          if (message && message.startsWith('{"extensions":') && message.includes('"message"') && message.endsWith('}}}}}')) {
            message = message
              .substring(message.indexOf('"message'), message.indexOf('"}}}}}'))
              .replace(/message|:|"/gi, '')
              .trim();
          }
          if (message && message.trim().endsWith('.')) {
            message = message.slice(0, -1);
          }
          if (/:|{|}|"|\?|%/.test(message)) {
            message = '';
          }

          this.$euiNotifications.emit({
            notification: GLOBAL_ERROR,
            action: () => {
              return this.deploy();
            },
            message
          });
        })
        .finally(() => {
          this.setSaving(false);
        });
    }
  },
  components: {
    QBtn
  }
};
</script>

import gql from 'graphql-tag';
export default gql`
  query user($email: String!, $appCode: String!) {
    user(email: $email, appCode: $appCode) {
      id
      firstName
      lastName
      email
      isActive
      organisation {
        id
        name
        isActive
        salesForceAccountId
        erps {
          code
        }
        org_branding {
          logo
          brand_name
        }
      }
      features {
        name
        code
      }
      roles {
        id
        name
      }
      featureAllocation {
        id
        feature {
          name
          code
        }
      }
      pricingConfiguration {
        isItemisedPricingEnabled
        commissions {
          isCommissionsEnabled
          status
        }
      }
    }
  }
`;

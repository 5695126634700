export default {
  namespaced: true,
  state: {
    role: null,
    selectedRole: null
  },
  getters: {
    role(state) {
      return state.role;
    },
    selectedRole(state) {
      return state.selectedRole;
    }
  },
  mutations: {
    clearRole(state) {
      state.role = null;
      state.selectedRole = null;
    },
    setRole(state, role) {
      state.role = role;
    },
    setSelectedRole(state, selectedRole) {
      state.selectedRole = selectedRole;
    }
  }
};

<template>
  <div class="column justify-center items-center gif-loading">
    <img :src="src" alt="Loading" :style="{ width: '450px', height: '475px' }" />
  </div>
</template>

<script>
export default {
  name: 'LoadingVehicle',
  computed: {
    src() {
      return `${process.env.BASE_URL}assets/images/animation_transparent.gif`;
    }
  }
};
</script>

<style lang="sass" scoped>
.gif-loading
  padding-bottom: 100px
  margin-top: -30px
</style>

<template>
  <div class="col-xs-6 col-md-4 col-lg-3 flex">
    <QCard bordered :class="['col-grow', ...this.cardStyles]" @click="onClick">
      <div :class="{ 'q-px-lg q-py-md': !this.image }">
        <QImg
          :src="this.imageUrl"
          :key="this.imageKey"
          spinner-color="grey-7"
          :ratio="16 / 9"
          basic
          contain
          :placeholder-src="this.placeholderUrl"
          @error="handleImageError"
        />
      </div>
      <div class="q-pa-md">
        <div
          style="
             {
              'height':!!modelcode?'95px': '75px';
            }
          "
        >
          <p class="text-weight-bold q-mb-none">{{ this.title }}</p>
          <p class="text-body3 text-grey-7 q-pb-xs q-mb-none">
            {{ this.description }}
          </p>
          <p v-if="!!modelCode" class="text-body3 text-grey-7 q-mb-none">MODEL CODE {{ this.modelCode }}</p>
        </div>
        <slot name="rating" />
        <h6 v-if="price" class="text-primary q-mt-md q-mb-none">
          {{ price | currency }}
        </h6>
        <p class="q-ma-none text-grey-7 text-body3" v-if="grossPrice">RRP: {{ grossPrice | currency }}</p>

        <div class="row q-py-xs q-mt-md">
          <QIcon color="grey-9" class="q-mr-sm" :name="this.fuelTypeIcon" size="xs" />
          <span class="text-body3 text-grey-7">{{ this.fuelType }}</span>
        </div>

        <div class="row q-pb-xs">
          <QIcon color="grey-9" class="q-mr-sm" :name="this.gearIcon" size="xs" />
          <span class="text-body3 text-grey-7">{{ this.transmission }}</span>
        </div>

        <div class="row q-pb-xs">
          <QIcon color="grey-9" class="q-mr-sm" :name="this.engineIcon" size="xs" />
          <span class="text-body3 text-grey-7">{{ this.engine }}</span>
        </div>

        <div class="row q-pb-xs">
          <QIcon color="grey-9" class="q-mr-sm" :name="this.wheelsIcon" size="xs" />
          <span class="text-body3 text-grey-7">{{ this.driveTrain }}</span>
        </div>

        <div class="row q-pb-xs">
          <QIcon color="grey-9" class="q-mr-sm" :name="this.seatIcon" size="xs" />
          <span class="text-body3 text-grey-7">{{ this.seats }}</span>
        </div>
      </div>
    </QCard>
  </div>
</template>

<script>
import { QCard, QIcon, QImg } from 'eclipx.ui';
import { engine, fuelType, gear, seat, wheels } from '../../icons/customIcons';

/**
 * This component represents UI to display a vehicle in a card
 */
export default {
  name: 'VehicleCard',
  props: {
    id: undefined,
    disabled: Boolean,
    image: {
      type: String
    },
    baseImage: {
      type: String
    },
    trimImage: {
      type: String
    },
    price: Number,
    grossPrice: Number,
    title: {
      type: String,
      default: '—'
    },
    modelCode: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: '—'
    },
    engine: {
      type: String,
      default: '—'
    },
    transmission: {
      type: String,
      default: '—'
    },
    fuelType: {
      type: String,
      default: '—'
    },
    driveTrain: {
      type: String,
      default: '—'
    },
    seats: {
      type: [String, Number],
      default: '—'
    },
    cardStyles: undefined
  },
  data() {
    return {
      placeholderUrl: `${process.env.BASE_URL}assets/images/vehicleImagePlaceholder.svg`,
      showTrimImage: false,
      imageUrl: this.trimImage ? this.trimImage : this.image,
      imageKey: 0
    };
  },
  methods: {
    onClick() {
      if (!this.disabled) {
        this.$emit('click', this.id, this.imageUrl);
      }
    },
    handleImageError() {
      if (this.imageKey == 5) {
        return;
      }

      if (this.trimImage == this.imageUrl && this.image != this.imageUrl) {
        this.imageUrl = this.image;
        this.imageKey += 1;
      } else if (this.image == this.imageUrl && this.baseImage) {
        this.imageUrl = this.baseImage;
        this.imageKey += 1;
      }
    }
  },
  created() {
    this.engineIcon = engine;
    this.fuelTypeIcon = fuelType;
    this.gearIcon = gear;
    this.seatIcon = seat;
    this.wheelsIcon = wheels;
  },
  components: {
    QCard,
    QIcon,
    QImg
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped></style>

<template>
  <main class="q-page column q-pa-lg">
    <h6 class="q-mt-none q-mb-md text-weight-bold text-grey-9">Edit Administrator</h6>
    <div v-if="loading" class="flex justify-center q-pa-md">
      <QSpinner size="md" color="grey-7" />
    </div>
    <div class="row q-gutter-md" v-else>
      <div class="col q-pa-lg self-start q-card q-card--bordered">
        <div style="max-width: 400px">
          <QForm @submit="saveUserDetails">
            <h6 class="q-mt-none q-mb-none text-grey-8">Administrator Details</h6>
            <FormError :show="$v.$invalid && $v.$dirty" class="q-mt-md" />
            <div class="q-mt-md">
              <label class="text-body3" for="fname">First name:</label><br />
              <QInput
                v-model="userById.firstName"
                :error="$v.userById.firstName.$error"
                filled
                maxlength="50"
                color="secondary"
                dense
                hide-bottom-space
                clearable
                required
              />
              <div v-if="$v.userById.firstName.$dirty">
                <div v-if="!$v.userById.firstName.minLength" class="text-body3 validation-error">
                  First name must have at least {{ $v.userById.firstName.$params.minLength.min }} letters.
                </div>
                <div v-if="!$v.userById.firstName.maxLength" class="text-body3 validation-error">
                  First name must have at most {{ $v.userById.firstName.$params.maxLength.max }} letters.
                </div>
              </div>
            </div>
            <div class="q-mt-md">
              <label class="text-body3" for="lname">Last name:</label><br />
              <QInput
                v-model="userById.lastName"
                :error="$v.userById.lastName.$error"
                filled
                maxlength="50"
                color="secondary"
                dense
                hide-bottom-space
                clearable
                required
              />
              <div v-if="$v.userById.lastName.$dirty">
                <div v-if="!$v.userById.lastName.minLength" class="text-body3 validation-error">
                  Last name must have at least {{ $v.userById.lastName.$params.minLength.min }} letters.
                </div>
                <div v-if="!$v.userById.lastName.maxLength" class="text-body3 validation-error">
                  Last name must have at most {{ $v.userById.lastName.$params.maxLength.max }} letters.
                </div>
              </div>
            </div>
            <div class="q-mt-md">
              <label class="text-body3" for="lname">Email:</label><br />
              <QInput
                v-model="userById.email"
                :error="$v.userById.email.$error"
                disable
                filled
                maxlength="50"
                color="secondary"
                dense
                hide-bottom-space
                clearable
                required
                type="email"
              />
              <div v-if="$v.userById.email.$dirty">
                <div v-if="!$v.userById.email.email" class="text-body3 validation-error">Input should be email address.</div>
                <div v-if="!$v.userById.email.maxLength" class="text-body3 validation-error">
                  Email must have at most {{ $v.userById.email.$params.maxLength.max }} letters.
                </div>
              </div>
            </div>
            <div class="q-mt-md">
              <p class="text-body3 q-mb-xs">User Role</p>
              <QSelect
                color="primary"
                v-model="selected"
                dense
                map-options
                hide-bottom-space
                filled
                :clearable="true"
                emit-value
                :options="roles"
                option-value="role"
                option-label="name"
              />
            </div>
            <div class="q-mt-md" v-if="user.id !== routeId">
              <label class="text-body3" for="lname">Active:</label><br />
              <QCheckbox v-model="userById.isActive" />
            </div>
            <div class="q-mt-lg">
              <QBtn class="q-mr-sm" type="submit" :label="'Save'" color="secondary" :loading="saving" :disable="saving" :ripple="false" />
              <QBtn class="q-mr-sm" label="Reset Password" color="secondary" @click="prompt = true" :disable="!userById.isActive" />

              <QDialog v-model="prompt" persistent>
                <QCard style="min-width: 350px">
                  <QCardSection>
                    <div class="text-h6">Send password reset email</div>
                  </QCardSection>

                  <QCardSection class="q-pt-none">
                    <QInput dense v-model="userById.email" disable autofocus @keyup.enter="prompt = false" />
                  </QCardSection>

                  <QCardActions align="right" class="text-primary">
                    <QBtn flat label="Cancel" v-close-popup />
                    <QBtn flat label="Confirm" @click="resetPassword" v-close-popup />
                  </QCardActions>
                </QCard>
              </QDialog>
            </div>
          </QForm>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { QBtn, QForm, QInput, QCheckbox, QSpinner, QDialog, QCard, QCardSection, QSelect, ClosePopup, QCardActions } from 'eclipx.ui';
import { GLOBAL_ERROR } from '../../components/GlobalError/GlobalError';
import QueryUserById from '../../graphql/queries/QueryUserById';
import MutateUserById from '../../graphql/mutations/MutateUserById';
import MutateResetPassword from '../../graphql/mutations/MutateResetPasswordByEmail';
import { mapState, mapMutations } from 'vuex';
// import { apolloProvider } from './graphql/apollo'
import FormError from '../../components/FormError/FormError';
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators';

export default {
  name: 'EditAdmin',
  data() {
    return {
      saving: false,
      userById: {},
      prompt: false,
      loading: false,
      roles: [
        { role: 'DB Eclipx Admin', name: 'DB Eclipx Admin' },
        { role: 'DB Eclipx User', name: 'DB Eclipx User' }
      ]
    };
  },
  validations() {
    return {
      userById: {
        firstName: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(40)
        },
        lastName: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(40)
        },
        email: {
          required,
          email,
          maxLength: maxLength(80)
        }
      }
    };
  },
  computed: {
    ...mapState({
      user: (state) => {
        return state.auth.user;
      }
    }),
    routeId() {
      return this.$route.params.userId;
    },
    selected: {
      get() {
        if (this.userById && this.userById.roles && this.userById.roles.length) {
          return this.userById.roles[0].name;
        } else {
          return '';
        }
      },
      set(val) {
        if (this.userById && this.userById.roles && this.userById.roles.length) {
          this.userById.roles[0].name = val;
        } else {
        }
      }
    }
  },
  apollo: {
    userById: {
      query: QueryUserById,
      fetchPolicy: 'network-only',
      variables() {
        return {
          id: this.routeId
        };
      },
      error: function (error) {
        if (error) {
          this.$euiNotifications.emit({
            notification: GLOBAL_ERROR,
            action: () => {
              return this.$apollo.queries.userById.refresh();
            }
          });
        }
      }
    }
  },
  methods: {
    saveUserDetails: async function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;

      this.$apollo
        .mutate({
          mutation: MutateUserById,
          variables: {
            id: this.routeId,
            updateInfo: {
              firstName: this.userById.firstName,
              lastName: this.userById.lastName,
              isActive: this.userById.isActive,
              role: this.selected
            }
          }
        })
        .then((data) => {
          this.loading = false;
          this.$router.push({
            name: 'admins'
          });
        })
        .catch(() => {
          this.loading = false;
          let gqlError = error.graphQLErrors[0];
          this.error = gqlError ? gqlError.message : 'Error';
        });
    },
    resetPassword: async function () {
      const resetPasswordByEmail = await this.$apollo
        .mutate({
          mutation: MutateResetPassword,
          variables: {
            email: this.userById.email,
            id: this.routeId
          }
        })
        .then((data) => data)
        .catch((error) => {
          let gqlError = error.graphQLErrors[0];
          this.error = gqlError ? gqlError.message : 'Error';
        });
      if (resetPasswordByEmail.data != null) {
        alert(resetPasswordByEmail?.data?.resetPasswordByEmail?.message);
        this.$router.push({
          name: 'admins'
        });
      } else {
        alert(this.error);
      }
      return resetPasswordByEmail;
    }
  },
  directives: {
    ClosePopup
  },
  components: {
    FormError,
    QBtn,
    QForm,
    QInput,
    QCheckbox,
    QDialog,
    QCard,
    QCardSection,
    QCardActions,
    QSpinner,
    QSelect
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped></style>

<template>
  <QDialog v-model="show" transition-show="scale" transition-hide="scale" :no-esc-dismiss="noEscDismiss" :persistent="persistent">
    <QCard :style="qCardStyle">
      <QCardSection class="items-center">
        <div class="row justify-between items-center full-width no-wrap">
          <h4 class="q-ma-none text-secondary col-shrink">
            <span v-html="subject"></span>
          </h4>
          <QBtn flat outline round class="self-start" :icon="close" v-close-popup :style="closeBtnStyle" />
        </div>
        <div class="row justify-between items-center full-width no-wrap">
          <p class="q-mb-none q-mt-md text-grey-9" style="margin-top: -10px; padding: 10px; width: 100%">
            <span v-html="body"></span>
          </p>
        </div>
      </QCardSection>
      <QCardActions class="q-pb-lg q-pt-none q-px-lg justify-center" v-if="btnOne || btnTwo">
        <QBtn
          v-if="btnOne"
          :outline="btnOne.outline ? btnOne.outline : false"
          class="q-px-sm q-py-xs text-body3"
          :color="btnOne.color ? btnOne.color : 'grey-7'"
          no-caps
          :label="btnOne.label ? btnOne.label : 'Button One Click'"
          @click="onBtnOneHandler"
          :loading="actionLoadingOne"
          :disabled="actionLoadingOne"
        />
        <QBtn
          v-if="btnTwo"
          :outline="btnTwo.outline ? btnTwo.outline : false"
          class="q-px-sm q-py-xs text-body3"
          :color="btnTwo.color ? btnTwo.color : 'grey-7'"
          no-caps
          :label="btnTwo.label ? btnTwo.label : 'Button Two Click'"
          @click="onBtnTwoHandler"
          :loading="actionLoadingTwo"
          :disabled="actionLoadingTwo"
          style="margin-left: 20px"
        />
      </QCardActions>
    </QCard>
  </QDialog>
</template>

<script>
import { QDialog, QCard, QCardSection, QCardActions, QBtn, Icons, ClosePopup } from 'eclipx.ui';
export const GlobalDialog = 'GlobalDialog';

/**
 * This component displays a global info message as a Dialog which provides the ability to trigger an action.
 * To invoke the UI, call:
 * @code this.$euiNotifications.emit({
            notification: GlobalDialog,
            noEscDismiss: true,
            persistent: true,
            subject: 'Company financial can include',
            body:
              '<span style="color:white">Company financial can include' +
              '<ul>' +
              '<li> Balance sheet </li>' +
              '<li> Income statments </li>' +
              '</ul></span>',
            qCardStyle: {
              padding: '0',
              width: '400px',
              maxWidth: '80vw',
              backgroundColor: '#004578'
            },
            closeBtnStyle: {
              display: 'none',
              color: 'white'
            },
            btnOne: {
              label: 'Ok1',
              outline: true,
              color: 'primary',
              action: () => {
                console.log('clicked button one');
                return;
              }
            },
            btnTwo: {
              label: 'Cancel',
              outline: true,
              color: 'primary',
              action: () => {
                console.log('clicked button two');
                return;
              }
            }
          });
 * passing an action param in the emit payload is optional and if omitted, will not display the action button.
 */
export default {
  name: 'GlobalDialog',
  data() {
    return {
      show: false,
      action: null,
      actionLoadingOne: false,
      actionLoadingTwo: false,
      subject: '',
      body: '',
      qCardStyle: '',
      closeBtnStyle: '',
      noEscDismiss: false,
      persistent: false,
      btnOne: {},
      btnTwo: {},
      errorStack: 0
    };
  },
  watch: {
    show: function (value) {
      if (!value) {
        this.action = null;
      }
    }
  },
  methods: {
    onBtnOneHandler: async function () {
      // Perform the action
      this.actionLoadingOne = true;
      if (this.btnOne && this.btnOne.action) await this.btnOne.action();
      // Resolve the action loading state
      this.actionLoadingOne = false;
      this.errorStack--;
      // Do not close the popup If there was an error emitted while the action was in progress
      if (!this.errorStack) {
        this.show = false;
      }
    },
    onBtnTwoHandler: async function () {
      // Perform the action
      this.actionLoadingTwo = true;
      if (this.btnTwo && this.btnTwo.action) await this.btnTwo.action();
      // Resolve the action loading state
      this.actionLoadingTwo = false;
      this.errorStack--;
      // Do not close the popup If there was an error emitted while the action was in progress
      if (!this.errorStack) {
        this.show = false;
      }
    }
  },
  created() {
    this.close = Icons.evaClose;
  },
  beforeCreate() {
    this.$euiNotifications.EventBus.$on(GlobalDialog, (params) => {
      this.$nextTick(function () {
        this.errorStack++;
        this.action = params.action;
        this.subject = params.subject;
        this.body = params.body;
        this.qCardStyle = params.qCardStyle;
        this.closeBtnStyle = params.closeBtnStyle;
        this.noEscDismiss = params.noEscDismie;
        this.persistent = params.persistent;
        this.btnOne = params.btnOne;
        this.btnTwo = params.btnTwo;
        this.show = true;
      });
    });
  },
  directives: {
    ClosePopup
  },
  components: {
    QDialog,
    QCard,
    QCardSection,
    QCardActions,
    QBtn
  }
};
</script>

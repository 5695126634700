import gql from 'graphql-tag';
export default gql`
  mutation requestPricing($params: DealerPortalInput) {
    requestPricing(params: $params) {
      code
      message
      dealerPortal
    }
  }
`;

export default {
  namespaced: true,
  state: {
    user: {
      pricingConfiguration: {
        isItemisedPricingEnabled: null,
        commissions: null
      }
    },
    feature: null,
    whiteLabel: null,
    orgFeatures: []
  },
  getters: {
    user(state) {
      return state.user;
    },
    feature(state) {
      let allFeatures;
      if (state.feature) allFeatures = [...state.feature, ...state.orgFeatures];
      else allFeatures = [...state.orgFeatures];
      return allFeatures;
    },
    // Authentications
    canCreate(state, getters, rootState) {
      // console.log(this);
      if (!rootState.auth.user) {
        return false;
      }
      return rootState.auth.feature && rootState.auth.feature.find((code) => code === 'DB::ManageUsers');
    },
    hasFeature: (state) => (id) => {
      return state.feature.indexOf(id) > -1;
    },
    whiteLabelEnabled: (state) => {
      if (state.whiteLabel && state.whiteLabel.enableWhiteLabel) return true;
      else return false;
    },
    hasRole: (state) => (roleName) => {
      return state.user.roles.some((role) => role.name.match(roleName));
    },
    isUserNZ: (state) => {
      const nzRegex = /@[A-Za-z0-9.-]+\.(co\.nz|.nz)$/;
      return nzRegex.test(state.user.email);
    }
  },
  mutations: {
    clearUser(state) {
      state.user = null;
      state.feature = null;
      state.whiteLabel = null;
    },
    setUserPricingConfiguration(state, pricingConfiguration) {
      state.user.pricingConfiguration = {
        ...state.user.pricingConfiguration,
        ...pricingConfiguration
      };
    },
    setUser(state, user) {
      state.user = user;
    },
    setWhiteLabel(state, whiteLabel) {
      state.whiteLabel = whiteLabel;
    },
    clearWhiteLabel(state) {
      state.whiteLabel = null;
    },
    setFeature(state, feature) {
      state.feature = feature;
    },
    setOrgFeatures(state, features) {
      state.orgFeatures = features;
    },
    clearOrgFeatures(state) {
      state.orgFeatures = [];
    }
  }
};

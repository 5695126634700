import store from '../store';
import { NewQuoteRouteParam } from './Routes';

/**
 * Prevent the user from accessing certain quote pages if they do not have required permissions.
 *
 * If a user can not read a quote they will be moved to the quotes list
 * If a user can not create a quote and are on a new quote route they will be moved to the quotes list
 * If a user can read a quote but not create a quote and are viewing a quote they will only be able to see the review.
 *
 * @param to The route going to
 * @param from The route leaving
 * @param next The callback for vue router to progress
 */
export default function (to, from, next) {
  const homeRoute = {
    name: 'quotes',
    params: { quoteId: to.params.quoteId }
  };

  // Kick the user back to the quotes list if they do not have permission to view a quote.
  if (!store.getters['quote/canRead'] && !store.getters['quote/canCreate']) {
    return next(homeRoute);
  }

  if (!store.getters['quote/canCreate']) {
    // Kick the user back to the quotes list of they are creating a new quote and do not have permission
    if (to.params.quoteId === NewQuoteRouteParam) {
      return next(homeRoute);
    }

    // Kick the user to the review page if they can read but not edit a quote
    if (store.getters['quote/canRead'] && to.name !== 'quoteReview') {
      return next({
        name: 'quoteReview',
        params: { quoteId: to.params.quoteId }
      });
    }
  }

  next();
}

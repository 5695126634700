export const initialVehicleStore = {
  vehicles: {
    residualValue: []
  }
};

export default {
  namespaced: true,
  state: initialVehicleStore,
  getters: {
    getResidualValueVehicles(state) {
      return state.vehicles.residualValue;
    }
  },
  mutations: {
    saveResidualValueVehicles(state, residualValueVehicles) {
      state.vehicles.residualValue = residualValueVehicles;
    },
    clearResidualValueVehicles(state) {
      state.vehicles.residualValue = [];
    }
  },
  actions: {
    setResidualValueVehicles({ commit, state }, residualValueVehicles) {
      if (state.vehicles.residualValue.length >= 0) {
        commit('clearResidualValueVehicles');
      }

      if (residualValueVehicles?.length >= 0) {
        commit('saveResidualValueVehicles', residualValueVehicles);
      }
    }
  }
};

<template>
  <div class="footer-links">
    <EUIFooter :links="links">
      <template slot="terms">
        <span v-if="orgProfile.footerText" v-html="orgProfile.footerText" style="color: white" />
        <span v-else style="color: white">
          The information is general in nature and does not constitute financial or tax advice. Independent financial and/or tax advice
          should be sought. You should read the relevant Product Disclosure Statement available by calling FleetPlus on 1300 353 381 and
          consider whether the insurance product is right for you before making any decision. FleetPlus Pty Ltd is an Authorised
          Representative (AR No. 270321) of the insurance issuers ERIC Insurance Limited (ABN 18 009 129 793) (AFSL 238279) and Allianz
          Australia Insurance Limited (ABN 15 000 122 850) (AFSL 23408).
        </span>
        <span v-if="!orgProfile.footerText" style="color: white">
          All applications for credit are subject to credit approval criteria. Terms and conditions, fees and charges apply.
        </span>
        <span v-if="!orgProfile.footerText" style="color: white">
          © 2020 FleetPlus Pty Ltd ABN 59 099 217 320 is a wholly owned subsidiary of Eclipx Group Limited ABN 85 131 557 901 ASX:ECX
        </span>
      </template>

      <template slot="logos">
        <QIcon v-for="logo in logos" :key="logo.index" :name="logo" class="logo q-mr-lg" style="min-width: 125px" />
      </template>
    </EUIFooter>
  </div>
</template>
<script>
import { QIcon, EUIFooter } from 'eclipx.ui';
import { mapGetters } from 'vuex';

/**
 * This component represents the footer which is to be displayed as part of all page layouts.
 */
export default {
  name: 'Terms',
  data() {
    return {
      footerText: '<p>Lorem ipsum dolor sit amet, consectetur adipiscin</p>'
    };
  },
  computed: {
    ...mapGetters({
      orgProfile: 'orgProfile/dragonball',
      whiteLabel: 'orgProfile/whiteLabel'
    }),
    logos() {
      var list = [];
      if (this.orgProfile?.footerLogo1) list.push(`img:${this.orgProfile?.footerLogo1}`);
      if (this.orgProfile?.footerLogo2) list.push(`img:${this.orgProfile?.footerLogo2}`);
      // if (this.orgProfile?.footerLogo3) list.push(`img:${this.orgProfile?.footerLogo3}`);
      // if (this.orgProfile?.footerLogo4) list.push(`img:${this.orgProfile?.footerLogo4}`);
      return list;
    },
    links() {
      return {
        'Terms Of Use': this.whiteLabel?.termsLink || `${process.env.BASE_URL}assets/legal/TermsAndConditions.pdf`,
        'Privacy Policy': this.whiteLabel?.privacyPolicy || 'http://eclipxgroup.com/privacy-policy'
      };
    }
  },
  components: {
    EUIFooter,
    QIcon
  }
};
</script>
<style lang="sass" scoped>
.primary-logo
  height: 40px

.logo-separator
  align-self: center
  height: 30px

.logo
  height: 30px
</style>

export const distance =
  'M15.5,10.8c-1.3,0-2.3-1.1-2.3-2.4c0-1,0.5-1.7,1.3-2.1c-0.9,0.3-1.4,1.2-1.4,2.2c0,1.3,1,2.4,2.3,2.4 c1.4,0,2.4-1.1,2.4-2.5C17.8,9.8,16.8,10.8,15.5,10.8z M21.7,7.5c-0.5-3.5-3.9-5.9-7.5-5.2C10.7,2.9,8.6,6.5,9.5,10c0.3,1.4,1.2,2.5,2,3.6c1.9,2.5,3.3,5.2,3.9,8.2 c0,0,0.1,0,0.3,0.1c0-0.3,0.1-0.6,0.2-0.9c0.6-2.5,1.7-4.6,3.1-6.6c0.8-1.1,1.5-2.2,2.1-3.4C21.7,9.9,21.9,8.7,21.7,7.5z M15.4,10.9c-1.3,0-2.3-1.1-2.3-2.4c0-1.1,0.6-1.9,1.4-2.2c0.3-0.1,0.6-0.2,1-0.3c1.3,0.1,2.3,1.1,2.3,2.4 C17.8,9.8,16.8,10.9,15.4,10.9z M15.4,6.1c-0.3,0-0.6,0.1-0.9,0.2c-0.8,0.4-1.3,1.2-1.3,2.1c0,1.3,1,2.4,2.3,2.4s2.3-1,2.3-2.4 C17.8,7.1,16.8,6.1,15.4,6.1z M15.5,6c-0.3,0-0.7,0.1-1,0.3C14.8,6.2,15.1,6.1,15.5,6c1.3,0.1,2.3,1.1,2.3,2.4C17.8,7.1,16.8,6.1,15.5,6z M5.5,11.8l-1.4-0.9c-0.6-0.3-1.3-0.1-1.6,0.4c-0.3,0.6-0.1,1.3,0.4,1.6l1.4,0.9C4.5,13.9,4.7,14,4.9,14 c0.4,0,0.7-0.2,1-0.6C6.2,12.8,6,12.1,5.5,11.8z M11.1,15.3l-1.4-0.9c-0.6-0.3-1.3-0.1-1.6,0.4c-0.3,0.6-0.1,1.3,0.4,1.6l1.4,0.9c0.2,0.1,0.4,0.2,0.6,0.2 c0.3,0,0.7-0.2,1-0.6C11.8,16.3,11.6,15.6,11.1,15.3z';
export const engine =
  'M8.9,5.3v1.3h0.6v0.9H7.6l-1.1,1H4.8v0.6v2.6H3.6V10H2.3v4.6h1.3V13h1.3v2.6v0.6h2.8l2.2,2h7.5V8.4h-1.7 l-1.1-1h-1.8V6.5h0.6V5.2H8.9V5.3z M21,9.6l-0.2-0.3h-2.5v8.4h2.5l0.2-0.3c0.1-0.1,0.7-1.2,0.7-3.9C21.7,10.9,21.1,9.8,21,9.6z';
export const fuelType =
  'M19.6,10.6l-1.7-1.3c-0.2-0.1-0.3-0.1-0.5-0.1l-5.9,2.5L10.3,9C10,8.3,9.4,7.8,8.8,7.8H6.6V6.5h2.3V5.2H3.2 v1.3h2.3v1.3H3.7c-1,0-1.7,0.9-1.7,2v6.7c0,1.1,0.8,2,1.7,2h7.5c0.5,0,1-0.3,1.3-0.7l5.1-7.1l1.3,1L19.6,10.6z M20.3,11.9c0,0-1.7,1.9-1.7,3.8c0,1.1,0.8,1.9,1.7,1.9c0.9,0,1.7-0.9,1.7-1.9C22,13.8,20.3,11.9,20.3,11.9z';
export const gear =
  'M11.3,3.3c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6c0.4,0,0.7-0.3,0.6-0.6C11.9,3.6,11.6,3.3,11.3,3.3z M17.8,10.3h-4.6V8.4c1.2-0.5,2.1-1.7,2.1-3.1C15.3,3.5,13.8,2,12,2S8.7,3.5,8.7,5.3c0,1.4,0.9,2.6,2.1,3.1v6.5 c0,0.7,0.6,1.2,1.2,1.2c0.7,0,1.2-0.6,1.2-1.2v-0.4h1.7c0.2,0,0.4-0.2,0.4-0.4v-1.2c0-0.5,0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8 v6.7c0,0.5-0.4,0.8-0.8,0.8c-0.5,0-0.8-0.4-0.8-0.8v-2.1c0-0.2-0.2-0.4-0.4-0.4h-1.7c-0.2,0-0.4,0.2-0.4,0.4v2.1 c0,0.5-0.4,0.8-0.8,0.8c-0.5,0-0.8-0.4-0.8-0.8v-2.1c0-0.2-0.2-0.4-0.4-0.4H9.1c-0.2,0-0.4,0.2-0.4,0.4v2.1c0,0.5-0.4,0.8-0.8,0.8 c-0.5,0-0.8-0.4-0.8-0.8v-6.7c0-0.5,0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8V14c0,0.2,0.2,0.4,0.4,0.4h0.4c0.2,0,0.4-0.2,0.4-0.4 v-3.3c0-0.2-0.2-0.4-0.4-0.4H6.1c-0.9,0-1.7,0.7-1.7,1.7v8.3c0,0.9,0.7,1.7,1.7,1.7h11.6c0.9,0,1.7-0.7,1.7-1.7V12 C19.4,11,18.7,10.3,17.8,10.3z M11.3,4.5c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6C12,4.2,11.7,4.5,11.3,4.5z';
export const seat =
  'M6.4,22c-0.3-0.1-0.7-0.2-1-0.5S4.9,21,4.8,20.6C4.6,19.4,4.3,18.2,4,17c-0.2-0.8,0.3-1.4,1.1-1.3c0.6,0,1.3,0.1,1.9,0.1 c0.8,0.1,1.6,0.1,2.4,0.2c1,0.1,2,0.1,3.1,0.2c0.1,0,0.3,0,0.4,0c0.1-0.5,0.2-1,0.3-1.5c0.4-2,0.9-4.1,1.3-6.1 c0.2-0.9,0.9-1.5,1.9-1.5c0.4,0,0.8,0,1.2,0c0.3,0,0.7,0,1.1,0c0.1-0.4,0.1-0.9,0.2-1.4c-0.8,0.2-1.6,0.1-2.4,0.1 c-0.8,0-1.4-0.7-1.3-1.6c0.1-0.5,0.1-1,0.5-1.4C16,2.3,16.5,2,17.2,2c0.4,0,0.8,0,1.2,0C19.3,2,20,2.7,20,3.6c0,0.3-0.1,0.7-0.1,1 c-0.1,0.5-0.1,1.1-0.2,1.6c-0.1,1-0.2,2.1-0.3,3.1c-0.1,0.9-0.2,1.8-0.3,2.7c-0.1,0.9-0.2,1.8-0.3,2.7c-0.1,0.9-0.2,1.8-0.3,2.7 c-0.1,1-0.2,2-0.3,3c-0.1,0.7-0.5,1.2-1.2,1.4c-0.1,0-0.1,0-0.2,0.1C13.4,22,9.9,22,6.4,22z';
export const wheels =
  'M12.4,21.9c-0.3,0-0.6,0-0.8,0h-0.1c-0.3,0-0.5,0-0.8-0.1c-0.7-0.1-1.4-0.3-2.1-0.5c-2.2-0.8-3.9-2.2-5.1-4.2 c-1.1-1.7-1.5-3.6-1.4-5.6c0-0.7,0.2-1.4,0.3-2.1C3,7.3,4.2,5.6,5.8,4.3c1.7-1.4,3.7-2.1,5.9-2.2c0.8,0,1.7,0.1,2.5,0.2 c2.3,0.5,4.2,1.7,5.7,3.6c1.2,1.5,1.9,3.3,2,5.3c0,0.1,0,0.3,0,0.4c0,0.2,0,0.5,0,0.7v0.1c0,0.3-0.1,0.7-0.1,1 c-0.1,0.8-0.3,1.6-0.7,2.4c-0.6,1.5-1.6,2.8-2.9,3.8c-1.6,1.3-3.4,2-5.4,2.2C12.7,21.9,12.6,21.9,12.4,21.9z M12,5.2 c-3.8,0-6.8,3.1-6.8,6.8c0,3.8,3.1,6.8,6.8,6.8c3.8,0,6.8-3.1,6.8-6.9C18.8,8.2,15.7,5.2,12,5.2z M14.4,12.1c0,0.5-0.2,0.9-0.4,1.3c0.8,0.7,1.7,1.4,2.5,2.1c-0.7,0.9-1.5,1.5-2.6,1.9c-0.4-1-0.8-2-1.2-3 c-0.2,0-0.3,0.1-0.5,0.1c-0.3,0-0.5,0-0.8-0.1c-0.1,0-0.1,0-0.1,0.1c-0.4,0.9-0.8,1.9-1.1,2.8v0.1c-1-0.4-1.9-1-2.6-1.9 c0.8-0.7,1.7-1.4,2.5-2.1c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.3-0.3-0.6-0.3-0.9c0-0.1,0-0.1-0.1-0.1C9,12,8.6,12,8.1,12 c-0.6,0-1.2-0.1-1.8-0.1c0-1.1,0.3-2.1,1-3c0.9,0.6,1.8,1.1,2.8,1.7c0.3-0.4,0.7-0.7,1.1-0.8c-0.3-1.1-0.5-2.1-0.8-3.2 c1.1-0.3,2.1-0.3,3.2,0c-0.3,1.1-0.5,2.1-0.8,3.2c0.5,0.2,0.8,0.4,1.1,0.8c0.9-0.6,1.8-1.1,2.8-1.7c0.6,0.9,0.9,1.9,1,3 C16.6,11.9,15.5,12,14.4,12.1z M12,10.6c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4 C13.4,11.2,12.8,10.6,12,10.6z';
export const calculator =
  'M19,2 C19.5522847,2 20,2.44771525 20,3 L20,21 C20,21.5522847 19.5522847,22 19,22 L5,22 C4.44771525,22 4,21.5522847 4,21 L4,3 C4,2.44771525 4.44771525,2 5,2 L19,2 Z M18,9.5 L6,9.5 L6,20 L18,20 L18,9.5 Z M8,16 C8.55228475,16 9,16.4477153 9,17 C9,17.5522847 8.55228475,18 8,18 C7.44771525,18 7,17.5522847 7,17 C7,16.4477153 7.44771525,16 8,16 Z M12,16 C12.5522847,16 13,16.4477153 13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 C11,16.4477153 11.4477153,16 12,16 Z M16,16 C16.5522847,16 17,16.4477153 17,17 C17,17.5522847 16.5522847,18 16,18 C15.4477153,18 15,17.5522847 15,17 C15,16.4477153 15.4477153,16 16,16 Z M8,12 C8.55228475,12 9,12.4477153 9,13 C9,13.5522847 8.55228475,14 8,14 C7.44771525,14 7,13.5522847 7,13 C7,12.4477153 7.44771525,12 8,12 Z M12,12 C12.5522847,12 13,12.4477153 13,13 C13,13.5522847 12.5522847,14 12,14 C11.4477153,14 11,13.5522847 11,13 C11,12.4477153 11.4477153,12 12,12 Z M16,12 C16.5522847,12 17,12.4477153 17,13 C17,13.5522847 16.5522847,14 16,14 C15.4477153,14 15,13.5522847 15,13 C15,12.4477153 15.4477153,12 16,12 Z M18,4 L6,4 L6,7.5 L18,7.5 L18,4 Z';

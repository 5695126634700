<template>
  <div>
    <h6 class="q-mt-none q-mb-md text-grey-8">Vehicle</h6>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Car'
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped></style>

<template>
  <div class="bg-white q-pa-lg" v-if="$store.getters['auth/hasFeature']('DB::ManageAdmins')">
    <SiteConfigTabs />
    <div class="bg-white q-pa-lg">
      <!--
      <div class="row justify-between items-center q-mb-lg">
        <h6 class="q-ma-none text-weight-bold text-grey-9">Administrators</h6>
      </div> -->
      <div class="row" style="text-align: right">
        <div class="col items-end">
          <QBtn
            class="q-mr-sm float-right"
            :to="{ name: 'NewAdmin', params: { userId: this.NewAdminRouteParam } }"
            color="secondary"
            label="New Administrator"
          />
        </div>
      </div>
      <QTable
        flat
        :loading="$apollo.queries.getUserByRole.loading"
        :columns="columns"
        :data="getUserByRole.rows"
        :pagination.sync="pagination"
        @row-click="this.editAdmin"
        @request="onRequest"
      />
    </div>
  </div>
</template>

<script>
import { QPage, QTable, QBtn } from 'eclipx.ui';
import adminTableColumns from '../Users/usersTableColumns';
import QueryAdmins from '../../graphql/queries/QueryAdmins';
import { GLOBAL_ERROR } from '../../components/GlobalError/GlobalError';
import SiteConfigTabs from './SiteConfigTabs';
import { NewAdminRouteParam } from '../../router/Routes';

export default {
  name: 'Admins',
  data() {
    return {
      columns: adminTableColumns,
      getUserByRole: {},
      sortBy: 'createdAt',
      descending: true,
      page: 1,
      rowsPerPage: 20,
      NewAdminRouteParam: NewAdminRouteParam
    };
  },
  computed: {
    pagination: {
      get: function () {
        return {
          sortBy: this.sortBy,
          descending: this.descending,
          page: this.page,
          rowsPerPage: this.rowsPerPage,
          rowsNumber: this.getUserByRole.count
        };
      },
      set: function (newValue) {}
    },
    displayArrow() {
      return this.pagination.rowsNumber > this.pagination.rowsPerPage ? true : false;
    },
    backArrow() {
      return this.page == 1 ? '' : 'btn-pagination';
    },
    skipArrow() {
      const trackTotalRecord = this.pagination.rowsPerPage * this.page;
      return trackTotalRecord < this.pagination.rowsNumber ? 'btn-pagination' : '';
    }
  },
  watch: {
    rowsPerPage(value) {
      if (value == 'All') return (this.rowsPerPage = this.pagination.rowsNumber);
    }
  },
  apollo: {
    getUserByRole: {
      query: QueryAdmins,
      fetchPolicy: 'network-only',
      variables() {
        return {
          params: {
            page: this.page - 1, // Quasar table is 1 based, the api is 0 based
            size: this.rowsPerPage,
            sortBy: this.sortBy,
            sortType: this.descending ? '-' : ' ',
            role: 'DB Eclipx'
          }
        };
      },
      error: function ({ graphQLErrors }) {
        const errMessage = graphQLErrors.map((err) => {
          return err.message;
        });
        /*Extract error message */
        let message = errMessage.join().replace(/Error:|{}/gi, '');
        if (message && message.startsWith('{"extensions":') && message.includes('"message"') && message.endsWith('}}}}}')) {
          message = message
            .substring(message.indexOf('"message'), message.indexOf('"}}}}}'))
            .replace(/message|:|"/gi, '')
            .trim();
        }
        if (message && message.trim().endsWith('.')) {
          message = message.slice(0, -1);
        }
        if (/:|{|}|"|\?|%/.test(message)) {
          message = '';
        }

        this.$euiNotifications.emit({
          notification: GLOBAL_ERROR,
          action: () => {
            return this.$apollo.queries.getUserByRole.refresh();
          },
          message
        });
      }
    }
  },
  mounted() {
    this.onRequest({
      pagination: this.pagination,
      filter: undefined
    });
  },
  methods: {
    editAdmin: function (event, row) {
      this.$router.push({
        name: 'adminEdit',
        params: { userId: row.id }
      });
    },
    onRequest: function (props) {
      const { page, rowsPerPage, sortBy, descending } = props.pagination;
      const filter = props.filter;

      setTimeout(() => {
        this.page = page;
        this.rowsPerPage = rowsPerPage;
        this.sortBy = sortBy;
        this.descending = descending;
      }, 500);
    }
  },
  components: {
    //QPage,
    QTable,
    QBtn,
    SiteConfigTabs
  }
};
</script>

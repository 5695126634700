<template>
  <main class="bg-white q-pa-lg" v-if="$store.getters['auth/hasFeature']('DB::ManageAdmins')">
    <SiteConfigTabs />
    <div v-if="loading" class="flex justify-center q-pa-md">
      <QSpinner size="md" color="grey-7" />
    </div>
    <div v-else class="bg-white q-pa-lg">
      <QForm style="width: 100%">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12" style="padding: 22px 12px 12px 12px">
            <h6 class="q-mt-none q-mb-none text-grey-8">Signature Type</h6>
            <p>By updating the signature type, it will apply for all customer entities within that state.</p>
            <p>
              Note: A Wet Signature refers to a hand made pen-and-ink mark of approval on paper while a Digital Signature is the same
              process conducted electronically.
            </p>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-3" style="padding: 22px 12px 12px 12px">
            <label class="text-body" for="name">New South Wales</label>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-3" style="padding: 12px">
            <QSelect v-model="form.wetSignNSW" :options="options" outlined dense />
          </div>

          <div class="col-xs-12 col-sm-6 col-md-3" style="padding: 22px 12px 12px 12px">
            <label class="text-body" for="name">Victoria</label>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-3" style="padding: 12px">
            <QSelect v-model="form.wetSignVIC" :options="options" outlined dense />
          </div>

          <div class="col-xs-12 col-sm-6 col-md-3" style="padding: 22px 12px 12px 12px">
            <label class="text-body" for="name">Queensland</label>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-3" style="padding: 12px">
            <QSelect v-model="form.wetSignQLD" :options="options" outlined dense />
          </div>

          <div class="col-xs-12 col-sm-6 col-md-3" style="padding: 22px 12px 12px 12px">
            <label class="text-body" for="name">South Australia</label>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-3" style="padding: 12px">
            <QSelect v-model="form.wetSignSA" :options="options" outlined dense />
          </div>

          <div class="col-xs-12 col-sm-6 col-md-3" style="padding: 22px 12px 12px 12px">
            <label class="text-body" for="name">Western Australia</label>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-3" style="padding: 12px">
            <QSelect v-model="form.wetSignWA" :options="options" outlined dense />
          </div>

          <div class="col-xs-12 col-sm-6 col-md-3" style="padding: 22px 12px 12px 12px">
            <label class="text-body" for="name">Tasmania</label>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-3" style="padding: 12px">
            <QSelect v-model="form.wetSignTAS" :options="options" outlined dense />
          </div>

          <div class="col-xs-12 col-sm-6 col-md-3" style="padding: 22px 12px 12px 12px">
            <label class="text-body" for="name">Northern Territory</label>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-3" style="padding: 12px">
            <QSelect v-model="form.wetSignNT" :options="options" outlined dense />
          </div>

          <div class="col-xs-12 col-sm-6 col-md-3" style="padding: 22px 12px 12px 12px">
            <label class="text-body" for="name">Australian Capital Territory</label>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-3" style="padding: 12px">
            <QSelect v-model="form.wetSignACT" :options="options" outlined dense />
          </div>

          <div class="col-xs-12 col-sm-6 col-md-3" style="padding: 22px 12px 12px 12px">
            <label class="text-body" for="name">New Zealand</label>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-3" style="padding: 12px">
            <QSelect v-model="form.wetSignNZ" :options="options" outlined dense />
          </div>
        </div>

        <hr style="margin-top: 15px; margin-bottom: 15px; border-top: 1px solid #dbd7d2" />

        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12" style="padding: 0px 12px 12px 32px">
            <QBtn class="q-mr-sm" label="Save Changes" color="secondary" :loading="saving" :ripple="false" @click="save" />
            <QBtn class="q-mr-sm" label="Cancel" color="secondary" :ripple="false" @click="cancel" />
          </div>
        </div>
      </QForm>
    </div>
  </main>
</template>

<script>
import { QForm, QBtn, QSpinner, QSelect } from 'eclipx.ui';
import { GLOBAL_ERROR } from '../../components/GlobalError/GlobalError';
import { GlobalDialog } from '../../components/Dialogs/GlobalDialog';
import SiteConfigTabs from '../Admins/SiteConfigTabs';
import QueryWetSignatureById from '../../graphql/queries/QueryWetSignatureById';
import UpdateWetSignature from '../../graphql/mutations/UpdateWetSignature';

export default {
  name: 'Index',
  data() {
    return {
      form: {
        wetSignNSW: 'Wet Signature',
        wetSignSA: 'Wet Signature',
        wetSignQLD: 'Wet Signature',
        wetSignWA: 'Wet Signature',
        wetSignVIC: 'Wet Signature',
        wetSignNZ: 'Wet Signature',
        wetSignTAS: 'Wet Signature',
        wetSignACT: 'Wet Signature',
        wetSignNT: 'Wet Signature'
      },
      objId: '4ce0dc01-129b-44f7-820e-48e0dfc76cf3',
      options: ['Wet Signature', 'Digital Signature'],
      saving: false,
      loading: false
    };
  },
  apollo: {
    getWetSignatureById: {
      query: QueryWetSignatureById,
      fetchPolicy: 'network-only',
      variables() {
        return {
          orgId: this.objId
        };
      },
      result({ data, loading, networkStatus }) {
        if (data && data.getWetSignatureById) this.form = data.getWetSignatureById;
      },
      watchLoading(isLoading, countModifier) {
        this.loading = isLoading;
      },
      error: function ({ graphQLErrors }) {
        const errMessage = graphQLErrors.map((err) => {
          return err.message;
        });
        /*Extract error message */
        let message = errMessage.join().replace(/Error:|{}/gi, '');
        if (message && message.startsWith('{"extensions":') && message.includes('"message"') && message.endsWith('}}}}}')) {
          message = message
            .substring(message.indexOf('"message'), message.indexOf('"}}}}}'))
            .replace(/message|:|"/gi, '')
            .trim();
        }
        if (message && message.trim().endsWith('.')) {
          message = message.slice(0, -1);
        }
        if (/:|{|}|"|\?|%/.test(message)) {
          message = '';
        }
        this.$euiNotifications.emit({
          notification: GLOBAL_ERROR,
          action: () => {
            return this.$apollo.queries.getWetSignatureById.refresh();
          },
          message //:errMessage.join().replace(/Error:|{}/gi, '')
        });
      }
    }
  },
  methods: {
    deploy: async function () {
      this.loading = true;
      this.loading = false;
    },
    save: async function () {
      this.$euiNotifications.emit({
        notification: GlobalDialog,
        noEscDismiss: true,
        persistent: true,
        body:
          '<div class="col-12" style="text-align: center">' +
          '<img src="/assets/images/alert.png" class="header-logo" />' +
          '</div>' +
          '<p style="text-align:center">By changing the signature type this will change all entities within that state.</p>',
        qCardStyle: {
          padding: '0',
          width: '400px',
          maxWidth: '80vw'
        },
        closeBtnStyle: {
          display: 'none'
        },
        btnOne: {
          label: 'CANCEL',
          outline: true,
          color: 'primary',
          action: async () => {
            return;
          }
        },
        btnTwo: {
          label: 'CONFIRM',
          outline: false,
          color: 'primary',
          action: () => {
            this.submit();
          }
        }
      });
    },
    submit: async function () {
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: UpdateWetSignature,
          variables: {
            orgId: this.objId,
            params: this.form
          }
        })
        .then((data) => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          let gqlError = error.graphQLErrors[0];
          this.error = gqlError ? gqlError.message : 'Error';
        });
    },
    cancel: function () {
      this.$router.go();
    }
  },
  components: {
    SiteConfigTabs,
    QForm,
    QBtn,
    QSelect,
    QSpinner
  }
};
</script>

<style lang="sass" scoped></style>

import gql from 'graphql-tag';
export default gql`
  mutation validateOptions($params: ValidationInput){
    validateOptions(params: $params) {
        code
        success
        message
        errors
    }
  }
`;
let config;

try {
  config = JSON.parse(window.appConfig);
} catch (err) {
  // eslint-disable-next-line
  console.error(err, 'config parsing failed');
  throw new Error('APP CONFIG is required');
}

export default config;

<template>
  <div class="row">
    <div class="col-6 col-md-5 col-lg-4 q-pr-xl">
      <QImg
        :src="this.imageUrl"
        :key="this.imageKey"
        spinner-color="grey-7"
        :ratio="16 / 9"
        basic
        contain
        :placeholder-src="this.placeholderUrl"
        @error="handleImageError"
      />
    </div>
    <div class="col-6 col-md-5">
      <h6 class="q-ma-none text-weight-bold q-mb-xs text-grey-9">
        {{ this.title }}
      </h6>
      <p class="text-body3 text-grey-7 q-pb-xs q-mb-none">
        {{ this.description }}
      </p>
      <div class="row q-mt-sm">
        <div class="col-6 q-mb-xs">
          <QIcon color="grey-9" class="q-mr-sm" :name="this.fuelTypeIcon" size="xs" />
          <span class="text-body3 text-grey-7">{{ this.fuelType }}</span>
        </div>
        <div class="col-6 q-mb-xs">
          <QIcon color="grey-9" class="q-mr-sm" :name="this.gearIcon" size="xs" />
          <span class="text-body3 text-grey-7">{{ this.transmission }}</span>
        </div>
        <div class="col-6 q-mb-xs">
          <QIcon color="grey-9" class="q-mr-sm" :name="this.engineIcon" size="xs" />
          <span class="text-body3 text-grey-7">{{ this.engine }}</span>
        </div>
        <div class="col-6 q-mb-xs">
          <QIcon color="grey-9" class="q-mr-sm" :name="this.wheelsIcon" size="xs" />
          <span class="text-body3 text-grey-7">{{ this.driveTrain }}</span>
        </div>
        <div class="col-6 q-mb-xs">
          <QIcon color="grey-9" class="q-mr-sm" :name="this.seatIcon" size="xs" />
          <span class="text-body3 text-grey-7">{{ this.seats }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QImg, QIcon } from 'eclipx.ui';
import { engine, fuelType, gear, seat, wheels } from '@/icons/customIcons';

export default {
  name: 'ReviewQuoteCar',
  props: {
    image: {
      type: String
    },
    trimImage: {
      type: String
    },
    baseImage: {
      type: String
    },
    title: {
      type: String,
      default: '—'
    },
    description: {
      type: String,
      default: '—'
    },
    engine: {
      type: String,
      default: '—'
    },
    transmission: {
      type: String,
      default: '—'
    },
    fuelType: {
      type: String,
      default: '—'
    },
    driveTrain: {
      type: String,
      default: '—'
    },
    seats: {
      type: [String, Number],
      default: '—'
    }
  },
  data() {
    return {
      placeholderUrl: `${process.env.BASE_URL}assets/images/vehicleImagePlaceholder.svg`,
      imageUrl: this.trimImage ? this.trimImage : this.image,
      imageKey: 0
    };
  },
  methods: {
    handleImageError() {
      if (this.imageKey == 5) {
        return;
      }

      if (this.trimImage == this.imageUrl && this.image != this.imageUrl) {
        this.imageUrl = this.image;
        this.imageKey += 1;
      } else if (this.image == this.imageUrl && this.baseImage) {
        this.imageUrl = this.baseImage;
        this.imageKey += 1;
      }
    }
  },
  created() {
    this.engineIcon = engine;
    this.fuelTypeIcon = fuelType;
    this.gearIcon = gear;
    this.seatIcon = seat;
    this.wheelsIcon = wheels;
  },
  components: {
    QImg,
    QIcon
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped></style>

<template>
  <QTabs
    align="justify"
    active-color="secondary"
    class="bg-white q-my-md rounded-borders text-grey-8 q-tabs--inset-indicator q-tabs--no-focus-helper"
  >
    <QRouteTab
      class="q-py-sm"
      :ripple="false"
      label="Users"
      :to="{ name: 'users' }"
      v-if="$store.getters['auth/hasFeature']('DB::ManageUsers')"
    />
    <QRouteTab
      class="q-py-sm"
      :ripple="false"
      label="Dealerships"
      :to="{ name: 'dealers' }"
      v-if="$store.getters['auth/hasFeature']('DB::ManageDealership') && !$store.getters['auth/hasFeature']('DB::OwnDealer')"
    />
  </QTabs>
</template>

<script>
import { QTabs, QRouteTab } from 'eclipx.ui';
import { mapState } from 'vuex';

/**
 * This component renders the tabs of the Quote pages.
 * The active tab is determined by matching the `:to` to the current route path.
 */
export default {
  name: 'AdminTabs',
  props: {
    disabled: Boolean
  },
  components: {
    QTabs,
    QRouteTab
  }
};
</script>

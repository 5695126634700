<template>
  <QExpansionItem
    class="q-mt-md"
    :expand-icon="chevronDown"
    :expandedIcon="chevronUp"
    header-class="text-body3 text-weight-bold text-grey-9"
    label="Specifications"
    dense
  >
    <div class="q-ml-lg q-my-sm">
      <div class="row q-mb-xs">
        <p class="q-mb-none text-weight-medium text-body3 text-grey-9 specification">Engine:</p>
        <p class="q-mb-none text-grey-8 col text-body3">
          {{ variant.engineDescription }}
        </p>
      </div>
      <div class="row q-mb-xs">
        <p class="q-mb-none text-weight-medium text-body3 text-grey-9 specification">Drive:</p>
        <p class="q-mb-none text-grey-8 col text-body3">
          {{ variant.driveTrain }}
        </p>
      </div>
      <div class="row q-mb-xs">
        <p class="q-mb-none text-weight-medium text-body3 text-grey-9" style="width: 50px">Seats:</p>
        <p class="q-mb-none text-grey-8 col text-body3">{{ variant.seats }}</p>
      </div>
    </div>
  </QExpansionItem>
</template>

<script>
import { QExpansionItem, Icons } from 'eclipx.ui';
import { mapState } from 'vuex';

export default {
  name: 'SummaryCardSpecifications',
  computed: mapState({
    variant: (state) => state.quote.quote.erpquote.variant
  }),
  created() {
    this.chevronDown = Icons.evaChevronDown;
    this.chevronUp = Icons.evaChevronUp;
  },
  components: { QExpansionItem }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
.specification
  width: 50px
</style>

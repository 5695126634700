<template>
  <QForm @submit="addSelectField">
    <div class="row q-col-gutter-md">
      <div class="col-6">
        <p class="text-body1 text-grey-9 q-mb-xs text-weight-bold">{{ searchFieldTitle }}</p>
      </div>
      <div class="col-6" v-if="isItemisedPricingEnabled">
        <p class="text-body1 text-grey-9 q-mb-xs text-weight-bold">{{ inputFieldTitle }}</p>
      </div>
    </div>
    <div class="row q-col-gutter-md q-pt-sm" v-for="(selectField, index) in mappedValues" :key="index">
      <div class="col-6">
        <QField class="col" dense read-only filled disable>
          <span class="q-field__native q-placeholder items-center row">
            {{ selectField.description }}
          </span>
        </QField>
      </div>
      <div class="col" v-if="isItemisedPricingEnabled">
        <QInput
          color="secondary"
          filled
          dense
          read-only
          disable
          hide-bottom-space
          type="text"
          prefix="$"
          :value="formatCurrency(selectField.price)"
        />
      </div>
      <div class="row col-shrink items-center">
        <QBtn dense size="xs" color="grey-7" text-color="grey-1" unelevated round :icon="closeIcon" @click="() => removeField(index)" />
      </div>
    </div>
    <div class="row q-col-gutter-md q-pt-sm" v-if="isItemisedPricingEnabled">
      <div class="col-6">
        <PredictiveSearchbox
          :label="searchFieldLabel"
          v-model="selectedValue"
          :options="filteredSelectedOption"
          :hasError="$v.selectedValue.$error"
          :filterByColumn="filterByColumn"
          :sortByColumn="sortByColumn"
          :isLoading="isLoading"
          :isDisabled="isDisabled"
          @update-options="updateOptions"
        >
          <template #before-options>
            <div class="row">
              <QItemLabel class="col-6"> Description </QItemLabel>
              <QItemLabel class="col-6"> Dealer Price </QItemLabel>
              <QSeparator style="height: 1px !important" class="q-my-xs col-12" />
            </div>
          </template>
          <template v-slot:option="scope">
            <div class="row" v-bind="scope.itemProps">
              <QItemLabel class="col-6"> {{ scope.opt.description }} </QItemLabel>
              <QSeparator vertical inset class="q-mr-lg" />
              <QItemLabel class="col"> {{ formatCurrency(scope.opt.catalogPrice) | currency }} </QItemLabel>
            </div>
          </template>
        </PredictiveSearchbox>
      </div>
      <div class="col" v-if="isItemisedPricingEnabled">
        <CurrencyInputField v-model="price" :isDisabled="isDisabled" :hasError="$v.price.$error" />
      </div>
      <div class="col-12">
        <QBanner flat class="price-warning color-7 q-mb-sm" inline-actions v-if="selectedValue && !parseFloat(price) && showPriceWarning"
          >The selected accessory price is $0.00. Please confirm this is correct before proceeding.
          <template v-slot:action>
            <QIcon
              style="cursor: pointer"
              class="icon-spacer"
              :name="closeIcon"
              size="sm"
              color="grey-7"
              @click="
                () => {
                  showPriceWarning = !showPriceWarning;
                }
              "
          /></template>
        </QBanner>
      </div>
    </div>
    <div class="row q-col-gutter-md q-pt-sm" v-else>
      <div class="col-6">
        <QInput
          color="secondary"
          filled
          dense
          type="text"
          :disable="isDisabled"
          hide-bottom-space
          v-model="selectedValue"
          :error="$v.selectedValue.$error"
        />
      </div>
    </div>
    <QBtn class="q-pl-none q-ma-none" flat :disabled="isDisabled" type="submit" color="secondary">
      <QIcon color="secondary" class="q-pa-none q-ma-none q-mr-sm" :name="plusIcon" size="sm" />
      <span class="q-pa-none q-am-none text-body3">ADD</span>
    </QBtn>
  </QForm>
</template>

<script>
import { QInput, QForm, QField, QBtn, QIcon, Icons, QSeparator, QItemLabel, QBanner } from 'eclipx.ui';
import PredictiveSearchbox from '@/components/PredictiveSearchbox/PredictiveSearchbox';
import CurrencyInputField from '@/components/CurrencyInputField/CurrencyInputField';
import { required, requiredIf } from 'vuelidate/lib/validators';

export default {
  name: 'MilesColourAndExtrasAdditionalOptions',
  props: {
    value: {
      type: Array,
      required: true
    },
    limit: {
      type: Number,
      default: 20
    },
    options: {
      type: Array,
      required: true
    },
    filterByColumn: {
      type: String
    },
    sortByColumn: {
      type: String
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    searchFieldTitle: String,
    inputFieldTitle: String,
    searchFieldLabel: String,
    inputFieldLabel: String,
    isItemisedPricingEnabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentOptions: [],
      selectedValue: null,
      selectedOptions: [],
      price: 0,
      showPriceWarning: true,
      hideZeroValue: false
    };
  },
  validations: {
    price: {
      required: requiredIf((component) => component.isItemisedPricingEnabled)
    },
    selectedValue: { required }
  },
  watch: {
    value(value) {
      this.selectedOptions = value;
    },
    selectedValue(value) {
      if (value) {
        this.price = value.catalogPrice;
      }
    }
  },
  computed: {
    filteredSelectedOption: function () {
      let options = this.currentOptions;

      // options = options.filter((value) => !this.selectedOptions?.some((option) => value.id === option.id));

      return options;
    },
    mappedValues: function () {
      return this.selectedOptions.map((value) => {
        return (
          this.currentOptions.some((option) => {
            return value.id === option.id;
          }) && value
        );
      });
    }
  },
  methods: {
    shouldHideZeroValue(value) {
      if (!this.formatIntoFloat(value) && !this.formatIntoFloat(this.price)) {
        this.hideZeroValue = true;
      }
    },
    showZeroValue(value) {
      if (!this.formatIntoFloat(value)) {
        this.hideZeroValue = false;
      }
    },
    updateOptions(options) {
      // Update the list of options in the parent component's data
      this.currentOptions = options;
    },
    formatCurrency: function (value) {
      value = this.formatIntoFloat(value);

      if (!value) {
        return '0.00';
      }

      return value.toLocaleString('en-AU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 10
      });
    },
    formatIntoFloat: function (value) {
      if (!parseFloat(value)) {
        return 0;
      }

      if (typeof value === 'string') {
        value = parseFloat(value.replace(/,/g, ''), 10);
      } else {
        value = parseFloat(value);
      }

      return !value ? 0 : value;
    },
    addSelectField: function () {
      this.$v.$touch();
      if (this.$v.$invalid || !this.selectedValue || (this.isItemisedPricingEnabled && !(parseFloat(this.price) >= 0))) {
        return;
      }

      const updatedSelectedValue = {
        ...(this.isItemisedPricingEnabled && { ...this.selectedValue }),
        description: this.isItemisedPricingEnabled ? this.selectedValue.description : this.selectedValue,
        name: this.isItemisedPricingEnabled ? this.selectedValue.description : this.selectedValue,
        id: this.isItemisedPricingEnabled && this.selectedValue.id,
        price: this.price.replace(/,/g, '') || 0,
        catalogPrice: this.price.replace(/,/g, '') || 0,
        isFreeFormOption: !this.options.some(
          (option) => this.selectedValue.id == option.id || this.selectedValue.description == option.description
        ),
        isSelected: true
      };

      this.$emit('input', [...this.value, updatedSelectedValue]);

      if (!this.isItemisedPricingEnabled) {
        this.updateOptions([...this.value, updatedSelectedValue]);
      }

      this.selectedValue = null;
      this.price = null;
      this.showPriceWarning = true;
      this.$v.$reset();
    },
    removeField: function (index) {
      let updatedFields = [...this.value];
      updatedFields.splice(index, 1);
      this.$emit('input', updatedFields);
    }
  },
  created() {
    this.currentOptions = this.options;
    this.plusIcon = Icons.evaPlusCircleOutline;
    this.closeIcon = Icons.evaClose;
    this.selectedOptions = this.value;
  },
  components: { QInput, QIcon, PredictiveSearchbox, QForm, QBtn, QField, QSeparator, QItemLabel, QBanner, CurrencyInputField }
};
</script>

<style lang="sass">
.q-banner.price-warning
  border-left: 2px solid #EA6712!important
  background-color: #FFF7F4!important
</style>

<template>
  <div class="bg-white q-pa-lg" v-if="$store.getters['auth/whiteLabelEnabled']">
    <div class="bg-white q-pa-lg">
      <div class="row">
        <div class="col-xs-12 col-sm-3 col-md-3" style="padding: 25px">
          <h5>Global Styles</h5>
          <br />
          <p>Select your company's colours and logos to reflect your brand's online experience.</p>
          <img src="/assets/images/whitelabel/dbGlobal.png" width="100%" />
        </div>
        <div class="col-xs-12 col-sm-9 col-md-9" style="padding: 25px">
          <strong>Global Colours</strong>
          <p>Select your company's colours or enter the HEX/ RGB codes. This will be used for links and buttons.</p>
          <br />
          <div class="row" style="width: 100%">
            <ColourPicker
              caption="Primary (Dark)"
              field="primary"
              info="This colour is used for the main buttons across the platform."
              :colourProp="form.primary"
              @onColourChangeHandler="onColourChangeHandler"
            />

            <ColourPicker
              caption="Secondary (Dark)"
              field="secondary"
              info="This colour is used to focus on a secondary option such as tabs or text."
              :colourProp="form.secondary"
              @onColourChangeHandler="onColourChangeHandler"
            />

            <ColourPicker
              caption="Background (Dark)"
              field="background"
              info="This colour is used for the background and footer."
              :colourProp="form.background"
              @onColourChangeHandler="onColourChangeHandler"
            />
          </div>

          <hr style="border-top: 0.5px solid lightgray; margin: 30px 0px" />

          <strong>Image Library</strong>
          <p>Upload your company's logo. This will be positioned on the left hand side within the header.</p>
          <br />

          <div class="row" style="width: 100%">
            <ImagePicker
              caption="Company Logo"
              field="primaryLogo"
              info="Recommended size 310 (w) 50 (h) px<br/>File Formant: PNG, JPG, GIF<br/>Max file size: 160KB"
              :imageProp="form.primaryLogo"
              @onImageChangeHandler="onImageChangeHandler"
            />
            <!-- <ImagePicker
              caption="Company Banner Image"
              field="bannerImage"
              info="Upload an Image or Company logo that will sit at the top of the emails that are generated from the Quoting Portal.<br/><br/>Recommended size 480 (w) x 200 (h) px<br/>File format: PNG, JPG, GIF<br/>Max file size: 160KB"
              :imageProp="form.bannerImage"
              @onImageChangeHandler="onImageChangeHandler"
            /> -->
          </div>
          <hr style="border-top: 0.5px solid lightgray; margin: 30px 0px" />
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-3 col-md-3" style="padding: 25px">
          <h5>Footer Information</h5>
          <br />
          <p>Customise your footer with your company's information and logos.</p>
          <img src="/assets/images/whitelabel/dbFooter.png" width="100%" />
        </div>
        <div class="col-xs-12 col-sm-9 col-md-9" style="padding: 25px">
          <strong>Footer Content</strong>
          <p>You can edit the text within your footer to include privacy policy, contact details etc.</p>
          <br />
          <div class="row" style="width: 100%">
            <div class="q-pa-xs col-12 col-sm-12">
              <QEditor
                v-model.lazy="form.footerText"
                placeholder="Footer text"
                min-height="200px"
                :content-style="{ backgroundColor: '#0000000d' }"
                :toolbar="[['bold', 'italic', 'underline', 'link']]"
                @input="onInputChangeHandler('footerText')"
                :disable="!hasFeature('DB::ManageAdmins')"
              />
            </div>
            <div class="q-pa-xs col-12 col-sm-6">
              <br />
              <label class="field-label">Terms of use link</label>
              <!-- <QInput v-model.lazy="form.termsLink" filled @input="onInputChangeHandler('termsLink')" disable/> -->
              <QInput v-model.lazy="form.termsLink" filled disable />
            </div>

            <div class="q-pa-xs col-12 col-sm-6">
              <br />
              <label class="field-label">Privacy Policy Link</label>
              <QInput v-model="form.privacyPolicy" filled disable />
            </div>
          </div>

          <br />
          <br />
          <strong>Image Library </strong>
          <p>Upload your company's logos that will appear in the footer.</p>
          <br />

          <div class="row" style="width: 100%">
            <ImagePicker
              caption="Primary Logo"
              field="footerLogo1"
              info="Recommended size 120 (w) x 30 (h) px<br/>File format: PNG, JPG, GIF<br/>Max file size: 160KB"
              :imageProp="form.footerLogo1"
              @onImageChangeHandler="onImageChangeHandler"
            />
            <ImagePicker
              caption="Primary Logo"
              field="footerLogo2"
              info="Recommended size 120 (w) x 30 (h) px<br/>File format: PNG, JPG, GIF<br/>Max file size: 160KB"
              :imageProp="form.footerLogo2"
              @onImageChangeHandler="onImageChangeHandler"
            />
            <!--
            <ImagePicker
              caption="Primary Logo"
              field="footerLogo3"
              info="Recommended aspect ratio 3:1<br/>Recommended size XXcm (w) x XXcm (h)<br/>File format: PNG, JPG, GIF<br/>Max file size: 160KB"
              :imageProp="form.footerLogo3"
              @onImageChangeHandler="onImageChangeHandler"
            />
            <ImagePicker
              caption="Primary Logo"
              field="footerLogo4"
              info="Recommended aspect ratio 3:1<br/>Recommended size XXcm (w) x XXcm (h)<br/>File format: PNG, JPG, GIF<br/>Max file size: 160KB"
              :imageProp="form.footerLogo4"
              @onImageChangeHandler="onImageChangeHandler"
            />
            -->
          </div>

          <hr style="border-top: 0.5px solid lightgray; margin: 30px 0px" />
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-3 col-md-3" style="padding: 25px">
          <h5>Quote Information</h5>
          <br />
          <p>Provide a background colour where the pricing will be displayed.</p>
          <img src="/assets/images/whitelabel/dbQuote.png" width="100%" />
        </div>
        <div class="col-xs-12 col-sm-9 col-md-9" style="padding: 25px">
          <br />
          <div class="row" style="width: 100%">
            <ColourPicker
              caption="Background Colour"
              field="quoteColour"
              :colourProp="form.quoteColour"
              @onColourChangeHandler="onColourChangeHandler"
              :disablePicker="!hasFeature('DB::ManageAdmins')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QEditor, QInput } from 'eclipx.ui';
import { mapGetters, mapMutations } from 'vuex';
import ColourPicker from './Modules/ColourPicker';
import ImagePicker from './Modules/ImagePicker';

export default {
  name: 'DragonBallContent',
  data() {
    return {
      form: {
        primary: '',
        secondary: '',
        background: '',
        primaryLogo: '',
        footerText: '',
        termsLink: '',
        privacyPolicy: '',
        footerLogo1: '',
        footerLogo2: '',
        footerLogo3: '',
        footerLogo4: '',
        quoteColour: ''
      }
    };
  },
  computed: {
    ...mapGetters({
      dragonball: 'whiteLabel/dragonball',
      whiteLabel: 'whiteLabel/whiteLabel',
      hasFeature: 'auth/hasFeature'
    })
  },
  created() {
    this.form.primary = this.dragonball.primary;
    this.form.secondary = this.dragonball.secondary;
    this.form.background = this.dragonball.background;
    this.form.primaryLogo = this.dragonball.primaryLogo;
    this.form.footerText = this.dragonball.footerText;
    this.form.termsLink = this.whiteLabel.termsLink;
    this.form.privacyPolicy = this.whiteLabel.privacyPolicy;
    this.form.footerLogo1 = this.dragonball.footerLogo1;
    this.form.footerLogo2 = this.dragonball.footerLogo2;
    this.form.footerLogo3 = this.dragonball.footerLogo3;
    this.form.footerLogo4 = this.dragonball.footerLogo4;
    this.form.quoteColour = this.dragonball.quoteColour;
  },
  mounted() {},
  methods: {
    ...mapMutations({ updateValueStatus: 'whiteLabel/updateValueStatus' }),
    onColourChangeHandler: function (colour, field) {
      this.form[field] = colour;
      this.updateValueStatus({
        object: 'dragonball',
        variable: field,
        value: colour
      });
    },
    onImageChangeHandler: function (image, field) {
      this.form[field] = image;
      this.updateValueStatus({
        object: 'dragonball',
        variable: field,
        value: image
      });
    },
    onInputChangeHandler: function (field) {
      this.updateValueStatus({
        /*object: field=='termsLink'|| field=='privacyPolicy' ?'whiteLabel':'dragonball',*/
        object: 'dragonball',
        variable: field,
        value: this.form[field]
      });
    }
  },
  components: {
    QEditor,
    ColourPicker,
    ImagePicker,
    QInput
  }
};
</script>

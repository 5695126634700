import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import quote from './modules/quotes/quoteStore';
import auth from './modules/auth/authStore';
import org from './modules/org/orgStore';
import role from './modules/role/roleStore';
import whiteLabel from './modules/whiteLabel/whiteLabelStore';
import orgProfile from './modules/orgProfile/orgProfileStore';
import vehicle from './modules/vehicles/vehicleStore';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  key: 'dragonball-vuex',
  storage: window.localStorage,
  modules: ['auth', 'quote', 'org', 'role']
});

export default new Vuex.Store({
  modules: {
    auth,
    quote,
    org,
    whiteLabel,
    role,
    orgProfile,
    vehicle
  },
  plugins: [vuexLocal.plugin]
});

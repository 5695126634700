<template>
  <main class="q-page column q-pa-lg">
    <h6 class="q-mt-none q-mb-md text-weight-bold text-grey-9">Edit Organisation</h6>
    <OrganisationTabs :orgId="routeId" />
    <div v-if="loading" class="flex justify-center q-pa-md">
      <QSpinner size="md" color="grey-7" />
    </div>
    <div v-else class="row q-gutter-md">
      <QForm style="width: 100%">
        <div class="col q-pa-lg self-start q-card q-card--bordered">
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-6" style="padding: 22px 12px 12px 32px">
              <h6 class="q-mt-none q-mb-none text-grey-8">White Label Experience</h6>
              <p v-if="!this.form.enableWhiteLabel">
                Move the slider to Active to invite a customer to configure a white label experience for their business.
              </p>
              <p v-else>
                To disable a customer from a white label experince, move the slider to Inactive. This will remove their branding and delete
                the sub domain name, reverting all configurations to default.
              </p>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6" style="padding: 22px 12px 12px 32px">
              <QToggle
                v-model="form.enableWhiteLabel"
                size="lg"
                :label="form.enableWhiteLabel ? 'Active' : 'Inactive'"
                left-label
                color="secondary"
                :disable="defaultDomain"
              />
            </div>
          </div>

          <hr v-if="form.enableWhiteLabel" style="margin-top: 15px; margin-bottom: 15px; border-top: 1px solid #dbd7d2" />
          <div class="row" v-if="form.enableWhiteLabel">
            <div class="col-xs-12 col-sm-6 col-md-6" style="padding: 0px 0px 0px 32px">
              <h6 class="q-mt-none q-mb-none text-grey-8">Sub-Domain Name*</h6>
              <p>
                Enter the name of the organisation below. This will be set up as a sub domain where the organisation's white label
                experience will be located. Please ensure you give the full web address to the customer.
              </p>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12">
              <FormError :show="$v.$invalid && $v.$dirty" class="q-mt-md" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6" style="padding: 0px 12px 12px 32px">
              <QInput
                outlined
                v-model="form.domain"
                filled
                placeholder="Entity-name-example"
                :label="`Scoreboard - ${form && form.domains && form.domains.length && form.domains[0]}`"
                :suffix="form.region === 'NZ' ? '.' + nzRootDomain : '.' + auRootDomain"
              >
              </QInput>
              <QInput
                outlined
                v-model="form.domain"
                :readonly="true"
                filled
                placeholder="Entity-name-example"
                :label="`Dragonball - ${form && form.domains && form.domains.length && form.domains[1]}`"
                :suffix="form.region === 'NZ' ? '.' + nzRootDomainDB : '.' + auRootDomainDB"
              >
              </QInput>
              <div class="text-red error-text text-body3 q-mt-md" v-if="!$v.form.domain.required">Field is required</div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-12" style="padding: 22px 0px 0px 32px">
              <h6 class="q-mt-none q-mb-none text-grey-8">Personalised Policy Information</h6>
              <p>Enter in the Introducer's URL's for terms of use link and privacy policy.</p>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6" style="padding: 0px 12px 12px 32px">
              <label class="field-label">Terms of Use *</label>
              <QInput v-model="form.termsLink" filled placeholder="Terms of use" prefix="https://" />
              <div class="text-red error-text text-body3 q-mt-md" v-if="!$v.form.termsLink.required">Field is required</div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6" style="padding: 0px 12px 12px 32px">
              <label class="field-label">Privacy Policy*</label>
              <QInput v-model="form.privacyPolicy" filled placeholder="Privacy Policy" prefix="https://" />
              <div class="text-red error-text text-body3 q-mt-md" v-if="!$v.form.privacyPolicy.required">Field is required</div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6" style="padding: 22px 0px 0px 32px">
              <h6 class="q-mt-none q-mb-none text-grey-8">Introducer Administration Email</h6>
              <p>
                Enter the introducer's administration email address for communications regarding configuration of their white label
                experience.
              </p>
              <label class="field-label">Email*</label>
              <QInput v-model="form.email" filled placeholder="email" />
              <div class="text-red error-text text-body3 q-mt-md" v-if="!$v.form.email.required">Field is required</div>
              <div class="text-red error-text text-body3 q-mt-md" v-if="!$v.form.email.email">Field should be email</div>
            </div>
          </div>

          <hr style="margin-top: 15px; margin-bottom: 15px; border-top: 1px solid #dbd7d2" />

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12" style="padding: 0px 12px 12px 32px">
              <QBtn class="q-mr-sm" label="Save Changes" color="secondary" :loading="saving" :ripple="false" @click="save" />
              <QBtn class="q-mr-sm" label="Cancel" color="secondary" :ripple="false" @click="cancel" />
            </div>
          </div>
        </div>
      </QForm>
    </div>
  </main>
</template>

<script>
import { QForm, QBtn, QSpinner, QInput, QToggle } from 'eclipx.ui';
import { GLOBAL_ERROR } from '../../components/GlobalError/GlobalError';
import { GlobalDialog } from '../../components/Dialogs/GlobalDialog';
import QueryWhiteLabelById from '../../graphql/queries/QueryWhiteLabelById';
import UpdateWhiteLabel from '../../graphql/mutations/UpdateWhiteLabel';
import OrganisationTabs from './OrganisationTabs';
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators';
import FormError from '../../components/FormError/FormError';
import appConfig from '../../utils/app-config';

export default {
  name: 'WetSignature',
  data() {
    return {
      form: {
        domain: '',
        enableWhiteLabel: false,
        privacyPolicy: '',
        termsLink: '',
        email: ''
      },
      auDefaultDomain: appConfig.VUE_APP_AU_DEFAULT_DOMAIN ? appConfig.VUE_APP_AU_DEFAULT_DOMAIN : 'fleetpartners-dev',
      nzDefaultDomain: appConfig.VUE_APP_NZ_DEFAULT_DOMAIN ? appConfig.VUE_APP_NZ_DEFAULT_DOMAIN : 'test10-fleetpartners',
      auRootDomain: appConfig.VUE_APP_AU_ROOT_DOMAIN ? appConfig.VUE_APP_AU_ROOT_DOMAIN : 'eclipx.xyz',
      nzRootDomain: appConfig.VUE_APP_NZ_ROOT_DOMAIN ? appConfig.VUE_APP_NZ_ROOT_DOMAIN : 'eclipx.xyz',
      auRootDomainDB: appConfig.VUE_APP_AU_ROOT_DOMAIN_DB ? appConfig.VUE_APP_AU_ROOT_DOMAIN_DB : 'eclipx.xyz1',
      nzRootDomainDB: appConfig.VUE_APP_NZ_ROOT_DOMAIN_DB ? appConfig.VUE_APP_NZ_ROOT_DOMAIN_DB : 'eclipx.xyz1',
      saving: false,
      deploying: false,
      loading: false
    };
  },
  validations() {
    return {
      form: {
        domain: this.form.enableWhiteLabel
          ? {
              required,
              minLength: minLength(2),
              maxLength: maxLength(60)
            }
          : true,
        email: this.form.enableWhiteLabel
          ? {
              required,
              email,
              minLength: minLength(2),
              maxLength: maxLength(60)
            }
          : true,
        privacyPolicy: this.form.enableWhiteLabel
          ? {
              required
            }
          : true,
        termsLink: this.form.enableWhiteLabel
          ? {
              required
            }
          : true
      }
    };
  },
  computed: {
    routeId() {
      return this.$route.params.orgId;
    },
    defaultDomain() {
      if (this.form.domain === this.auDefaultDomain || this.form.domain === this.nzDefaultDomain || this.form.abn === '53007870395') {
        return this.form.enableWhiteLabel;
      } else {
        return false;
      }
    }
  },
  apollo: {
    getWhiteLabelById: {
      query: QueryWhiteLabelById,
      fetchPolicy: 'network-only',
      variables() {
        return {
          orgId: this.routeId
        };
      },
      result({ data, loading, networkStatus }) {
        if (data && data.getWhiteLabelById) this.form = data.getWhiteLabelById;
        if (this.form && this.form.privacyPolicy) {
          this.form.privacyPolicy = this.form.privacyPolicy.replace('https://', '');
          this.form.privacyPolicy = this.form.privacyPolicy.replace('http://', '');
        }
        if (this.form && this.form.termsLink) {
          this.form.termsLink = this.form.termsLink.replace('https://', '');
          this.form.termsLink = this.form.termsLink.replace('http://', '');
        }
        if (this.form && this.form.domain) {
          if (this.form.region === 'NZ') {
            this.form.domain = this.form.domain.replace('.' + this.nzRootDomain, '');
          } else {
            this.form.domain = this.form.domain.replace('.' + this.auRootDomain, '');
          }
        }
      },
      watchLoading(isLoading, countModifier) {
        this.loading = isLoading;
      },
      error: function ({ graphQLErrors }) {
        const errMessage = graphQLErrors.map((err) => {
          return err.message;
        });
        /*Extract error message */
        let message = errMessage.join().replace(/Error:|{}/gi, '');
        if (message && message.startsWith('{"extensions":') && message.includes('"message"') && message.endsWith('}}}}}')) {
          message = message
            .substring(message.indexOf('"message'), message.indexOf('"}}}}}'))
            .replace(/message|:|"/gi, '')
            .trim();
        }
        if (message && message.trim().endsWith('.')) {
          message = message.slice(0, -1);
        }
        if (/:|{|}|"|\?|%/.test(message)) {
          message = '';
        }

        this.$euiNotifications.emit({
          notification: GLOBAL_ERROR,
          action: () => {
            return this.$apollo.queries.getWhiteLabelById.refresh();
          },
          message
        });
      }
    }
  },
  methods: {
    save: async function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$euiNotifications.emit({
        notification: GlobalDialog,
        noEscDismiss: true,
        persistent: true,
        body:
          '<div class="col-12" style="text-align: center">' +
          '<img src="/assets/images/alert.png" class="header-logo" />' +
          '</div>' +
          '<p style="text-align:center">Are you sure want to ' +
          (this.form.enableWhiteLabel ? 'activate' : 'inactivate') +
          ' the white label experience for ' +
          this.form.introducerName +
          '.</p>',
        qCardStyle: {
          padding: '0',
          width: '400px',
          maxWidth: '80vw'
        },
        closeBtnStyle: {
          display: 'none'
        },
        btnOne: {
          label: 'CANCEL',
          outline: true,
          color: 'primary',
          action: async () => {
            return;
          }
        },
        btnTwo: {
          label: 'CONFIRM',
          outline: false,
          color: 'primary',
          action: () => {
            this.submit();
          }
        }
      });
    },
    submit: async function () {
      this.loading = true;
      if (this.form && this.form.privacyPolicy) {
        this.form.privacyPolicy = this.form.privacyPolicy.replace('https://', '');
        this.form.privacyPolicy = this.form.privacyPolicy.replace('http://', '');
        this.form.privacyPolicy = 'https://' + this.form.privacyPolicy;
      }
      if (this.form && this.form.termsLink) {
        this.form.termsLink = this.form.termsLink.replace('https://', '');
        this.form.termsLink = this.form.termsLink.replace('http://', '');
        this.form.termsLink = 'https://' + this.form.termsLink;
      }

      if (this.form && this.form.domain) {
        this.form.domains = [];
        if (this.form.region === 'NZ') {
          this.form.domain = this.form.domain.replace('.' + this.nzRootDomain, '');
          this.form.domains.push(this.form.domain + '.' + this.nzRootDomain);
          this.form.domains.push(this.form.domain + '.' + this.nzRootDomainDB);
          this.form.domain = this.form.domain + '.' + this.nzRootDomain;
        } else {
          this.form.domain = this.form.domain.replace('.' + this.auRootDomain, '');
          this.form.domains.push(this.form.domain + '.' + this.auRootDomain);
          this.form.domains.push(this.form.domain + '.' + this.auRootDomainDB);
          this.form.domain = this.form.domain + '.' + this.auRootDomain;
        }
      }

      await this.$apollo
        .mutate({
          mutation: UpdateWhiteLabel,
          variables: {
            orgId: this.routeId,
            params: this.form
          }
        })
        .then(({ data }) => {
          this.loading = false;
          this.form = data?.updateWhiteLabel?.whiteLabel;
          if (this.form && this.form.privacyPolicy) {
            this.form.privacyPolicy = this.form.privacyPolicy.replace('https://', '');
            this.form.privacyPolicy = this.form.privacyPolicy.replace('http://', '');
          }
          if (this.form && this.form.termsLink) {
            this.form.termsLink = this.form.termsLink.replace('https://', '');
            this.form.termsLink = this.form.termsLink.replace('http://', '');
          }
          if (this.form && this.form.domain) {
            if (this.form.region === 'NZ') {
              this.form.domain = this.form.domain.replace('.' + this.nzRootDomain, '');
            } else {
              this.form.domain = this.form.domain.replace('.' + this.auRootDomain, '');
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          let gqlError = error.graphQLErrors[0];
          this.error = gqlError ? gqlError.message : 'Error';

          if (this.error && this.error.includes('DOMAIN_DUPLICATION')) {
            const duplicatedIntroducer = this.error.replace('DOMAIN_DUPLICATION - ', '');

            this.$euiNotifications.emit({
              notification: GlobalDialog,
              noEscDismiss: true,
              persistent: true,
              body:
                '<div class="col-12" style="text-align: center">' +
                '<img src="/assets/images/alert.png" class="header-logo" />' +
                '</div>' +
                '<p style="text-align:center; color: red; font-weight:700">Record could not be saved! <br/><br/>Sub-Domain Name is duplicated with ' +
                duplicatedIntroducer +
                '.</p>',
              qCardStyle: {
                padding: '0',
                width: '400px',
                maxWidth: '80vw'
              },
              closeBtnStyle: {
                display: 'none'
              },
              btnOne: {
                label: 'CANCEL',
                outline: true,
                color: 'primary',
                action: async () => {
                  return;
                }
              }
            });
          }

          if (this.form && this.form.privacyPolicy) {
            this.form.privacyPolicy = this.form.privacyPolicy.replace('https://', '');
            this.form.privacyPolicy = this.form.privacyPolicy.replace('http://', '');
          }
          if (this.form && this.form.termsLink) {
            this.form.termsLink = this.form.termsLink.replace('https://', '');
            this.form.termsLink = this.form.termsLink.replace('http://', '');
          }
          if (this.form && this.form.domain) {
            if (this.form.region === 'NZ') {
              this.form.domain = this.form.domain.replace('.' + this.nzRootDomain, '');
            } else {
              this.form.domain = this.form.domain.replace('.' + this.auRootDomain, '');
            }
          }
        });
    },
    cancel: function () {
      this.$router.push({ name: 'organisations' });
    }
  },
  components: {
    OrganisationTabs,
    QForm,
    QBtn,
    QSpinner,
    QInput,
    QToggle,
    FormError
  }
};
</script>

<template>
  <div
    class="bg-white q-pa-lg"
    v-if="$store.getters['auth/hasFeature']('DB::ManageDealership') && !$store.getters['auth/hasFeature']('DB::OwnDealer')"
  >
    <AdminTabs />
    <!-- <div class="row justify-between items-center q-mb-lg">
      <h6 class="q-ma-none text-weight-bold text-grey-9">Dealerships</h6>
    </div> -->
    <div class="row" style="text-align: right">
      <div class="col items-end">
        <QBtn
          class="q-mr-sm"
          :to="{ name: 'NewDealer', params: { dealerId: this.NewDealerRouteParam } }"
          color="secondary"
          label="New Dealer"
        />
      </div>
    </div>
    <QTable
      flat
      :loading="$apollo.queries.dealers.loading"
      :columns="columns"
      :data="dealers.rows"
      :pagination.sync="pagination"
      @row-click="this.editDealer"
      @request="onRequest"
    />
  </div>
</template>

<script>
import { QTable, QBtn } from 'eclipx.ui';
import dealersTableColumns from './dealersTableColumns';
import QueryDealers from '../../graphql/queries/QueryDealers';
import { GLOBAL_ERROR } from '../../components/GlobalError/GlobalError';
import AdminTabs from '../Users/AdminTabs';
import { NewDealerRouteParam } from '../../router/Routes';

export default {
  name: 'Dealers',
  data() {
    return {
      columns: dealersTableColumns,
      dealers: {},
      sortBy: 'createdAt',
      descending: true,
      page: 1,
      rowsPerPage: 20,
      NewDealerRouteParam: NewDealerRouteParam
    };
  },
  computed: {
    pagination: {
      get: function () {
        return {
          sortBy: this.sortBy,
          descending: this.descending,
          page: this.page,
          rowsPerPage: this.rowsPerPage,
          rowsNumber: this.dealers.count
        };
      },
      set: function (newValue) {}
    }
  },
  apollo: {
    dealers: {
      query: QueryDealers,
      fetchPolicy: 'network-only',
      variables() {
        return {
          params: {
            getAll: true,
            page: this.page - 1, // Quasar table is 1 based, the api is 0 based
            size: this.rowsPerPage,
            sortBy: this.sortBy,
            sortType: this.descending ? '-' : ' '
          }
        };
      },
      error: function (error) {
        if (error) {
          this.$euiNotifications.emit({
            notification: GLOBAL_ERROR,
            action: () => {
              return this.$apollo.queries.dealers.refresh();
            }
          });
        }
      }
    }
  },
  mounted() {
    this.onRequest({
      pagination: this.pagination,
      filter: undefined
    });
  },
  methods: {
    editDealer: function (event, row) {
      this.$router.push({
        name: 'dealerEdit',
        params: { dealerId: row.id }
      });
    },
    onRequest: function (props) {
      const { page, rowsPerPage, sortBy, descending } = props.pagination;
      const filter = props.filter;

      setTimeout(() => {
        this.page = page;
        this.rowsPerPage = rowsPerPage;
        this.sortBy = sortBy;
        this.descending = descending;
      }, 500);
    }
  },
  components: {
    QTable,
    QBtn,
    AdminTabs
  }
};
</script>

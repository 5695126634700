import gql from 'graphql-tag';
export default gql`
  query getOrgById($id: String!) {
    getOrgById(id: $id) {
      name
      abn
      erps {
        id
        name
        code
      }
      orgBranding {
        logo
      }
    }
  }
`;

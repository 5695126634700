<template>
  <QExpansionItem class="q-mb-md" dense :expand-icon="chevronDown" :expandedIcon="chevronUp">
    <template
      slot="header"
      class="q-item q-item-type row no-wrap q-item--clickable q-link cursor-pointer q-focusable q-hoverable q-item--dense"
    >
      <div class="q-item__section row q-item__section--main justify-space-between items-center">
        <span class="text-body3 text-weight-bold text-grey-9"> Colour, Accessories, Options </span>
        <div class="q-ml-sm">
          <QChip color="secondary" text-color="white" size="sm" class="text-weight-medium" :label="this.totalColourAndExtras" />
        </div>
      </div>
    </template>
    <div class="q-mx-lg q-my-sm">
      <div class="q-mb-xs row" v-if="erpquote.exteriorColour.id">
        <SummaryCardRemoveItem
          :canRemove="!isDisabled"
          @remove="() => removeField('exteriorColour')"
          :item="`Exterior Colour: ${erpquote.exteriorColour.name}`"
        />
      </div>
      <div class="q-mb-xs row" v-if="erpquote.interiorColour && erpquote.interiorColour.id">
        <SummaryCardRemoveItem
          :canRemove="!isDisabled"
          @remove="() => removeField('interiorColour')"
          :item="`Interior Colour: ${erpquote.interiorColour.name}`"
        />
      </div>
      <div v-for="option in erpquote.optionalEquipment" :key="option.id" class="row q-mb-xs items-start">
        <SummaryCardRemoveItem
          :canRemove="!isDisabled"
          @remove="() => removeFieldItem('optionalEquipment', option.id)"
          :item="option.name || option.description"
          :price="option.price"
        />
      </div>
      <div v-for="accessory in erpquote.adhocEquipment" :key="accessory.id" class="row q-mb-xs">
        <SummaryCardRemoveItem
          :canRemove="!isDisabled"
          @remove="() => removeFieldItem('adhocEquipment', accessory.id)"
          :item="accessory.name || accessory.description"
          :price="accessory.price"
        />
      </div>
    </div>
  </QExpansionItem>
</template>

<script>
import { QExpansionItem, QChip, Icons } from 'eclipx.ui';
import { mapState, mapMutations, mapGetters } from 'vuex';
import SummaryCardRemoveItem from './SummaryCardRemoveItem.vue';

export default {
  name: 'SummaryCardColoursAndExtras',
  computed: {
    ...mapState({ erpquote: (state) => state.quote.quote.erpquote }),
    ...mapGetters({
      hasRequestedPricing: 'quote/hasRequestedPricing',
      isExpired: 'quote/isExpired'
    }),
    isDisabled: function () {
      return this.hasRequestedPricing || this.isExpired || !this.routeIsColoursAndExtras;
    },
    totalColourAndExtras: function () {
      let count = 0;
      if (this.erpquote.exteriorColour.id) {
        count++;
      }
      if (this.erpquote.interiorColour.id) {
        count++;
      }
      count += this.erpquote.optionalEquipment ? this.erpquote.optionalEquipment.length : 0;
      count += this.erpquote.adhocEquipment ? this.erpquote.adhocEquipment.length : 0;
      return count;
    },
    routeIsColoursAndExtras: function () {
      return this.$route.name === 'quoteColourAndExtras';
    }
  },
  methods: {
    ...mapMutations({ setErpQuoteFields: 'quote/setErpQuoteFields' }),
    openColoursAndExtras: function () {
      if (!this.routeIsColoursAndExtras) {
        this.$router.push({
          name: 'quoteColourAndExtras',
          params: { quoteId: this.$route.params.quoteId }
        });
      }
    },
    removeField: function (field) {
      this.setErpQuoteFields({ [field]: {} });
      this.openColoursAndExtras();
    },
    removeFieldItem: function (field, id) {
      let updatedField = [...this.erpquote[field]];
      let index = updatedField.findIndex((item) => item.id === id);
      updatedField.splice(index, 1);
      this.setErpQuoteFields({ [field]: updatedField });
      this.openColoursAndExtras();
    }
  },
  created() {
    this.chevronDown = Icons.evaChevronDown;
    this.chevronUp = Icons.evaChevronUp;
  },
  components: {
    QExpansionItem,
    QChip,
    SummaryCardRemoveItem
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped></style>

<template>
  <div class="row items-center">
    <div class="row col q-mr-md">
      <QSlider
        class="q-mt-lg q-mb-md col-12"
        :color="error ? 'red-7' : 'secondary'"
        v-model="sliderValueCopy"
        :min="minimumValue"
        :max="maximumValue"
        :step="step"
        :disable="isDisabled"
        :read-only="isDisabled"
        @input="handleSliderValueChanged(sliderValueCopy)"
        markers
      />
      <div class="row col-12 text-body2">
        <div class="col-6">
          {{ getSliderTypePlaceholder(minimumValue) }}
        </div>
        <div class="col-6 text-right">
          {{ getSliderTypePlaceholder(maximumValue) }}
        </div>
      </div>
    </div>
    <input
      type="text"
      class="custom-qfield text-body2"
      :disabled="isDisabled"
      v-model="formattedSliderValueInput"
      @input="handleSliderInputValueChanged(formattedSliderValueInput)"
      pattern="\d{1,3}(,\d{3})*(\.\d+)?"
    />
  </div>
</template>

<script>
import { QSlider } from 'eclipx.ui';
import { debounce } from 'lodash';

export default {
  name: 'LeaseDetailsSlider',
  props: {
    step: {
      type: Number,
      default: 1000
    },
    sliderValue: {
      type: Number,
      default: 0
    },
    isDisabled: Boolean,
    minimumValue: {
      type: Number,
      default: 10000
    },
    maximumValue: {
      type: Number,
      default: 50000
    },
    sliderType: {
      type: String,
      default: 'default'
    },
    error: Boolean
  },
  data() {
    return {
      sliderValueCopy: this.sliderValue ? this.sliderValue : parseInt(this.minimumValue),
      sliderValueInputCopy: this.sliderValue ? this.sliderValue : '',
      debouncedHandleSliderValueChanged: debounce(this.handleSliderValueChanged, 800)
    };
  },
  computed: {
    formattedSliderValueInput: {
      get() {
        return this.sliderValueInputCopy.toLocaleString();
      },
      set(value) {
        this.sliderValueInputCopy = parseInt(value.replace(/,/g, ''), 10) || this.sliderValueInputCopy;
      }
    }
  },
  methods: {
    getSliderTypePlaceholder(value) {
      if (!value) {
        return '';
      }

      const SLIDER_TYPES = {
        kilometer: {
          placeholder: (value) => `${value.toLocaleString()}km`
        },
        month: {
          placeholder: (value) => `${value}m`
        },
        default: {
          placeholder: (value) => value
        }
      };

      if (Object.keys(SLIDER_TYPES).some((type) => this.sliderType === type)) {
        return SLIDER_TYPES[this.sliderType].placeholder(value);
      } else {
        return SLIDER_TYPES.default.placeholder(value);
      }
    },
    handleSliderValueChanged(value) {
      this.sliderValueCopy = value;
      this.sliderValueInputCopy = value;
      this.$emit('sliderValueChanged', value);
    },
    handleSliderInputValueChanged() {
      if (this.sliderValueInputCopy < this.minimumValue) {
        this.sliderValueCopy = this.minimumValue;
      } else if (this.sliderValueInputCopy > this.maximumValue) {
        this.sliderValueCopy = this.maximumValue;
      } else {
        this.sliderValueCopy = this.sliderValueInputCopy;
      }

      this.$emit('sliderValueChanged', this.sliderValueCopy);
    }
  },
  watch: {
    sliderValue(value) {
      this.sliderValueCopy = value;
    }
  },
  components: {
    QSlider
  }
};
</script>

<style lang="sass">
.custom-qfield
  margin-bottom: 12px
  width: 100%
  max-width: 55px
  padding: 2.5px
  height: 25px
  border: 1px solid rgba(0, 0, 0, 0.05)
  border-radius: 4px

.custom-qfield::-webkit-inner-spin-button,
.custom-qfield::-webkit-outer-spin-button
  -webkit-appearance: none;
  margin: 0;

.custom-qfield[type="text"]
  -moz-appearance: textfield;

.q-slider__track-markers
  color: rgba(0, 0, 0, 0.24) !important

.q-slider__track-markers--h
  background-image: repeating-linear-gradient(to right, currentColor, currentColor 1px, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0))!important
</style>

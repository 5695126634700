<template>
  <div class="bg-white q-pa-lg" v-if="$store.getters['auth/whiteLabelEnabled']">
    <div class="bg-white q-pa-lg">
      <div class="row">
        <div class="col-xs-12 col-sm-3 col-md-3" style="padding: 25px">
          <h5>Global Styles</h5>
          <br />
          <p>Select your company's colours and logos to reflect your brand's online experience.</p>
          <div style="width: 100; text-align: center">
            <img src="/assets/images/whitelabel/sbGlobal.png" height="400px" />
          </div>
        </div>
        <div class="col-xs-12 col-sm-9 col-md-9" style="padding: 25px">
          <strong>Global Colours</strong>
          <p>Select your company's colours or enter the HEX/ RGB codes. This will be used across the site.</p>
          <br />
          <div class="row" style="width: 100%">
            <ColourPicker
              caption="Primary (Dark)"
              field="primary"
              :colourProp="form.primary"
              @onColourChangeHandler="onColourChangeHandler"
            />

            <ColourPicker
              caption="Secondary (Dark)"
              field="secondary"
              :colourProp="form.secondary"
              @onColourChangeHandler="onColourChangeHandler"
            />

            <ColourPicker
              caption="Footer (Dark)"
              field="background"
              :colourProp="form.background"
              @onColourChangeHandler="onColourChangeHandler"
            />
          </div>

          <hr style="border-top: 0.5px solid lightgray; margin: 30px 0px" />

          <strong>Image Library</strong>
          <p>Upload your company's image assets that will appear in the Credit Portal.</p>
          <br />

          <div class="row" style="width: 100%">
            <ImagePicker
              caption="Company Logo Left Hand Side"
              field="primaryLogo"
              info="Upload your company's logo. This will be positioned on the left hand side within the header.<br/><br/>Recommended aspect ratio 5:1<br/>Recommended size 900(w) x 180(h) px<br/>File format: PNG, JPG, GIF<br/>Max file size: 160KB"
              :imageProp="form.primaryLogo"
              @onImageChangeHandler="onImageChangeHandler"
            />
            <ImagePicker
              caption="Company Logo Right Hand Side"
              field="primaryLogoRight"
              info="Upload your company’s logo. This will be positioned on the right hand side within the header.<br/><br/>Recommended aspect ratio 5:1<br/>Recommended size 900(w) x 180(h) px<br/>File format: PNG, JPG, GIF<br/>Max file size: 160KB"
              :imageProp="form.primaryLogoRight"
              @onImageChangeHandler="onImageChangeHandler"
            />
          </div>
          <div class="row" style="width: 100%">
            <ImagePicker
              caption="Primary Image"
              field="primaryImage"
              info="Upload an image. This image will appear on the credit portal as shown and will replace the car image shown in the sample image.<br/><br/>Recommended aspect ratio 3:2<br/>Recommended size 1200(w) x 800(h) px<br/>File format: PNG, JPG, GIF<br/>Max file size: 160KB"
              :imageProp="form.primaryImage"
              @onImageChangeHandler="onImageChangeHandler"
            />
          </div>
          <hr style="border-top: 0.5px solid lightgray; margin: 30px 0px" />
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-3 col-md-3" style="padding: 25px">
          <h5>Footer Information</h5>
          <br />
          <p>Customise your footer with your company's information and logos.</p>
          <div style="width: 100%; text-align: center">
            <img src="/assets/images/whitelabel/sbFooter.png" height="400px" />
          </div>
        </div>
        <div class="col-xs-12 col-sm-9 col-md-9" style="padding: 25px">
          <strong>Footer Content</strong>
          <p>You can edit the text within your footer to include privacy policy, contact details etc.</p>
          <br />
          <div class="row" style="width: 100%">
            <div class="q-pa-xs col-12 col-sm-12">
              <QEditor
                v-model.lazy="form.footerText"
                placeholder="Footer text"
                min-height="200px"
                :content-style="{ backgroundColor: '#0000000d' }"
                :toolbar="[['bold', 'italic', 'underline', 'link']]"
                @input="onInputChangeHandler('footerText')"
                :disable="!hasFeature('DB::ManageAdmins')"
              />
            </div>

            <div class="q-pa-xs col-12 col-sm-12">
              <br />
              <strong>Contact Details</strong>
            </div>

            <div class="q-pa-xs col-12 col-sm-6">
              <br />
              <label class="field-label">Phone Number</label>
              <QInput v-model="form.phoneNumber" filled @input="onInputChangeHandler('phoneNumber')" />
            </div>

            <div class="q-pa-xs col-12 col-sm-6">
              <br />
              <label class="field-label">Email</label>
              <QInput v-model="form.email" filled @input="onInputChangeHandler('email')" />
            </div>

            <div class="q-pa-xs col-12 col-sm-6">
              <br />
              <label class="field-label">Website</label>
              <QInput v-model="form.website" filled @input="onInputChangeHandler('website')" />
            </div>

            <div class="q-pa-xs col-12 col-sm-6"></div>

            <div class="q-pa-xs col-12 col-sm-12">
              <br />
              <strong>Links</strong>
            </div>
            <div class="q-pa-xs col-12 col-sm-6">
              <br />
              <label class="field-label">Terms of use link</label>
              <QInput v-model="form.termsLink" filled disable />
            </div>

            <div class="q-pa-xs col-12 col-sm-6">
              <br />
              <label class="field-label">Privacy Policy Link</label>
              <QInput v-model="form.privacyPolicy" filled disable />
            </div>
          </div>

          <hr style="border-top: 0.5px solid lightgray; margin: 30px 0px" />
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-3 col-md-3" style="padding: 25px">
          <h5>Information Pop-ups</h5>
          <br />
          <p>Upload images to customise the experience.</p>
        </div>
        <div class="col-xs-12 col-sm-9 col-md-9" style="padding: 25px">
          <div class="row" style="width: 100%">
            <ImagePicker
              caption="Are you sure your details are correct?"
              field="popupImage1"
              info="Recommended aspect ratio 1:1<br/>Recommended size 200 (w) x 200(h) px<br/>File format: PNG, JPG, GIF<br/>Max file size: 160KB"
              :imageProp="form.popupImage1"
              @onImageChangeHandler="onImageChangeHandler"
            />

            <ImagePicker
              caption="You're inviting in an Authorised Officer."
              field="popupImage2"
              info="Recommended aspect ratio 1:1<br/>Recommended size 200 (w) x 200(h) px<br/>File format: PNG, JPG, GIF<br/>Max file size: 160KB"
              :imageProp="form.popupImage2"
              @onImageChangeHandler="onImageChangeHandler"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QInput, QEditor } from 'eclipx.ui';
import { mapGetters, mapMutations } from 'vuex';
import ColourPicker from './Modules/ColourPicker';
import ImagePicker from './Modules/ImagePicker';

export default {
  name: 'ScoreBoardContent',
  data() {
    return {
      form: {
        primary: '',
        secondary: '',
        background: '',
        primaryLogo: '',
        primaryImage: '',
        footerText: '',
        phoneNumber: '',
        email: '',
        website: '',
        termsLink: '',
        policyLink: '',
        popupImage1: '',
        popupImage2: '',
        primaryLogoRight: ''
      }
    };
  },
  computed: {
    ...mapGetters({
      scoreboard: 'whiteLabel/scoreboard',
      whiteLabel: 'whiteLabel/whiteLabel',
      hasFeature: 'auth/hasFeature'
    })
  },
  created() {
    this.form.primary = this.scoreboard.primary;
    this.form.secondary = this.scoreboard.secondary;
    this.form.background = this.scoreboard.background;
    this.form.primaryImage = this.scoreboard.primaryImage;
    this.form.primaryLogo = this.scoreboard.primaryLogo;
    this.form.footerText = this.scoreboard.footerText;
    this.form.phoneNumber = this.scoreboard.phoneNumber;
    this.form.email = this.scoreboard.email;
    this.form.website = this.scoreboard.website;
    this.form.termsLink = this.whiteLabel.termsLink;
    this.form.privacyPolicy = this.whiteLabel.privacyPolicy;
    this.form.popupImage1 = this.scoreboard.popupImage1;
    this.form.popupImage2 = this.scoreboard.popupImage2;
    this.form.primaryLogoRight = this.scoreboard.primaryLogoRight;
  },
  mounted() {},
  methods: {
    ...mapMutations({ updateValueStatus: 'whiteLabel/updateValueStatus' }),
    onColourChangeHandler: function (colour, field) {
      this.form[field] = colour;
      this.updateValueStatus({
        object: 'scoreboard',
        variable: field,
        value: colour
      });
    },
    onImageChangeHandler: function (image, field) {
      this.form[field] = image;
      this.updateValueStatus({
        object: 'scoreboard',
        variable: field,
        value: image
      });
    },
    onInputChangeHandler: function (field) {
      this.updateValueStatus({
        object: 'scoreboard',
        variable: field,
        value: this.form[field]
      });
    }
  },
  components: {
    QInput,
    QEditor,
    ColourPicker,
    ImagePicker
  }
};
</script>

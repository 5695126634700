<template>
  <div class="row col-grow no-wrap items-center">
    <div v-if="canRemove">
      <QBtn v-bind="closeButtonAttributes" size="7px" :icon="close" @click="$emit('remove')" />
    </div>
    <div class="col" v-if="item">
      <p class="ellipsis text-body3 text-grey-8 q-mb-none">
        <QTooltip anchor="top middle" :offset="[0, 25]">{{ item }}</QTooltip>
        {{ item }}
      </p>
    </div>
    <p class="q-mb-none q-pl-sm text-body3 text-grey-7" v-if="price">
      {{ price | currency }}
    </p>
  </div>
</template>

<script>
import { Icons, QBtn, QTooltip } from 'eclipx.ui';

export default {
  name: 'SummaryCardRemoveItem',
  props: {
    item: {
      type: String
    },
    price: {
      type: [String, Number]
    },
    canRemove: Boolean
  },
  data: function () {
    return {
      closeButtonAttributes: {
        'class': 'q-mr-sm',
        'unelevated': true,
        'color': 'grey-4',
        'text-color': 'grey-7',
        'round': true,
        'size': 'xs'
      }
    };
  },
  created() {
    this.close = Icons.evaClose;
  },
  components: { QBtn, QTooltip }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped></style>

<template>
  <div>
    <p>Dear {{ $store.getters['auth/user'] ? $store.getters['auth/user'].firstName : '' }},</p>
    <p><b>Access to your FleetPartners account has been revoked by your internal administrator for this application.</b></p>
    <p>If you believe this was actioned in error, please contact the Administrator.</p>
  </div>
</template>
<script>
export default {
  name: 'Useraccess'
};
</script>

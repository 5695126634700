<template>
  <div class="col-xs-12 col-sm-6 col-md-6" style="padding: 25px 50px 25px 0px">
    <div class="row">
      <div class="col-6" style="margin: auto">
        <strong> {{ caption }}</strong>
        <p v-if="info" v-html="info" style="font-size: 12px; padding-top: 5px"></p>
      </div>
      <div class="col-6" style="text-align: right">
        <strong style="padding-right: 25px; text-transform: uppercase">{{ colour }}</strong>
        <QBtn :style="{ 'background-color': colour }" ref="submitBtn">
          <QPopupProxy transition-show="scale" transition-hide="scale">
            <!-- <QColor v-model="colour" @change="onChangeHandler" :disable="disablePicker" /> -->
            <QColor v-model="colour" :disable="disablePicker" />
          </QPopupProxy>
        </QBtn>
        <div style="padding-top: 10px">
          <span style="cursor: pointer; font-size: 11px; font-weight: 700" @click="onClickChange">Change Colour</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QBtn, QColor, QPopupProxy } from 'eclipx.ui';

export default {
  name: 'ColourPicker',
  props: {
    caption: String,
    info: String,
    field: String,
    colourProp: String,
    disablePicker: Boolean
  },
  data() {
    return {
      colour: ''
    };
  },
  watch: {
    colour() {
      this.$emit('onColourChangeHandler', this.colour, this.field);
    }
  },
  computed: {},
  created() {
    if (this.colourProp) this.colour = this.colourProp;
  },
  mounted() {},
  methods: {
    onClickChange() {
      this.$refs.submitBtn.$el.click();
    },
    onChangeHandler: async function () {
      this.$emit('onColourChangeHandler', this.colour, this.field);
    }
  },
  components: {
    QBtn,
    QColor,
    QPopupProxy
  }
};
</script>

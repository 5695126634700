import gql from 'graphql-tag';
export default gql`
  query leaseOptions($productId: String!) {
    leaseOptions(productId: $productId) {
      availableOptions {
        id
        name
        type
        required
        default
        options {
          id
          name
        }
        multiple
      }
      leaseTerms {
        months
        kmsPerYear
      }
      included
      excluded
    }
  }
`;

import gql from 'graphql-tag';
export default gql`
  mutation UPLOAD($file: Upload) {
    upload {
      uploadOrgImage(file: $file) {
        uuid
        signedUrl
      }
    }
  }
`;

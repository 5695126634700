import gql from 'graphql-tag';
export default gql`
  mutation updateMilesQuote($params: UpdateMilesQuoteInput!) {
    updateMilesQuote(params: $params) {
      code
      message
      response
    }
  }
`;

import gql from 'graphql-tag';
export default gql`
  {
    roles {
      count
      rows {
        id
        name
      }
    }
  }
`;

import gql from 'graphql-tag';
export default gql`
  query getBusinessInfo($param: String!) {
    getBusinessInfo(param: $param) {
      rows {
        abn
        name
        status
        stateCode
        postCode
        registerNumber
        contactDetail {
          name
          email
          contactNumber
          street
          suburb
          state
          postCode
        }
      }
      status
    }
  }
`;
